export default {
  form: {
    title: "Iniciar sesión en Xplora",
    username: "Número de teléfono de los padres",
    password: "Ingrese el código que recibió por SMS.",
    language: "Idioma",
    submit: "Iniciar sesión",
    continue: "Siguiente",
    usernameValidationMessage: "Ingrese el número de teléfono de los padres.",
    otpHint: "",
  },
  logoutDialog: {
    title: "Conectado como",
  },
  loginInfo: {
    title: "¿Eres suscriptor de Xplora?",
    content: `NOTA: Si la suscripción es para un reloj Xplora, ingrese el número de teléfono móvil de los padres, no el del reloj inteligente. \n \n Tenga en cuenta que puede tomar hasta 30 minutos desde la orden hasta que pueda iniciar sesión.`,
  },
} as const;
