export default {
  _404: {
    title: "Página no encontrada",
    urlNotFound: "URL no encontrada",
    notFoundOrNotAvailable:
      "El recurso solicitado no se encuentra o ya no está disponible",
  },
  _500: {
    fatalError: {
      title: "Ocurrió un error fatal inesperado en el servidor",
      title_plural:
        "Ocurrieron varios errores fatales inesperados en el servidor",
    },
  },
  backToApplications: "Volver a mis aplicaciones",
  error: "error",
  error_plural: "errores",
  errorWithCount: "{{count}} error",
  errorWithCount_plural: "{{count}} errores",
  preventNavigationDialog: {
    title: "Cambios no guardados",
    body: "Tienes cambios no guardados. ¿Estás seguro de que quieres salir de esta página y descartarlos?",
    stayOnPage: "Permanecer en la página",
    leavePage: "Salir de la página",
  },
  route: {
    noAccess:
      "No tienes acceso a la URL solicitada {{url}}. Tu rol de usuario lo impide.",
  },
  fetchError:
    "No se pudo cargar el widget debido a un error del servidor (Código de error {{status}})",
  fields: {
    valueAmountError:
      "Error: Se esperaban {{expectedAmount}} valores en el campo {{fieldName}}, pero solo se recibieron {{actualAmount}}",
  },
  auth: {
    logoutFail:
      "Falló la solicitud de cierre de sesión. Credenciales de autenticación local borradas.",
  },
  emptyQuery: "Error: El servidor no devolvió ningún valor",
  clientError: "Error del cliente web: ",
  validationError: "Error de validación",
  screenLoadingError:
    "Ocurrió un error al cargar la página. Esto podría deberse a una URL inválida o caducada. Mensaje del servidor: {{serverMessage}} ",
  screenLoadingError_nomessage:
    "Ocurrió un error al cargar la página. Esto podría deberse a una URL inválida o caducada.",
  failedToLoadWidget: "No se pudo cargar el widget",
} as const;
