import { Menu, MenuItem } from "common/components";
import { MenuState, TableAction } from "core/components";
import { useTranslation } from "i18n";
import { ReactElement } from "react";

/**
 * Props for the DBTableActionMenu component
 */
export interface TableActionMenuProps<T> {
  /** If hideDisabledActions is true, its filtered to only have the enabled tableActions, if false it has the disabled also */
  actions: TableAction<T>[];
  menuState: MenuState<T>;
  setMenuState: React.Dispatch<React.SetStateAction<MenuState<T>>>;
  selectedItems: T[];
}

export function TableActionMenu<T>({
  actions,
  menuState,
  setMenuState,
  selectedItems,
}: TableActionMenuProps<T>): ReactElement {
  const { t } = useTranslation(["common"]);
  const resetMenuState = () => {
    setMenuState({
      menuAnchor: null,
      selectedElement: undefined,
      menuId: undefined,
      menuButtonId: undefined,
    });
  };

  const perFormAction =
    (action: (selectedItems: T[], selectedItem: T | undefined) => void) =>
    () => {
      action(selectedItems, menuState.selectedElement);
      resetMenuState();
    };

  /*
    TODO: The button that opens the menu need to have these attributes

    id={<button id>}
    aria-controls={menuState.menuId}
    aria-haspopup="true"
    aria-expanded={if menu is open}
  */

  const emptyMenuText =
    selectedItems.length > 0
      ? t("common:noActionMatchingSelection")
      : t("common:noItemSelected");

  return (
    <Menu
      id={menuState.menuId}
      open={Boolean(menuState.menuAnchor)}
      anchorEl={menuState.menuAnchor}
      onClose={resetMenuState}
      MenuListProps={{
        "aria-labelledby": menuState.menuButtonId,
      }}
    >
      {actions.length > 0 ? (
        actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={perFormAction(action.action)}
            disabled={action.disableActionForSelection(
              selectedItems,
              menuState.selectedElement
            )}
            data-cy={"tableButton" + action.label.replaceAll(" ", "")}
          >
            {action.label}
          </MenuItem>
        ))
      ) : (
        <MenuItem data-cy="emptyMenuItem">{emptyMenuText}</MenuItem>
      )}
    </Menu>
  );
}
