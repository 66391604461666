import {
  BaseField,
  EntityData,
  Field,
  FieldConfig,
  FieldKey,
  FieldType,
  FieldUIComponent,
  FieldValue,
  FieldValueConfig,
  FieldVariant,
  FlowStep,
  Icon,
  IconVariant,
} from "core/api";
import { AccessLevel } from "core/auth";
import { getI18n } from "i18n";
import { formatDate } from "./DateUtils";
import {
  convertToJSUnit,
  formatCurrency,
  formatNumber,
  formatNumberWithUnit,
  formatPercentage,
} from "./NumberUtils";

/**
 * Returns a field
 * @param fieldKey Field Key to search for
 * @param fields Fields to search in
 * @returns Returns a field
 */
export function getField(
  fieldKey: FieldKey,
  fields: Field[]
): Field | undefined {
  return fields.find(
    ({ key }) =>
      key.key.toUpperCase() === fieldKey.key.toUpperCase() &&
      key.variant === fieldKey.variant
  );
}

/**
 * Finds a field value from field key and value number.
 *
 * @param fieldKey Field key object or string of combined key + variant.
 * @param fields List of fields.
 * @param valueNo Field values value number. Defaults to 0.
 * @returns Target field value if it exists.
 */
export function getFieldValue(
  fieldKey: FieldKey,
  fields: Field[],
  valueNo = 0
): FieldValue | undefined {
  return getField(fieldKey, fields)?.values.find(
    ({ valueNo: _valueNo }) => _valueNo === valueNo
  );
}

/**
 * Gets the url property of a field value
 *
 * @param entity An entity that contains fields
 * @param fieldKey The key that identifies a field
 * @returns The url of the first value of the field identified by *fieldKey*
 *   or undefined if *fieldKey* is undefined, or if no field of the given
 *   *entity* matches *fieldKey*, or if the matching field value does not have
 *   any url property.
 */
export function getFieldUrl(
  entity: EntityData,
  fieldKey?: FieldKey
): string | undefined {
  if (!fieldKey) {
    return undefined;
  }

  for (const field of entity.fields ?? []) {
    if (
      field.key.variant === fieldKey.variant &&
      field.key.key === fieldKey.key &&
      field.values?.length > 0
    ) {
      return field.values[0].url;
    }
  }
  return undefined;
}

/**
 * Returns a displayValue from a field.
 * @param fieldKey Field Key to search for
 * @param uiComponent Type of UI Component that is used to display this field
 * @param fields Fields to search in
 * @returns Returns the first value if displayValue if found, else a empty string.
 */
export function getFieldDisplayValue(
  fieldKey: FieldKey,
  uiComponent: FieldUIComponent,
  fields: Field[]
): string {
  const field = getField(fieldKey, fields);
  // Take into account the override of the uiComponent on this specific Field
  const _uiComponent = field?.uiComponent || uiComponent;
  return formatDisplayValue(
    _uiComponent,
    getFieldProps(_uiComponent, field, [])
  );
}

/**
 * Converts a JSON string to a more readable format.
 *
 * @param value String to be pretty printed
 * @returns string
 *
 */
export function prettyPrintJson(value: string): string {
  let newString: string;
  try {
    newString = JSON.stringify(JSON.parse(value), null, 2);
  } catch {
    newString = value;
  }
  return newString;
}

/**
 * Formats read-only field display value
 * @param uiComponent UI component type
 * @param displayValue Unformatted field display value
 * @param options Display value formatting options
 * @returns Formatted display value
 */
export function formatDisplayValue(
  uiComponent: FieldUIComponent,
  options: DisplayValueOptions
): string {
  const { displayValue, unitType, locale } = options;
  if (displayValue?.length === 0) {
    // If displayValue is the empty string, there is nothing to format
    return displayValue as string;
  }
  const formattedDisplayValue = (() => {
    switch (uiComponent) {
      case "Date":
      case "DateTime":
      case "Time":
        return formatDate(
          displayValue as string,
          { Date: "L", DateTime: "L LTS", Time: "LTS" }[uiComponent]
        );
      case "JsonText":
        return prettyPrintJson((displayValue as string) ?? "");

      case "Amount":
        return (
          formatCurrency(
            parseFloat(displayValue as string),
            options.currencyCode as string
          ) || String(displayValue)
        );

      case "Number": {
        const unit = unitType && convertToJSUnit(unitType);
        if (unit) {
          return (
            formatNumberWithUnit(parseFloat(displayValue as string), unit, {
              locale,
            }) || String(displayValue)
          );
        } else {
          return (
            formatNumber(parseFloat(displayValue as string)) ||
            String(displayValue)
          );
        }
      }
      case "Percent":
        return (
          formatPercentage(parseFloat(displayValue as string)) ||
          String(displayValue)
        );
      case "List": {
        const separator = ", ";
        return Array.isArray(displayValue)
          ? displayValue.join(separator)
          : String(displayValue);
      }
      case "FieldValueGroup": {
        const separator = " / ";
        return Array.isArray(displayValue)
          ? displayValue.join(separator)
          : String(displayValue);
      }
      default:
        return String(displayValue);
    }
  })();
  return [options.startAdornment, formattedDisplayValue, options.endAdornment]
    .filter((value) => value?.trim())
    .join(" ");
}

/**
 * Returns a Status Icon from a field.
 * @param fieldKey Field Key to search for
 * @param fields Fields to search in
 * @returns Returns field Icon with name and variant.
 */
export function getFieldIcon(fieldKey: FieldKey, fields: Field[]): Icon {
  const field = getField(fieldKey, fields);
  return {
    name: field?.values.find(({ valueNo }) => valueNo === 0)?.icon?.name ?? "",
    variant:
      field?.values.find(({ valueNo }) => valueNo === 0)?.icon?.variant ??
      IconVariant.ERROR,
  };
}

/**
 * Returns the Currency code from a field.
 * @param fieldKey Field Key to search for
 * @param fields Fields to search in
 * @returns Returns field currency code.
 */
export function getFieldCurrencyCode(
  fieldKey: FieldKey,
  fields: Field[]
): string {
  const field = getField(fieldKey, fields);
  return field?.currencyCode ?? "";
}

export interface DisplayValueOptions {
  /** Override user locale */
  locale?: string;

  /** Currency code */
  currencyCode?: string;
  /** Adornment text before display value */
  startAdornment?: string;
  /** Adornment text after display value */
  endAdornment?: string;
  displayValue: string | string[] | undefined;
  checked?: boolean;
  icon?: Icon;
  error?: string;
  fieldValueConfig?: FieldValueConfig;
  fieldValue?: FieldValue;
  groupedFieldValues?: DisplayValueOptions[];
  uiComponent?: FieldUIComponent;

  /** The Billiant UnitType enum name */
  unitType?: string;
}

/**
 *
 * @param uiComponent Helper function to format the display text.
 *
 * @param data
 * @returns
 */
export function getFieldProps(
  uiComponent: FieldUIComponent,
  data: BaseField | undefined,
  fieldValueConfigs: FieldValueConfig[],
  currentValueNo = 0
): DisplayValueOptions {
  if (!data) {
    return { displayValue: "" };
  }
  switch (uiComponent) {
    case "Amount":
      return {
        displayValue: data.values.find(
          ({ valueNo }) => valueNo === currentValueNo
        )?.value,
        currencyCode: data.currencyCode,
        error: data.values.find(({ valueNo }) => valueNo === currentValueNo)
          ?.error,
      };

    case "Number":
    case "Percent": {
      const fieldValue = data.values.find(
        ({ valueNo }) => valueNo === currentValueNo
      );
      return {
        displayValue: fieldValue?.value,
        unitType: fieldValue?.unitType,
        error: data.values.find(({ valueNo }) => valueNo === currentValueNo)
          ?.error,
      };
    }
    case "Date":
    case "DateTime":
    case "Time":
      return {
        displayValue: data.values.find(
          ({ valueNo }) => valueNo === currentValueNo
        )?.value,
        error: data.values.find(({ valueNo }) => valueNo === currentValueNo)
          ?.error,
      };

    case "FieldValueGroup":
      return {
        displayValue: data.values.map(
          (item) => item.displayValue[item.valueNo]
        ),
        groupedFieldValues: fieldValueConfigs
          .filter((fieldValueConfig) => fieldValueConfig.visible)
          .map((fieldValueConfig) =>
            getLocalDisplayValueOption(
              fieldValueConfig,
              data.values,
              data.key,
              data.accessLevel,
              data.currencyCode
            )
          ),
      };

    case "Password":
      return { displayValue: "********" };

    case "List":
      return { displayValue: data.values.map((item) => item.displayValue) };

    default:
      return {
        displayValue: data.values.find(
          ({ valueNo }) => valueNo === currentValueNo
        )?.displayValue,
        checked:
          data?.values.find(
            ({ valueNo }) =>
              valueNo === (uiComponent.startsWith("Optional") ? 1 : 0)
          )?.value === "true",
        icon: data.values.find(({ valueNo }) => valueNo === currentValueNo)
          ?.icon,
        error: data.values.find(({ valueNo }) => valueNo === currentValueNo)
          ?.error,
      };
  }
}

function getLocalDisplayValueOption(
  config: FieldValueConfig,
  values: FieldValue[],
  key: FieldKey,
  accessLevel: AccessLevel,
  currencyCode: string
): DisplayValueOptions {
  let filteredValues = values.filter((item) => item.valueNo === config.valueNo);
  if (filteredValues.length === 0) {
    filteredValues = values.filter((item) => item.valueNo === 0);
  }
  const currentValue = filteredValues ? filteredValues[0] : undefined;

  const component = getFieldUIComponent(config.basicType);
  const options = getFieldProps(
    component,
    { values: filteredValues, key, accessLevel, currencyCode },
    [],
    config.valueNo
  );
  options.fieldValueConfig = config;
  options.uiComponent = component;
  options.fieldValue = currentValue;
  return options;
}

function getFieldUIComponent(basicType: string): FieldUIComponent {
  switch (basicType) {
    case "Integer":
    case "Double":
      return FieldUIComponent.Number;
    case "Date":
    case "DateTime":
      return FieldUIComponent.Date;
    case "Boolean":
      return FieldUIComponent.Toggle;
    case "String":
    default:
      return FieldUIComponent.Text;
  }
}

/**
 *
 * @param key Converts key to uppercase string <variant>.<key>
 * @returns
 */
export function fieldKeyToString(key: FieldKey): string {
  return key.variant + "." + key.key.toUpperCase();
}

/**
 * Parse a string containing a FieldKey
 * @param fieldKey
 */
export function fieldKeyFromString(fieldKey: string): FieldKey {
  return {
    variant: fieldKey.split(".")[0] as FieldVariant,
    key: fieldKey.split(".")[1],
  };
}

/**
 * Map fields by string formatted FieldKey
 * @param fields Fields to include
 * @returns Field map
 */
export function mapFields<TField extends { key: FieldKey }>(
  fields: TField[] | undefined
): Record<string, TField> {
  return (
    fields?.reduce(
      (map: Record<string, TField>, field: TField) => ({
        ...map,
        [fieldKeyToString(field.key)]: field,
      }),
      {}
    ) ?? {}
  );
}

/**
 * Return field key for field of specified type.
 *
 * @param type the type the field key should correspond to.
 *
 * @param fields Fields to include
 * @returns FieldKey or undefined if not found.
 */
export function getFieldKey(
  type: FieldType,
  fields?: FieldConfig[]
): FieldKey | undefined {
  return fields?.find((f) => f.fieldType === type)?.key;
}

/**
 * Converts a boolean value to localized text.
 *
 * @param booleanValue - The boolean value to be converted.
 * @returns The localized string "Yes" if the boolean value is `true`,
 * or "No" if it's `false` or `undefined`.
 */
export function convertBooleanToText(
  booleanValue: boolean | undefined = false
): string {
  return booleanValue ? getI18n().t("common:yes") : getI18n().t("common:no");
}

/**
 * Adds a prefix to a value using the flowStep id.
 *
 * @param flowStep The flow step object containing an `id` property.
 * @param value The primary value to be prefixed.
 * @param secondValue (Optional) An additional value to be added after the primary value.
 * @returns The resulting string after adding the flowStep id prefix.
 */
export function addFlowStepPrefix(
  flowStep: FlowStep,
  value: string,
  secondValue?: string
): string {
  if (secondValue) {
    return `${flowStep.id}_${value}${secondValue}`;
  } else {
    return `${flowStep.id}_${value}`;
  }
}

/**
 * Removes the prefix before the first underscore in the fieldName,
 * which is assumed to be in the format of a flowStep.id followed by an underscore.
 *
 * @param fieldName The fieldName including a flowStep prefix.
 * @returns The fieldName without the prefix.
 */
export function removeFlowStepPrefix(fieldName: string): string {
  return fieldName.replace(/[A-F\d-]{36}_/, "");
}
