import {
  FormControlProps,
  SingleSelect,
  createFilterOptions,
} from "common/components";
import { Icon, ValidValue } from "core/api";
import { useTranslation } from "i18n";
import { ReactElement, SyntheticEvent, useEffect } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export interface FormSelectProps extends FormControlledFieldProps {
  /**  An array of ValidValues that will be displayed as options in the select. */
  validValues?: ValidValue[];
  /** Optional. Helper text that is displayed in the input box when empty */
  helperText?: string;
  /**  If set, allow to clear the select by pressing the x. */
  includeEmptyOption?: boolean;
  /** If auto focus on the select */
  autoFocus?: boolean;
  /**  Form control props */
  FormControlProps?: FormControlProps;
}
/**
 * Adds form handling for FormSelect.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param validValues The list of validValues.
 * @param props Any remaining properties.
 * @param includeEmptyOption If set, allow to clear the select by pressing the x.
 */
export const FormSelect = withFormController<FormSelectProps>(
  ({
    helperText,
    renderState,
    autoFocus,
    validValues,
    ...props
  }): ReactElement => {
    const { t } = useTranslation(["common"]);
    const {
      field,
      fieldState: { error },
      gridColumn,
      isDisabled,
      setExtendedRules,
    } = renderState;

    useEffect(() => {
      setExtendedRules({
        validate: {
          notEmptyRequiredValue: (value) => {
            if (
              props.required &&
              (value === null || (Array.isArray(value) && value.length === 0))
            ) {
              return t("common:forms.fieldIsMandatory");
            }
            return true;
          },
        },
      });
    }, [setExtendedRules, t, props.required]);

    // Remove the 'fieldName' prop from the input props for <SingleSelect>
    // This avoids the warning "React does not recognize the `fieldName` prop on a DOM element."
    const filteredProps: any = { ...props };
    delete filteredProps.fieldName;

    const selectedValue = getSelectedValue(field.value, validValues ?? []);

    const handleSingleChange = (
      _event: SyntheticEvent,
      value: ValidValue | null
    ) => {
      field.onChange(value ? value.id : "-1");
    };

    return (
      <SingleSelect<ValidValue>
        fullWidth
        autoFocus={autoFocus}
        id={selectedValue?.id}
        dataCyValue={props["data-cy"]}
        disabled={isDisabled}
        required={props.required}
        helperText={error?.message ?? helperText}
        value={selectedValue}
        groupBy={(option) => getGroupBy(option, validValues ?? [])}
        label={props.label || ""}
        includeEmptyOption={props.includeEmptyOption ?? false}
        onChange={handleSingleChange}
        options={validValues ?? []}
        getOptionId={getId}
        getOptionName={getName}
        getIcon={getIcon}
        filterOptions={filterOptions}
        {...filteredProps}
        formControlProps={{
          ...props.FormControlProps,
          sx: [
            { gridColumn },
            ...((Array.isArray(props.FormControlProps?.sx)
              ? props.FormControlProps?.sx
              : [props.FormControlProps?.sx]) ?? []),
          ],
        }}
      />
    );
  }
);

/**
 *  Helper function to provide the options id
 *
 * @param option
 *
 * @returns the option's id
 */
function getId(option: ValidValue): string {
  return option.id;
}

/**
 *  Helper function to provide the options name
 *
 * @param option
 *
 * @returns the options name
 */

function getName(option: ValidValue): string {
  return option.name;
}

/**
 *  Helper function to provide the options optional groupBy string
 *
 * @param option
 *
 * @returns the options groupBy string, or an empty string if not set or there is just one unique group.
 */
function getGroupBy(option: ValidValue, options: ValidValue[]): string {
  const uniqueGroups = new Set(options.map((opt) => opt.groupBy)).size;

  // Return groupBy if there are more than 1 unique group, otherwise return an empty string
  return uniqueGroups > 1 ? option.groupBy || "" : "";
}
/**
 * Return the selected value as a ValidValue
 *
 * @param value - field value ID
 * @param optionItems
 *
 * @returns
 */
function getSelectedValue(
  value: string | number,
  optionItems: ValidValue[]
): ValidValue | undefined {
  const selectedOption = optionItems.find(
    (option) => option.id === value?.toString()
  );
  return selectedOption || undefined;
}

/**
 * Configures filtering options
 *
 * Matches from the start of the options stringified name.
 *
 * @constant
 * @type {ReturnType<typeof createFilterOptions>}
 */
const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: ValidValue) => option.name,
});

/**
 *  Helper function to provide the optional icon
 *
 * @param option
 *
 * @returns the options icon
 */
function getIcon(option: ValidValue): Icon | undefined {
  return option.icon;
}
