import {
  FormCurrencyField,
  FormDatePicker,
  FormSelect,
  FormTextField,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { PayInvoiceFlowData, PayInvoiceFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type PayInvoiceProps = FlowStepComponentProps<
  PayInvoiceFlowStep,
  PayInvoiceFlowData
>;

/** Pay Invoice step component */
export function PayInvoice({
  control,
  data,
  flowStep,
}: PayInvoiceProps): ReactElement {
  const { t } = useTranslation(["invoice"]);
  const {
    paymentDate,
    remainingAmount,
    paidAmount,
    paymentReference,
    currencyCode,
    paymentType,
    applicablePaymentTypes,
  } = data;

  return (
    <StyledStepContentWrapper sx={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
      <FormCurrencyField
        fieldName={addFlowStepPrefix(flowStep, "remainingAmount")}
        defaultValue={remainingAmount}
        control={control}
        label={t("invoice:remainingAmount")}
        disabled
        currencyCode={currencyCode}
      />
      <FormCurrencyField
        fieldName={addFlowStepPrefix(flowStep, "paidAmount")}
        defaultValue={paidAmount}
        control={control}
        label={t("invoice:payInvoice:paidAmount")}
        currencyCode={currencyCode}
        required
        allowNegative={false}
        sx={{ gridColumn: "1 / span 1" }}
      />
      <FormDatePicker
        fieldName={addFlowStepPrefix(flowStep, "paymentDate")}
        defaultValue={paymentDate}
        control={control}
        label={t("invoice:payInvoice:paymentDate")}
        required
      />
      <FormSelect
        fieldName={addFlowStepPrefix(flowStep, "paymentType")}
        control={control}
        label={t("invoice:payInvoice:paymentType")}
        required
        defaultValue={paymentType.id}
        validValues={applicablePaymentTypes ?? []}
      />
      <FormTextField
        fieldName={addFlowStepPrefix(flowStep, "paymentReference")}
        defaultValue={paymentReference}
        control={control}
        label={t("invoice:payInvoice:paymentReference")}
        required
      />
    </StyledStepContentWrapper>
  );
}
