import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  FormPasswordField,
  FormPhoneNumberField,
  FormSelect,
  Stack,
  Typography,
} from "common/components";
import { nameofFactory } from "common/utils";
import { StorageKey } from "core/appSettings";
import { useAuthenticationContext } from "core/auth";
import { LoginData } from "core/auth/util";
import { Head } from "core/components";
import { useTranslation } from "i18n";
import { useAvailableLanguages } from "i18n/hooks";
import { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { StyledLoginForm } from "./OneTimePasswordForm.styles";

interface FormState {
  username: string;
  password: string;
  language: string;
}

export interface OneTimePasswordFormProps {
  cardMediaSrc: string;
  defaultLanguage: string;
}

/**
 * A login form with an image. This is a one time password form, meaning you first put in a
 * username, and make a request for a one time password. When the component receives a 200
 * from the server it will then change state to render the password input field.
 * If the environment variable displayLanguageSelector is true, the user will get a language selector
 * to select between the available languages. The selected language will be saved in local storage.
 * NOTE: Users need special approval on their account to use this login method.
 * @param cardMediaSrc The source for the image presented above the form on the login page
 * @param defaultLanguage - The language to be used for localization.
 * @returns A react jsx element
 */
export function OneTimePasswordForm({
  cardMediaSrc,
  defaultLanguage,
}: OneTimePasswordFormProps): ReactElement {
  const { t } = useTranslation(["common", "login"]);
  const { login, isLoggingIn, getOneTimePassword, isRequestingOTP } =
    useAuthenticationContext();

  const displayLanguageSelector =
    process.env.REACT_APP_DISPLAY_LANGUAGE_SELECTOR === "true" ?? false;
  const country = process.env.REACT_APP_SELFCARE_COUNTRY ?? "se";
  const availableLanguages = useAvailableLanguages();

  const [otpSuccess, setOtpSuccess] = useState(false);
  const nameof = nameofFactory<FormState>();

  const { handleSubmit, control, formState } = useForm<FormState>({
    defaultValues: { username: "", password: "", language: defaultLanguage },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const queryClient = useQueryClient();

  const onSubmit = (formData: FormState) => {
    const data: LoginData = {
      userName: formData.username,
      password: formData.password,
      isProvider: false,
      providerName: "",
      sessionState: "",
      code: "",
    };

    if (displayLanguageSelector) {
      localStorage.setItem(StorageKey.SELFCARE_LANGUAGE, formData.language);
    }

    queryClient.removeQueries();
    login(data);
  };

  const onRequestOTP = (formData: FormState) => {
    queryClient.removeQueries();
    getOneTimePassword(formData.username, (success) => {
      setOtpSuccess(success);
    });
  };

  const cancelLogin = () => setOtpSuccess(false);

  return (
    <>
      <Head>
        <title>{t("login:form.title", { lng: defaultLanguage })}</title>
      </Head>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardMedia
          sx={{ maxHeight: 250 }}
          component="img"
          src={cardMediaSrc}
          alt="login banner"
        />
        <CardContent sx={{ paddingLeft: 4, paddingRight: 4 }}>
          <Typography gutterBottom variant="h4" pb={2}>
            {t("login:form.submit", { lng: defaultLanguage })}
          </Typography>

          <StyledLoginForm
            onSubmit={handleSubmit(otpSuccess ? onSubmit : onRequestOTP)}
          >
            <FormPhoneNumberField
              country={country}
              fieldName={nameof("username")}
              label={t("login:form.username", { lng: defaultLanguage })}
              control={control}
              validationRules={{
                required: t("login:form.usernameValidationMessage", {
                  lng: defaultLanguage,
                }),
              }}
              disabled={otpSuccess}
            />

            {otpSuccess && (
              <FormPasswordField
                fieldName={nameof("password")}
                control={control}
                label={t("login:form.password", { lng: defaultLanguage })}
                validationRules={{ required: true }}
                helperText={t("login:form.otpHint", { lng: defaultLanguage })}
              />
            )}

            {otpSuccess && displayLanguageSelector && (
              <FormSelect
                fieldName={nameof("language")}
                label={t("login:form.language", { lng: defaultLanguage })}
                control={control}
                validValues={availableLanguages}
                data-cy={"language"}
              />
            )}
            <Stack
              direction="row"
              gap={2}
              mt={2}
              sx={{ justifyContent: "end", alignItems: "center" }}
            >
              <Button
                type="submit"
                loading={isLoggingIn || isRequestingOTP}
                data-cy="loginSubmit"
                disabled={!formState.isValid}
                sx={{ width: "45%" }}
              >
                {t(`login:form.${otpSuccess ? "submit" : "continue"}`, {
                  lng: defaultLanguage,
                })}
              </Button>

              {otpSuccess && (
                <Button variant="text" onClick={cancelLogin}>
                  {t("common:buttons.back", { lng: defaultLanguage })}
                </Button>
              )}
            </Stack>
          </StyledLoginForm>
        </CardContent>
      </Card>
    </>
  );
}
