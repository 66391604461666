import {
  formatCurrency,
  formatDate,
  getFieldDisplayValue,
  timeFromNow,
} from "common/utils";
import {
  EntityData,
  FieldUIComponent,
  TableOption,
  ValueConfig,
} from "core/api";
import { getEntityValue } from "core/components";

export const MAXIMUM_SEARCH_RESULT_ITEMS = 200;

/**
 * Find search option based on type.
 * @param options Search options
 * @param type Search type
 * @returns Target search option if it exists
 */
export function findSearchOption(
  options: TableOption[] | undefined,
  type: string
): TableOption | undefined {
  return options?.find(({ value }) => value === type);
}

export function checkKeyInEntity(
  entity: EntityData,
  key: keyof EntityData
): boolean {
  return key.split(".").reduce(
    ({ entity, val }, subkey) => {
      const containsKey = subkey in entity;
      const retEntity = containsKey ? entity[subkey] : entity;
      return { entity: retEntity, val: val && containsKey };
    },
    { entity: entity, val: true }
  ).val;
}

/**
 * Format value based on configuration.
 * @param valueConfig Formatting configuration for value
 * @param value Entity value
 * @returns The formatted value
 */
export function formatValue<TValue extends EntityData>(
  valueConfig: ValueConfig<TValue> | undefined,
  value: TValue
): string {
  if (!valueConfig) {
    return "";
  }
  switch (valueConfig.type) {
    case "TEXT":
      return valueConfig.keys.reduce((result: string, key) => {
        const keyValue = getEntityValue(value, key as string);
        return result.replace("<$>", `${keyValue ?? ""}`);
      }, valueConfig.pattern);
    case "DATE": {
      const timestamp = getEntityValue(
        value,
        valueConfig.timestampKey as string
      );
      return valueConfig.timeFromNow
        ? timeFromNow(timestamp)
        : formatDate(timestamp, valueConfig.format);
    }
    case "FIELD_KEY":
      return valueConfig.patternKeys.reduce((result: string, key) => {
        const keyValue = getFieldDisplayValue(
          key,
          value.uiComponent ?? FieldUIComponent.Text,
          value?.fields ?? []
        );

        return result.replace("<$>", `${keyValue ?? ""}`);
      }, valueConfig.pattern);

    case "CURRENCY":
      try {
        const amount = getEntityValue(value, valueConfig.amountKey as string);
        const code = getEntityValue(value, valueConfig.codeKey as string);
        return formatCurrency(Number(amount), `${code}`);
      } catch (error) {
        return "";
      }
    default:
      return "";
  }
}

/**
 * Creates a regular expression from a search query that supports the "*" wildcard.
 *
 * This function treats "*" as a wildcard that can match any sequence of characters,
 * and escapes other special regex characters to allow for a literal match.
 *
 * @param searchQuery - The search query containing optional "*" wildcards.
 */
export function createWildcardRegex(searchQuery: string): RegExp {
  // Escape special characters except "*", then replace "*" with ".*" for wildcard matching
  const escapedQuery = searchQuery
    .replace(/[-/\\^$+?.()|[\]{}]/g, "\\$&") // Escape special characters
    .replace(/\*/g, ".*"); // Replace "*" with ".*" for wildcards

  return new RegExp(`^${escapedQuery}$`, "i");
}
