import {
  Box,
  Button,
  CircularProgress,
  FormControllerContext,
  FormSelect,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import {
  AddressFieldsFlowData,
  AddressFieldsFlowStep,
  FieldsFlowData,
  FieldsFlowStep,
} from "core/api";
import { useTranslation } from "i18n";
import { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { theme } from "styles/theme";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";
import { Fields } from "./Fields";

export type AddressFieldsProps = FlowStepComponentProps<
  AddressFieldsFlowStep,
  AddressFieldsFlowData
>;

/** The AddressFields component can be used to edit the fields of a customer
 *  address. In addition to the standard functionality of a Fields component,
 * it provides a 'copy from' functionality, which allows the user to select
 * another address belonging to the same customer, and copy the field values
 * from the selected address into the compatible fields of the address being
 * edited or created. */
export function AddressFields({
  control,
  data,
  flowStep,
  ...props
}: AddressFieldsProps): JSX.Element {
  const { t } = useTranslation(["common"]);

  const fieldsData: FieldsFlowData = {
    ...data,
    uiComponent: "Fields",
  };

  const fieldsFlowStep: FieldsFlowStep = {
    ...flowStep,
    uiComponent: "Fields",
  };

  const { copyFromAddressTypeId } = flowStep.config;

  const addressesOptions =
    copyFromAddressTypeId === -1
      ? data.customerAddresses
        ? data.customerAddresses.map((address) => {
            return {
              id: address.id,
              name: address.name,
              description: "",
            };
          })
        : []
      : [];

  const context = useFormContext();
  const fieldNameCopyFromFieldName = addFlowStepPrefix(flowStep, "copyFrom");

  const selectedAddress: string = context?.watch(
    fieldNameCopyFromFieldName,
    ""
  );

  const { evaluateOnChange } = useContext(FormControllerContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleCopyButtonClick = () => {
    setIsLoading(true);
    evaluateOnChange?.(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {copyFromAddressTypeId === -1 ? (
        <StyledStepContentWrapper>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <FormSelect
              validValues={addressesOptions}
              defaultValue={""}
              includeEmptyOption
              fieldName={fieldNameCopyFromFieldName}
              control={control}
              label={t("common:copyFrom")}
            />
            <Button
              variant={"outlined"}
              onClick={handleCopyButtonClick}
              disabled={selectedAddress === ""}
              data-cy="buttonCopy"
            >
              {t("common:buttons.copy")}
            </Button>
          </Box>
        </StyledStepContentWrapper>
      ) : (
        <StyledStepContentWrapper>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant={"outlined"}
              onClick={handleCopyButtonClick}
              disabled={false}
              data-cy="buttonCopy"
            >
              {t("common:buttons.copyFrom", {
                addressType: data.copyFromAddressType.name,
              })}
            </Button>
          </Box>
        </StyledStepContentWrapper>
      )}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Fields
          control={control}
          data={fieldsData}
          flowStep={fieldsFlowStep}
          {...props}
        ></Fields>
      )}
    </>
  );
}
