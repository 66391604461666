import { compareAsDate, compareAsNumber, fieldKeyToString } from "common/utils";
import {
  FieldConfig,
  FieldKey,
  TableColumnConfig,
  TaggedTableData,
  ValueConfig,
} from "core/api";
import { formatValue, mapFields } from "core/components";
import { useMemo } from "react";

/**
 * Handles table client side filtering with text and tags, as well as sorting.
 *
 * @remarks
 * - this hook is used for the ConfigTable component (via useTableState hook)
 *   and for the FormTable component (via the useTableSearch hook)
 * - the function is memoized, so it will perform filtering and sorting only if the
 *   inputs parameters have changed.
 *
 * @param enabled Set to **true** to enable sorting, set to **false**
 *  to disable sorting.
 *
 * @param columnConfigs An array that contains the configuration of each column
 *   in the table. Is used to get the data type of the values in each column,
 *   so that sorting is done properly. Column of type DATE is sorted
 *   chronologically, all other types are sorted numerically or
 *   alphabetically.
 *
 * @param fieldConfigs An Array that contains a FieldConfig for each field displayed in the current table
 *
 * @param items Table data that will be filtered and sorted
 *
 * @param search Search text state
 *
 * @param sortKey The **sortKey** identifying the column on which the table
 *   items should be sorted. Set to undefined to disabled sorting
 *
 * @param ascending Set to "true" to sort in ascending order, set to false to
 *   sort in descending order. Defaults to **true**
 *
 * @param setSelectedTags Function that sets selected tags state
 *
 * @param selectedTags Selected tags state
 *
 * @returns Search state and methods
 *
 */
export function useLocalSearch<TData extends TaggedTableData = TaggedTableData>(
  enabled: boolean,
  columnConfigs: TableColumnConfig[],
  fieldConfigs: FieldConfig[],
  items: TData[],
  search: string | undefined,
  sortKey: string | undefined,
  ascending = true,
  setSelectedTags?: (tags: string[]) => void,
  selectedTags?: string[] | undefined
) {
  const columns = columnConfigs.map(({ column }) => column);

  const tags: string[] = useMemo(
    () =>
      enabled
        ? Array.from(new Set(items?.flatMap((row) => row.tags ?? [])))
        : [],
    [enabled, items]
  );

  const toggleTag = (tag: string) => {
    if (selectedTags?.includes(tag)) {
      setSelectedTags?.(
        selectedTags.filter((currentTag) => currentTag !== tag)
      );
    } else {
      setSelectedTags?.([...(selectedTags ?? []), tag]);
    }
  };

  const isSelectedTag = (tag: string) => !!selectedTags?.includes(tag);

  const filteredItems: TData[] = useMemo(() => {
    return enabled
      ? items.filter(
          (item) =>
            matchesRowValues(columns, item, search) &&
            hasOneOrMoreTags(item.tags, selectedTags)
        )
      : [];
  }, [enabled, columns, items, search, selectedTags]);

  const filteredAndSortedItems: TData[] = useMemo(() => {
    if (!(enabled && sortKey && columnConfigs.length > 0)) {
      return filteredItems;
    }

    const colIndex = columnConfigs.findIndex(
      (column) => column.sortKey === sortKey
    );
    if (colIndex === -1) {
      console.error(
        `sortKey property has a value '${sortKey}' that does not match any sortKey in the columnConfigs.`
      );
      return filteredItems;
    }

    // Note: Sorting columns that contain DATES and CURRENCY amounts require
    // special handling. The others are handled with a default sorting
    // function that performs an alphabetical sort.

    if (columnConfigs[colIndex].column.type === "DATE") {
      // This condition handles the FieldId.CreditRows.FROM
      // and FieldId.CreditRows.TO table columns, for example
      return filteredItems.sort((a, b) =>
        compareAsDate(
          extractValue(a, sortKey),
          extractValue(b, sortKey),
          ascending
        )
      );
    }

    if (columnConfigs[colIndex].column.type === "CURRENCY") {
      // This condition handles the FieldId.CreditRows.INVOICED_AMOUNT,
      // FieldId.CreditRows.CREDITED_NET, and
      // TableFields.FieldId.CreditRows.CREDITED_TOTAL cases, for example
      return filteredItems.sort((a, b) =>
        compareAsNumber(
          extractValue(a, sortKey),
          extractValue(b, sortKey),
          ascending
        )
      );
    }

    if (
      fieldConfigs.length > 0 &&
      columnConfigs[colIndex].column.type === "FIELD_KEY"
    ) {
      // In the case of FIELD_KEY, the data type needs to be read from the
      // FieldConfig matching with the field key

      const fieldColumn = columnConfigs[colIndex].column as {
        /** Value type */
        type: "FIELD_KEY";
        key: FieldKey;
        pattern: string;
        patternKeys: FieldKey[];
      };

      const sortFieldConfig =
        mapFields(fieldConfigs)[fieldKeyToString(fieldColumn.key)];
      switch (sortFieldConfig.values[0].basicType) {
        case "DATE":
        case "DATETIME":
        case "TIME":
          return filteredItems.sort((a, b) =>
            compareAsDate(
              extractFieldValue(a, fieldColumn.key),
              extractFieldValue(b, fieldColumn.key),
              ascending
            )
          );
        case "CURRENCY":
          return filteredItems.sort((a, b) =>
            compareAsNumber(
              extractFieldValue(a, fieldColumn.key),
              extractFieldValue(b, fieldColumn.key),
              ascending
            )
          );
      }
    }

    // In all other cases, just use alphabetical/numerical sorting
    return filteredItems.sort((a, b) => {
      const _a = extractValue(a, sortKey);
      const _b = extractValue(b, sortKey);
      if (_a < _b) {
        return ascending ? -1 : 1;
      } else if (_a > _b) {
        return ascending ? 1 : -1;
      } else {
        return 0;
      }
    });
  }, [enabled, filteredItems, columnConfigs, fieldConfigs, sortKey, ascending]);

  return {
    tags,
    toggleTag,
    isSelectedTag,
    filteredItems: filteredAndSortedItems,
  };
}

/**
 * Checks if no tags are selected or if any selected tag matches
 * @param tags All tags
 * @param selectedTags Selected tags
 * @returns If no tags are selected or if any selected tag matches
 */
function hasOneOrMoreTags(
  tags: string[] | undefined,
  selectedTags: string[] | undefined = []
) {
  return (
    selectedTags.length === 0 ||
    (tags?.reduce(
      (isIncluded, itemTag) => isIncluded && selectedTags.includes(itemTag),
      true as boolean
    ) ??
      false)
  );
}

/**
 * Checks if any column values matches search text value
 * @param columns Table column configs
 * @param item Table row data
 * @param search Search text
 * @returns If any column values matches search text value
 */
function matchesRowValues(
  columns: ValueConfig[],
  item: TaggedTableData,
  search: string | undefined
) {
  return (
    search === undefined ||
    search === "" ||
    !!columns
      .map((column) => formatValue(column, item))
      .find((value) => matchesValue(value, search))
  );
}

/**
 * Check if RegExp of normalized search value matches normalized
 * column value
 * @param value Column value
 * @param search Value to match
 * @returns If RegExp of normalized search value matches normalized
 * column value
 */
function matchesValue(value: string, search: string) {
  const normalizedSearch = search
    .replaceAll("*", ".*")
    .replaceAll("(", "\\(")
    .replaceAll(")", "\\)")
    .replaceAll("[", "\\[")
    .replaceAll("]", "\\]")
    .replaceAll("?", "\\?")
    .replaceAll("+", "\\+")
    .trim();
  return new RegExp("^" + normalizedSearch + "$", "i").test(value);
}

/**
 * Extract the value of a property identified by its name,
 * or the displayValue of field identified by its key.
 * First tries ordinary property named propOrFieldName,
 * then tries to find a field that has propOrFieldName as key,
 * picking the first field assuming no fields with the same name.
 *
 * @param data the data in a table cell
 * @param propOrFieldName The name of a property of data,
 *   or the key of a field in data.fields
 *
 * @returns value or empty string.
 */
function extractValue(data: TaggedTableData, propOrFieldName: string): string {
  if (data[propOrFieldName] !== undefined) {
    // Note that data[propOrFieldName] can be a string or a number
    return data[propOrFieldName];
  } else {
    // the field displayValue is always a string
    return (
      data["fields"]?.find((f) => f.key.key === propOrFieldName)?.values[0]
        .displayValue || ""
    );
  }
}

/**
 * Extract the field value for the given fieldKey.
 *
 * @param data the data in a table cell
 * @param fieldKey The key of a field in data.fields
 *
 * @returns The value of the matching field, or an empty string
 *   if no field with a matching key is found.
 */
function extractFieldValue(data: TaggedTableData, fieldKey: FieldKey): string {
  return (
    data["fields"]?.find(
      (f) => f.key.key === fieldKey.key && f.key.variant === fieldKey.variant
    )?.values[0].value || ""
  );
}
