export default {
  changePaymentMethod: {
    currentPaymentMethod:
      "Tu método de pago actual es {{currentPaymentMethodName}}",
    enterCreditCard:
      "Ingresa otra tarjeta de crédito a continuación para usar.",
    changePaymentMethod: "Cambiar método de pago",
  },
  renewProduct: "Es posible renovar el producto para obtener un mejor precio.",
  renewOptions: "Opciones de renovación",
} as const;
