import { FilterList, FilterListOff, Search } from "common/assets/icons";
import {
  Box,
  Button,
  ButtonProps,
  ClickAwayListener,
  Collapse,
  IconButton,
  SxProps,
  Theme,
  Tooltip,
} from "common/components";
import { useToggle } from "common/hooks";
import { FilterValueState, FilterValueStates, SearchFilter } from "core/api";
import { useTranslation } from "i18n";
import { MouseEventHandler, ReactElement, useState } from "react";
import { AppliedFilters } from "./AppliedFilters";
import { FilterPanelFields } from "./FilterPanelFields";
import {
  StyledFilterWrapper,
  StyledFiltersContainer,
  StyledSearchFieldContainer,
  StyledSearchFilterContainer,
} from "./SearchFilterPanel.styles";

interface SearchFilterPanelProps {
  /** Search field component */
  searchField?: ReactElement;
  /** State values for the filter fields */
  filterStates: FilterValueStates;
  /** Reset hook: gets called when the user clicks on the RESET button */
  resetFilterPanel(): void;
  /** Available filters */
  filterFields: SearchFilter[];
  /** onChange hook: gets called everytime the user changes the value of a filter field through the UI */
  getOnChange: <TValueState extends FilterValueState>(
    id: number
  ) => (state: TValueState) => void;
  /** If filters should be displayed initially */
  displayFilter?: boolean;
  /** This function is called when the user clicks on the Search button
   * or presses the Enter key when some search filters have the focus */
  triggerSearch(
    filterState?: (state: FilterValueStates) => FilterValueStates
  ): void;
  /** Text field value */
  activeSearchValue?: string;
  /** Search in entity */
  activeFilters?: FilterValueStates;
  /** If active search filters should be displayed */
  showActiveSearchFilters?: boolean;
  /** Style props */
  sx?: SxProps<Theme>;
  /** If search and reset buttons should be disabled */
  searchDisabled?: boolean;
}

export function SearchFilterPanel({
  searchField,
  displayFilter = false,
  filterStates,
  resetFilterPanel,
  getOnChange,
  filterFields,
  triggerSearch,
  activeSearchValue,
  activeFilters,
  showActiveSearchFilters,
  searchDisabled,
  sx,
}: SearchFilterPanelProps) {
  const [showFilter, setShowFilter] = useToggle(displayFilter);

  const { t } = useTranslation(["common"]);

  const filterText = showFilter
    ? t("common:filter.hideFilter")
    : t("common:filter.showFilter");

  const filterIcon = showFilter ? <FilterList /> : <FilterListOff />;

  const visibleFilterFields = filterFields.filter(
    ({ alwaysVisible }) => alwaysVisible
  );
  const hiddenFilterFields = filterFields.filter(
    ({ alwaysVisible }) => !alwaysVisible
  );

  return (
    <StyledFilterWrapper>
      <StyledSearchFilterContainer filtersOpen={showFilter} sx={sx}>
        <StyledSearchFieldContainer>
          {searchField}
          <FilterPanelFields
            filterFields={visibleFilterFields}
            getOnChange={getOnChange}
            filterStates={filterStates}
            triggerSearch={triggerSearch}
          />
        </StyledSearchFieldContainer>
        <SearchButton
          searchDisabled={searchDisabled}
          onClick={() => triggerSearch()}
          data-cy="searchButton"
          sx={{ gridArea: "searchButton", alignSelf: "center" }}
        />
        <Button
          type="reset"
          onClick={resetFilterPanel}
          color="inherit"
          variant="text"
          size="small"
          data-cy="resetButton"
          sx={{ gridArea: "resetButton", alignSelf: "center" }}
        >
          {t("common:buttons.reset")}
        </Button>
        <IconButton
          aria-label={filterText}
          title={filterText}
          size="small"
          onClick={setShowFilter}
          data-cy="btnToggleSearchFilter"
          sx={{ gridArea: "filterButton", alignSelf: "center" }}
        >
          {filterIcon}
        </IconButton>
        <Collapse
          in={showFilter}
          sx={{ gridArea: "filters", alignSelf: "start" }}
          data-cy="searchFilterPanel"
        >
          <StyledFiltersContainer>
            <FilterPanelFields
              filterFields={hiddenFilterFields}
              getOnChange={getOnChange}
              filterStates={filterStates}
              triggerSearch={triggerSearch}
            />
          </StyledFiltersContainer>
        </Collapse>
      </StyledSearchFilterContainer>
      <AppliedFilters
        searchValue={activeSearchValue}
        filterFields={filterFields}
        activeFilters={activeFilters}
        triggerSearch={triggerSearch}
        showActiveSearchFilters={showActiveSearchFilters}
      />
    </StyledFilterWrapper>
  );
}

interface SearchButtonProps extends Omit<ButtonProps, "children"> {
  searchDisabled: boolean | undefined;
}

export function SearchButton({
  onClick,
  searchDisabled,
  sx,
  ...props
}: SearchButtonProps) {
  const { t } = useTranslation(["common", "search"]);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (searchDisabled) {
      setOpen(true);
    } else {
      onClick?.(event);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={sx}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableHoverListener
          disableTouchListener
          title={t("search:searchKeywordRequired")}
        >
          <Button
            color="primary"
            variant="contained"
            size="small"
            aria-label={t("common:buttons.search")}
            onClick={handleOnClick}
            {...props}
          >
            <Search />
          </Button>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
}
