import {
  API,
  APIQueryResult,
  ProductOptionsContainer,
  useAPIQuery,
} from "core/api";

/**
 * Find all product offering options
 *
 * @param enabled Directive for useQuery.
 *
 * @returns
 */
export function useFetchAllProductOfferingOptions(
  customerNo: string,
  enabled = true
): APIQueryResult<ProductOptionsContainer> {
  return useAPIQuery(
    ["fetchAllProductOfferingOptions", customerNo],
    API.customerservice.productOfferingOptions,
    {
      pathParams: {
        customerNo: parseInt(customerNo),
      },
    },
    {
      enabled,
    }
  );
}
