import { StatusIcon } from "common/assets/icons";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  FilterOptionsState,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputAdornment,
  ListItem,
  StyledListItemText,
  StyledSelectContainer,
  TextField,
} from "common/components";
import { Icon, IconVariant } from "core/api";
import { ReactElement, useRef } from "react";

/**
 * Props for the single select, almost identical to the existing MultiSelectProps except value type.
 */
interface SingleSelectProps<T> {
  /**
   * The field label
   */
  label: string;
  /**
   * OnChange function to call then selection changes.
   */
  onChange(
    event: React.SyntheticEvent,
    value: T | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined
  ): void;
  /**
   * Component id
   */
  id?: string;
  /** Data-cy value */
  dataCyValue?: string;
  /** If disabled */
  disabled?: boolean;
  /**  If set, allow to clear the select by pressing the x. */
  includeEmptyOption?: boolean;
  /** Optional. Helper text that is displayed in the input box when empty */
  helperText?: string;
  /** If required */
  required?: boolean;
  /** If auto focus on the select */
  autoFocus?: boolean;
  /**
   * Current value
   */
  value?: T;
  /**
   * Selectable values
   */
  options: readonly T[];
  /**
   * If Select takes fullWidth
   */
  fullWidth?: boolean;
  /**
   * Function to retrieve the groupBy name from T
   */
  groupBy?(option: T): string;
  /**
   * Function to retrieve the id from T
   */
  getOptionId(option: T): string;
  /**
   * Function to retrieve the name from T
   */
  getOptionName(option: T): string;
  /**
   * Function to retrieve the icon name from T
   */
  getIcon?(option: T): Icon | undefined;

  /**
   * Function to match the text written in the input field with T items.
   */
  filterOptions?(options: T[], state: FilterOptionsState<T>): T[];
  /**  Form control props */
  formControlProps?: FormControlProps;
}

/**
 * Single-selection combobox based on the MUI Autocomplete component
 *
 * @remarks A special SingleSelect is needed since the multiple prop to the AutoComplete change
 * types of input resulting is difficult code. For a cleaner interface this is an intended
 * duplication of the MultiSelect. Also, rendering is done without checkboxes and chips since it is a single select.
 */
export function SingleSelect<T>({
  label,
  onChange,
  id,
  value,
  options,
  fullWidth = false,
  dataCyValue,
  disabled,
  includeEmptyOption = true,
  formControlProps,
  required = false,
  autoFocus = false,
  helperText,
  groupBy,
  getOptionId,
  getOptionName,
  getIcon,
  filterOptions,
  ...props
}: Readonly<SingleSelectProps<T>>): ReactElement {
  const ref = useRef<HTMLDivElement>(null);

  const { icon } = (value as { icon?: Icon }) || {};
  const startAdornment = icon ? (
    <Box sx={{ alignItems: "center" }}>
      <StatusIcon
        size="medium"
        sx={{
          display: "grid",
          alignItems: "center",
        }}
        iconName={icon.name ?? ""}
        iconVariant={icon.variant ?? IconVariant.SUCCESS}
      />
    </Box>
  ) : null;

  // If no dataCyValue is provided we add the label or unlabeledSelect
  const dataCyAttribute =
    dataCyValue === undefined
      ? label?.trim()
        ? label.replace(/\s/g, "")
        : "unlabeledSelect"
      : undefined;

  return (
    <FormControl {...formControlProps}>
      <StyledSelectContainer fullWidth={fullWidth}>
        <Autocomplete
          disabled={disabled}
          onChange={onChange}
          clearOnEscape={includeEmptyOption}
          multiple={false}
          id={id}
          groupBy={groupBy}
          filterOptions={filterOptions}
          value={value ?? null}
          options={options}
          defaultValue={null}
          handleHomeEndKeys
          disableClearable={!includeEmptyOption}
          clearOnBlur
          size="small"
          renderOption={(props, option) => {
            const optionName = getOptionName(option);
            const optionsIcon = getIcon?.(option);
            // key must be AFTER ...props to overwrite the key that is contained in ...props
            return (
              <ListItem
                {...props}
                key={getOptionId(option)}
                style={{ paddingLeft: 16 }}
              >
                {optionsIcon && (
                  <StatusIcon
                    size="medium"
                    iconName={optionsIcon.name}
                    iconVariant={optionsIcon.variant}
                    sx={{
                      marginRight: 1,
                      display: "grid",
                      alignItems: "center",
                    }}
                  />
                )}
                <StyledListItemText
                  primary={optionName}
                  data-cy={optionName?.replace(/[\s.:()]/g, "")}
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
            );
          }}
          getOptionLabel={getOptionName}
          renderInput={(params) => (
            <TextField
              autoFocus={autoFocus}
              ref={ref}
              {...params}
              label={label}
              required={required}
              InputLabelProps={{
                shrink: true, // Force the label to always appear at the top
              }}
              // only adds data-cy if we have a dataCyAttribute
              {...(dataCyAttribute ? { "data-cy": dataCyAttribute } : {})}
              InputProps={{
                ...params.InputProps,
                startAdornment: startAdornment ? (
                  <InputAdornment position="end">
                    {startAdornment}
                  </InputAdornment>
                ) : undefined,
              }}
            />
          )}
          {...props}
        />
        {helperText && (
          <FormHelperText error={true}>{helperText}</FormHelperText>
        )}
      </StyledSelectContainer>
    </FormControl>
  );
}
