import { TableCellProps, Tooltip } from "@mui/material";
import { Checkbox, SxProps, TableCell, Theme, styled } from "common/components";
import { Link } from "common/routing";
import { getFieldDisplayValue } from "common/utils";
import React, { ReactElement, useCallback, useMemo } from "react";
import { combineSx } from "styles/theme/themeUtils";
import { FieldUIComponent } from "../../../api";

/**
 *
 * @param key
 * @returns Function that gives a concrete component, displaying given value of a table item as text
 */
export function textFromKeyBase<T extends Record<string, any>>(
  key: string | keyof T
): (a: T) => ReactElement {
  return (a: T) => {
    return <React.Fragment>{a[key as keyof T]}</React.Fragment>;
  };
}

export function textFromFunction<T>(
  fun: (a: T) => string
): (b: T) => ReactElement {
  return (b: T) => {
    return <React.Fragment>{fun(b)}</React.Fragment>;
  };
}

/**
 *
 * @param getValue
 * @returns Function that gives a concrete component, displaying a checkbox with
 *          the value from getValue applied to a table item
 */
export function checkBoxBase<T>(
  getValue: (a: T) => boolean
): (a: T) => ReactElement {
  return (a: T) => {
    return <Checkbox disabled checked={getValue(a)} />;
  };
}

/**
 * Decorator for base function that builds a link that
 * around content
 * @param fun Function to build content of link tag from a table item
 * @param constructLink Function to build a link from a table item
 * @returns
 */
export function linkDecorator<T>(
  fun: (a: T) => ReactElement,
  constructLink: (a: T) => string
): (a: T) => ReactElement {
  return (obj: T) => {
    return <a href={constructLink(obj)}>{fun(obj)}</a>;
  };
}

export interface DBTableCellProps<T> {
  row: T;
  /** Field name used as data-cy element */
  fieldName?: string;
  sx?: SxProps<Theme>;
  type?: string;
}

interface StyledTableCellProps {
  isOnClickFunction: boolean;
  isIcon: boolean;
}

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (propName) =>
    propName !== "isOnClickFunction" && propName !== "isIcon",
})<StyledTableCellProps>(({ isOnClickFunction, isIcon }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: !isIcon ? "ellipsis" : "unset",
  padding: "5px",
  "&:hover": isOnClickFunction
    ? {
        textDecoration: "underline",
        cursor: "pointer",
      }
    : undefined,
}));

/**
 * Extracts display value from the provided table data.
 */
function getDisplayValueFromTableData(
  tableData: ReactElement | null
): string | null {
  const entityFields = Array.isArray(tableData?.props?.entity?.fields)
    ? tableData.props.entity.fields
    : [];
  return getFieldDisplayValue(
    tableData?.props?.fieldKey ?? "",
    FieldUIComponent.Text,
    entityFields
  );
}

/**
 * Create DB table cell
 * @param tableCellData Function that builds the content of the DBTableCell. Construct
 *                      this function from base elements + decorators defined in this file
 * @param alignment How content should be aligned within the cell
 * @param padding Padding of content in the cell
 * @param type The field type
 * @param onClick The onClick function
 * @param sx Styling to apply to table cell
 * @returns Function that creates a DBTableCell component to display a table item
 */
export function createDBTableCell<T>(
  tableCellData: (a: T) => ReactElement | null,
  alignment: TableCellProps["align"],
  padding: TableCellProps["padding"],
  type: string,
  onClick?: (row: T) => string | (() => void) | undefined,
  sx?: SxProps<Theme>
): (a: DBTableCellProps<T>) => ReactElement {
  return function DBTableCell({
    row,
    fieldName,
    sx: _sx,
  }: DBTableCellProps<T>): ReactElement {
    const _onClick = useMemo(() => onClick?.(row), [row]);
    const isIcon = type === "BOOKMARK" || type === "DOWNLOAD";

    const handleOnClick = useCallback(() => {
      const selection = window.getSelection()?.toString() ?? "";
      if (selection.length === 0 && typeof _onClick === "function") {
        _onClick();
      }
    }, [_onClick]);

    const tableData = useMemo(() => tableCellData(row), [row]);
    // Ensure that entityFields is always an array to avoid errors with array methods
    const displayValue = getDisplayValueFromTableData(tableData);

    return (
      <Tooltip
        title={displayValue ?? ""}
        disableHoverListener={isIcon}
        PopperProps={{
          sx: {
            "&[data-popper-reference-hidden]": {
              visibility: "hidden",
              pointerEvents: "none",
            },
          },
        }}
      >
        <StyledTableCell
          isOnClickFunction={typeof _onClick === "function"}
          data-cy={fieldName}
          align={alignment}
          padding={padding}
          onClick={handleOnClick}
          sx={combineSx(sx, _sx)}
          isIcon={isIcon}
        >
          {typeof _onClick === "string" ? (
            <Link to={_onClick} color="inherit" underline="hover">
              {tableData}
            </Link>
          ) : (
            tableData
          )}
        </StyledTableCell>
      </Tooltip>
    );
  };
}

/**
 * Create DB table cell
 * @param tableCellData Function that builds the content of the DBTableCell. Construct
 *                      this function from base elements + decorators defined in this file
 * @param alignment How content should be aligned within the cell
 * @param padding Padding of content in the cell
 * @param type The field type
 * @param sx Styling to apply to table cell
 * @returns Function that creates a DBTableCell component to display a table item
 */
export function createDBTableCellWithoutOnClick<T>(
  tableCellData: (row: T) => ReactElement | null,
  alignment: TableCellProps["align"],
  padding: TableCellProps["padding"],
  type: string,
  sx?: SxProps<Theme>
): (a: DBTableCellProps<T>) => ReactElement {
  return function DBTableCell({
    row,
    fieldName,
    sx: _sx,
  }: DBTableCellProps<T>): ReactElement {
    const tableData = useMemo(() => tableCellData(row), [row]);
    const isIcon = type === "BOOKMARK" || type === "DOWNLOAD";
    // Ensure that entityFields is always an array to avoid errors with array methods
    const displayValue = getDisplayValueFromTableData(tableData);

    return (
      <Tooltip
        title={displayValue ?? ""}
        disableHoverListener={isIcon}
        PopperProps={{
          sx: {
            "&[data-popper-reference-hidden]": {
              visibility: "hidden",
              pointerEvents: "none",
            },
          },
        }}
      >
        <StyledTableCell
          isOnClickFunction={false}
          data-cy={fieldName}
          align={alignment}
          padding={padding}
          sx={combineSx(sx, _sx)}
          isIcon={isIcon}
        >
          {tableData}
        </StyledTableCell>
      </Tooltip>
    );
  };
}
