import { ChevronRight, ExpandMore, MoreVert } from "common/assets/icons";
import {
  Checkbox,
  IconButton,
  Radio,
  TableCell,
  Tooltip,
} from "common/components";
import { indexOfElement } from "common/utils";
import { useTranslation } from "i18n";
import {
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
} from "react";
import { BilliantTableBodyProps } from "../BilliantTableBody";
import { StyledTableRow } from "../BilliantTableBody.styles";
import { useCollapseState } from "../useCollapseState";

export interface BilliantTableRowProps<TData>
  extends Pick<
      BilliantTableBodyProps<TData>,
      | "headers"
      | "tableActions"
      | "hasActions"
      | "enableSelection"
      | "selectionVariant"
      | "handleSelect"
      | "setMenuState"
      | "generateRowKey"
      | "collapsibleRows"
    >,
    ReturnType<typeof useCollapseState> {
  row: TData;
  index: number;
  isSelected: boolean;
  rowHasCollapse?: boolean;
  indent?: boolean;
  "data-cy"?: string;
  /** If disabled actions should be hidden */
  hideDisabledActions?: boolean;
}

export function BilliantTableRow<TData>({
  row,
  headers,
  tableActions,
  hasActions,
  index,
  isSelected,
  handleSelect,
  enableSelection,
  selectionVariant,
  collapsibleRows,
  rowHasCollapse,
  isOpen,
  toggleCollapse,
  setMenuState,
  generateRowKey,
  indent,
  hideDisabledActions,
  "data-cy": dataCy,
}: Readonly<BilliantTableRowProps<TData>>): ReactElement {
  const { t } = useTranslation(["common"]);
  const isMultiselect = useMemo(
    () => selectionVariant === "MULTI",
    [selectionVariant]
  );
  /**
   * Handles opening the menu for a table row.
   * Selects the row if not already selected
   *
   * @param tableItem - The table item for which the menu should be opened.
   */
  const openMenu = useCallback(
    (tableItem: TData) => (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!isMultiselect && !isSelected) {
        handleSelect(row, "single");
      }

      setMenuState({
        menuAnchor: event.currentTarget,
        selectedElement: tableItem,
      });
    },
    [handleSelect, isMultiselect, isSelected, row, setMenuState]
  );

  /**
   * Handles checkbox click event.
   * It performs selection logic based on the provided row data and mouse event properties.
   *
   * @param row The data associated with the clicked row.
   * @param event The mouse event associated with the click.
   *
   */
  const handleCheckboxClick = useCallback(
    (row: TData) => (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (event.shiftKey) {
        event.preventDefault();
      }
      if (isMultiselect) {
        handleSelect(row, event.shiftKey ? "multi" : "current");
      } else {
        handleSelect(row, "single");
      }
    },
    [handleSelect, isMultiselect]
  );

  /**
   * Handles row click event.
   *
   * @param event The mouse event associated with the click.
   * @param item The data associated with the clicked row.
   *
   * @remarks
   * It performs selection logic based on the provided row data, mouse event properties,
   * and text selection status. The selection logic is executed only when there is no text selected.
   * This is so that we can select text in a row without it selecting the row.
   *
   */
  const handleRowClick = useCallback(
    (event: MouseEvent<HTMLTableRowElement>, item: TData) => {
      if (event.shiftKey) {
        event.preventDefault();
      }
      const selection = window.getSelection()?.toString() ?? "";
      if (selection.length === 0) {
        if (isMultiselect) {
          handleSelect(item, event.shiftKey ? "multi" : "current");
        } else {
          handleSelect(item, "single");
        }
      }
    },
    [handleSelect, isMultiselect]
  );

  const rowOnKeyDown = useCallback(
    (row: TData) => (event: KeyboardEvent<HTMLTableRowElement>) => {
      const { currentTarget, key, shiftKey } = event;
      const index = indexOfElement(currentTarget);

      if (document.activeElement === currentTarget) {
        switch (key) {
          case "ArrowDown":
            {
              event.preventDefault();
              const nextSibling =
                currentTarget.nextSibling as HTMLElement | null;
              nextSibling?.focus();
              if (shiftKey && enableSelection && isMultiselect) {
                handleSelect(row, "next");
              }
            }
            break;
          case "ArrowUp":
            {
              if (index > 1) {
                event.preventDefault();
              }
              const previousSibling =
                currentTarget.previousSibling as HTMLElement | null;
              previousSibling?.focus();
              if (shiftKey && enableSelection && isMultiselect) {
                handleSelect(row, "previous");
              }
            }
            break;
          case "Enter":
            currentTarget.querySelector("a")?.click();
            break;
          case " ": // Space
            if (enableSelection) {
              event.preventDefault();
              if (isMultiselect) {
                handleSelect(row, shiftKey ? "multi" : "current");
              } else {
                handleSelect(row, "single");
              }
            }
            break;
          default:
            break;
        }
      }
    },
    [handleSelect, enableSelection, isMultiselect]
  );

  return (
    <StyledTableRow
      hover
      selected={isSelected}
      data-cy={dataCy}
      data-id={generateRowKey(row, index)}
      onClick={(event) => enableSelection && handleRowClick(event, row)}
      tabIndex={0}
      onKeyDown={rowOnKeyDown(row)}
    >
      {collapsibleRows && (
        <TableCell sx={{ padding: 0.5 }}>
          {rowHasCollapse && (
            <IconButton
              aria-label={t("common:table.expandRow")}
              size="small"
              onClick={toggleCollapse(index)}
              data-cy="collapseRow"
            >
              {isOpen(index) ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
          )}
        </TableCell>
      )}
      {enableSelection && (
        <TableCell padding="checkbox">
          {selectionVariant === "MULTI" ? (
            <Checkbox
              data-cy="selectItem"
              checked={isSelected}
              onClick={handleCheckboxClick(row)}
            />
          ) : (
            <Radio
              data-cy="selectItem"
              checked={isSelected}
              onClick={handleCheckboxClick(row)}
            />
          )}
        </TableCell>
      )}
      {headers.map(({ sortKey, ColumnCell }, index) => {
        return (
          <ColumnCell
            key={generateRowKey(row, index) + "_" + sortKey}
            fieldName={sortKey}
            row={row}
            sx={index === 0 && indent ? { paddingLeft: 3 } : undefined}
          />
        );
      })}
      {tableActions.length > 0 || hideDisabledActions ? (
        <TableCell align="left" sx={{ height: "47px" }}>
          <Tooltip title={t("common:table.rowActionMenu.tooltip")}>
            <IconButton
              size="small"
              arial-label={t("common:table.rowActionMenu.ariaLabel")}
              onClick={openMenu(row)}
              data-cy="rowActionsMenu"
            >
              <MoreVert />
            </IconButton>
          </Tooltip>
        </TableCell>
      ) : (
        hasActions && <TableCell sx={{ height: "47px" }}></TableCell>
      )}
    </StyledTableRow>
  );
}
