import { create } from "zustand";
import { persist } from "zustand/middleware";
import { StorageKey } from "../../appSettings";

interface NavData {
  /** The URL to navigate to */
  url: string;
  /** The title that will be used */
  title: string;
  /** The entity type that will be displayed */
  entityType: string;
}

const initialState: Partial<NavigationState> = {
  backTo: undefined,
  current: undefined,
};

/**
 * Represents the state for navigation in the application.
 */
interface NavigationState {
  /** The navigation data to go back to a previous location */
  backTo?: NavData;
  /** The current navigation entry data */
  current?: NavData;
  /**
   * Updates the current navigation entry and sets `backTo`
   * to the previous `current` if there's a change in title.  If the title in `current` and
   * `backTo` match, `backTo` is cleared.
   *
   * @param current - The new current navigation entry.
   */
  setCurrent: (current: NavData) => void;
  /**
   * Sets the `backTo` entry to the current navigation entry and clears `current`.
   * If `current` is undefined, `backTo` is not updated.
   */
  setBackTo: () => void;
  /** Clears the navigation data from session storage and resets the state */
  clearStorage: () => void;
}

/**
 * Creates a navigation store using Zustand with persistence in session storage.
 */
const useNavigationStore = create<NavigationState>()(
  persist(
    (set) => ({
      ...initialState,
      setCurrent: (current: NavData) => {
        set((state) => {
          let newBackTo = state.backTo;

          // Check if `backTo` should be updated based on the new `current`
          if (state.current && current.title !== state.current.title) {
            newBackTo = state.current;
          }

          const updatedState = {
            current,
            backTo: newBackTo,
          };

          // Clear `backTo` if it title matches `current`
          if (updatedState.current?.title === updatedState.backTo?.title) {
            updatedState.backTo = undefined;
          }

          return updatedState;
        });
      },
      setBackTo: () => {
        set((state) => {
          if (state.current) {
            return {
              backTo: state.current,
              current: undefined,
            };
          }
          return state;
        });
      },
      clearStorage: () => {
        sessionStorage.removeItem(StorageKey.BACK_TO);
        set(initialState);
      },
    }),
    {
      name: StorageKey.BACK_TO,
      getStorage: () => sessionStorage,
    }
  )
);

export default useNavigationStore;
