import { Close } from "common/assets/icons";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from "common/components";
import { useFetchIcons } from "core/api";
import { useTranslation } from "i18n";
import { ChangeEvent, ReactElement, useState } from "react";

export interface IconSelectorDialogProps {
  open: boolean;
  openCloseDialog: any;
  saveIcon: any;
  value: string;
}
/**
 * Component for visualizing, searching and selecting icons.
 */
export function IconSelectorDialog({
  open,
  openCloseDialog,
  saveIcon,
  value,
}: IconSelectorDialogProps): ReactElement | null {
  const { t } = useTranslation(["common"]);
  const [filterText, setFilterText] = useState("");
  const { data: icons } = useFetchIcons();

  const [page, setPage] = useState(1);

  const handleChange = (_event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const searchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilterText(e.target.value);
    setPage(1);
  };

  const SearchBar = (filterText: string) => {
    return (
      <TextField
        autoFocus
        label={t("common:forms.iconSelector.searchIcons")}
        value={filterText}
        fullWidth
        onChange={searchChange}
        margin="normal"
      />
    );
  };

  let filterIcons: string[] = [];

  icons &&
    icons.map((icon: string) => {
      if (icon.toLowerCase().indexOf(filterText.toLowerCase()) !== -1) {
        filterIcons.push(icon);
      }
    });

  const pageSize: number = 42;
  const iconsOnPage = filterIcons.slice((page - 1) * pageSize, page * pageSize);
  const numPages = Math.ceil(filterIcons.length / pageSize);

  const [iconChoice, chooseIcon] = useState(value);

  const onClose = () => {
    openCloseDialog();
    chooseIcon(value);
  };

  return (
    <Dialog open={open} onClose={onClose} disableRestoreFocus>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", top: "0", right: "0" }}
      >
        <Close />
      </IconButton>
      <DialogTitle>{t("common:forms.iconSelector.selectIcon")}</DialogTitle>
      <DialogContent>
        {SearchBar(filterText)}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "center",
            rowGap: "20px",
          }}
        >
          <Box
            onWheel={(e) => {
              if (e.deltaY > 0 && page < numPages) setPage(page + 1);
              else if (e.deltaY < 0 && page > 1) setPage(page - 1);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignContent: "flex-start",
              width: "560px",
              height: "480px",
            }}
          >
            {iconsOnPage &&
              iconsOnPage.map((icon: string, index) => {
                if (icon == "") {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "80px",
                        width: "80px",
                        alignSelf: "center",
                        bgcolor: icon == iconChoice ? "primary.light" : "",
                        borderRadius: "8px",
                      }}
                    >
                      <IconButton
                        size={"large"}
                        onClick={() => chooseIcon(icon)}
                      >
                        <Icon fontSize={"large"}>{icon}</Icon>
                      </IconButton>

                      <Typography
                        sx={{
                          fontSize: "small",
                          maxWidth: "80%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {t("common:forms.iconSelector.noIcon")}
                      </Typography>
                    </Box>
                  );
                }

                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "80px",
                      width: "80px",
                      alignSelf: "center",
                      bgcolor: icon == iconChoice ? "primary.light" : "",
                      borderRadius: "8px",
                    }}
                  >
                    <IconButton size={"large"} onClick={() => chooseIcon(icon)}>
                      <Icon fontSize={"large"}>{icon}</Icon>
                    </IconButton>

                    <Typography
                      sx={{
                        fontSize: "small",
                        maxWidth: "80%",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {icon}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
          <Pagination count={numPages} page={page} onChange={handleChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => saveIcon(iconChoice)}>
          {t("common:buttons.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
