import { Error, Info, Warning } from "common/assets/icons";
import { Alert, AlertProps, Typography } from "common/components";
import { StepDetailsProps } from "core/actions/flows/ActionDialog/components";
import { Severity, StepMessage } from "core/api";
import { ReactElement } from "react";

type StepMessageProps = Pick<StepDetailsProps, "messages" | "apiError">;

export function StepMessages({
  messages,
  apiError,
}: StepMessageProps): ReactElement {
  const errors = [
    ...(apiError?.split("\n") ?? []),
    ...filterBySeverity(messages, Severity.ERROR),
  ];

  const warnings = filterBySeverity(messages, Severity.WARNING);

  const infos = filterBySeverity(messages, Severity.INFO);

  return (
    <>
      {infos.length > 0 && (
        <StepAlert color="info" icon={<Info />} messages={infos} />
      )}
      {errors.length > 0 && (
        <StepAlert color="error" icon={<Error />} messages={errors} />
      )}
      {warnings.length > 0 && (
        <StepAlert color="warning" icon={<Warning />} messages={warnings} />
      )}
    </>
  );
}

export interface StepAlertProps extends Pick<AlertProps, "color" | "icon"> {
  messages: string[];
}

export function StepAlert({
  color,
  icon,
  messages,
}: StepAlertProps): ReactElement {
  return (
    <Alert color={color} icon={icon} data-cy="stepMessages">
      {messages.map((message, index) => (
        <Typography key={index} variant="body2">
          {message}
        </Typography>
      ))}
    </Alert>
  );
}

function filterBySeverity(
  messages: StepMessage[] | undefined,
  severity: Severity
): string[] {
  return (
    messages
      ?.filter(
        (message) =>
          message.severity === severity &&
          message.text &&
          message.text.length > 0
      )
      .map(({ text }) => text) ?? []
  );
}
