/**
 * Enum defining the Date Alignment Types used in the Product Catalog.
 *
 * Corresponds to the java class com.billiant.billiant.util.code.ProductCatalogDateAlignmentType
 * which is a BilliantCodeObject
 */
export enum ProductCatalogDateAlignmentType {
  NONE = "NONE",
  PRODUCT_ACTIVATION_DATE = "PRODUCT_ACTIVATION_DATE",
  ORDER_DATE = "ORDER_DATE",
  END_DATE = "END_DATE",
}
