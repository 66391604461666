import { CheckboxIcon, StatusIcon } from "common/assets/icons";
import {
  fieldKeyToString,
  getFieldDisplayValue,
  getFieldIcon,
  mapFields,
} from "common/utils";
import {
  EntityData,
  EntityType,
  FieldConfig,
  FieldKey,
  ValueConfig,
} from "core/api";
import {
  BookmarkIcon,
  DownloadResourceButton,
  EntityStatusIcon,
  TableSkeleton,
  formatEntityUrl,
  formatValue,
  useEntityStatus,
} from "core/components";
import { useMemo } from "react";

interface FormattedFieldDisplayValueProps {
  fieldConfigs?: FieldConfig[];
  entity?: EntityData;
  fieldKey: FieldKey;
}

function FormattedFieldDisplayValue({
  fieldConfigs,
  entity,
  fieldKey,
}: FormattedFieldDisplayValueProps) {
  const icon = getFieldIcon(fieldKey, entity?.fields ?? []);

  const fieldConfig = mapFields(fieldConfigs)[fieldKeyToString(fieldKey)];
  const displayValue = fieldConfig
    ? getFieldDisplayValue(
        fieldKey,
        fieldConfig.uiComponent,
        entity?.fields ?? []
      )
    : "";
  return entity && fieldConfig ? (
    <>
      {icon.name ? (
        <StatusIcon
          iconName={icon.name}
          iconVariant={icon.variant}
          description={displayValue}
          withLabel
          tooltip={false}
          sx={{ display: "grid" }}
        />
      ) : (
        displayValue
      )}
    </>
  ) : (
    <TableSkeleton fullColumn />
  );
}

/**
 * Defines column content creator function
 *
 * @param entityType The type of entity whose data is displayed in the table
 *
 * @param fieldConfigs An Array that contains a FieldConfig for each field displayed in the current table
 *
 * @returns Column content creator function
 */
export function useTableColumnContent(
  entityType: EntityType,
  fieldConfigs: FieldConfig[]
) {
  const { getStatus } = useEntityStatus();

  return useMemo(
    () => ({
      createColumnContent(
        column: ValueConfig,
        entityType: EntityType,
        getEntityUrl?: (entity: EntityData) => string | undefined
      ) {
        return function ColumnContent(entry: EntityData) {
          switch (column.type) {
            case "CHECKBOX":
              return (
                <CheckboxIcon
                  checked={entry[column.valueKey] === column.expectedValue}
                />
              );
            case "ENTITY_STATUS": {
              const { status, reasonText: statusReason } = getStatus(
                entityType,
                entry
              );
              return status && statusReason ? (
                <EntityStatusIcon
                  entityType={entityType}
                  fontSize="small"
                  status={status}
                  description={statusReason}
                  tooltip={false}
                  withLabel
                />
              ) : null;
            }
            case "FIELD_KEY":
              return (
                <FormattedFieldDisplayValue
                  fieldConfigs={fieldConfigs}
                  fieldKey={column.key}
                  entity={entry}
                />
              );

            case "BOOKMARK":
              return (
                <BookmarkIcon
                  entityType={entityType}
                  entityData={entry}
                  getEntityUrl={getEntityUrl}
                />
              );
            case "DOWNLOAD": {
              const url = formatEntityUrl(
                entry,
                column.resourcePath,
                column.pathParams
              );
              const fileName =
                formatValue(
                  {
                    type: "TEXT",
                    keys: column.fileNameKeys,
                    pattern: column.fileNamePattern,
                  },
                  entry
                ) + column.fileExtension;
              return (
                <DownloadResourceButton
                  type="icon"
                  size="small"
                  fileName={fileName}
                  resourceUrl={url}
                />
              );
            }
            default:
              return <>{formatValue(column, entry)}</>;
          }
        };
      },
    }),
    [getStatus, fieldConfigs]
  );
}
