export default {
  urlNotFound: "URL no encontrada",
  notFoundOrNotAvailable:
    "El recurso solicitado no se encuentra o ya no está disponible",
  yourApplications: "Tus Aplicaciones",
  homePage: "Aplicaciones",
  applications: "Tus Aplicaciones",
  customerCare: "Atención al cliente",
  printerQueue: "Cola de impresora",
  loading: "Cargando...",
  loadingApp: "Cargando la aplicación web...",
  logout: "Cerrar sesión",
  switchTo: "Cambiar a",
  yourChangesAreSaved: "Tus cambios fueron guardados",
  filters: "Filtros",
  configuration: "Configuración",
  rowsperpage: "Filas por página",
  customerGroup: "Grupo de clientes",
  help: "Ayuda",
  notifications: "Notificaciones",
  dashboard: "Panel de control",
  documentation: "Documentación",
  downloadingDocument: "Descargando documento...",
  loadingDocument: "Cargando documento...",
  noDocumentAvailable:
    "El documento solicitado está restringido o no está disponible en este momento",
  nextPage: "Página siguiente",
  previousPage: "Página anterior",
  downloadingFile: "Descargando {{fileName}}",
  downloadFile: "Descargar {{fileName}}",
  fileName: "Nombre del archivo",
  about: "Acerca de ...",
  version: "Versión: {{version}}",
  errorNoIdentityProvider:
    "No se pudo recuperar ningún proveedor de identidad de la base de datos.",
  name: "Nombre",
  customerName: "Nombre del cliente",
  status: "Estado",
  address: "Dirección",
  invoices: "Facturas",
  inventories: "Inventarios",
  type: "Tipo",
  products: "Productos",
  showHideButton: {
    show: "Expandir el componente",
    hide: "Colapsar el componente",
  },
  bookmarks: {
    noFoundBookmarks: "No hay marcadores de {{entityType}}",
    noFoundEntityBookmarks: "No hay marcadores",
    addToBookmarks: "Añadir a marcadores",
    removeFromBookmarks: "Eliminar de marcadores",
  },
  entityTypeName: {
    account_one: "Cuenta",
    account_other: "Cuentas",
    address_one: "Dirección",
    address_other: "Direcciones",
    adjustment_one: "Ajuste",
    adjustment_other: "Ajustes",
    webApplication_one: "Aplicación",
    webApplication_other: "Aplicaciones",
    customer_one: "Cliente",
    customer_other: "Clientes",
    document_one: "Documento",
    document_other: "Documentos",
    invoice_one: "Factura",
    invoice_other: "Facturas",
    inventory_one: "Inventario",
    inventory_other: "Inventarios",
    message_one: "Mensaje",
    message_other: "Mensajes",
    note_one: "Nota",
    note_other: "Notas",
    npcase_one: "Caso de portabilidad",
    npcase_other: "Casos de portabilidad",
    payment_one: "Pago",
    payment_other: "Pagos",
    product_one: "Producto",
    product_other: "Productos",
    productOffering_one: "Oferta de producto",
    productOffering_other: "Ofertas de productos",
    subscription_one: "Suscripción",
    subscription_other: "Suscripciones",
    task_one: "Tarea",
    task_other: "Tareas",
    tasklist_one: "Lista de tareas",
    tasklist_other: "Listas de tareas",
    user_one: "Usuario",
    user_other: "Usuarios",
    nrdbClient_one: "Cliente",
    nrdbClient_other: "Clientes",
    nrdbConfig_one: "Configuración",
    nrdbConfig_other: "Configuraciones",
    nrdbMessage_one: "Mensaje",
    nrdbMessage_other: "Mensajes",
    nrdbNumber_one: "Número de teléfono",
    nrdbNumber_other: "Números de teléfono",
    order_one: "Orden",
    order_other: "Órdenes",
    webWidget_one: "Widget",
    webWidget_other: "Widgets",
    webTable_one: "Tabla",
    webTable_other: "Tablas",
    webTableOption_one: "Configuración de tablas",
    webTableOption_other: "Configuraciones de tablas",
    webTableFieldDefinition_one: "Columna de tablas",
    webTableFieldDefinition_other: "Columnas de tablas",
    webView_one: "Vista",
    webView_other: "Vistas",
    webScreen_one: "Pantalla",
    webScreen_other: "Pantallas",
    webFlow_one: "Flujo",
    webFlow_other: "Flujos",
    webStep_one: "Paso",
    webStep_other: "Pasos",
    webAppConfig_one: "Configuración de aplicaciones",
    webAppConfig_other: "Configuraciones de aplicaciones",
    webNavigationGroup_one: "Grupo de navegación",
    webNavigationGroup_other: "Grupos de navegación",
  },
  dateGroupTitle: {
    today: "Hoy",
    last_week: "Durante la última semana",
    last_month: "Durante el último mes",
    earlier: "Anterior",
  },
  sideBar: {
    show: "Mostrar",
    hide: "Ocultar",
    showTooltip: "Mostrar la barra de navegación lateral",
    hideTooltip: "Ocultar la barra de navegación lateral",
  },
  loggedOut: {
    message: "Has sido desconectado",
    gotoLogin: "Ir a la página de inicio de sesión",
  },
  editCustomerNote: {
    noteText: "Texto de la nota",
    status: "Emoji",
  },
  properties: "Propiedades",
  noAvailableData: "No hay datos disponibles",
  noValue: "<sin valor>",
  noValueSelected: "<Ninguno>",
  deleteSelected: "¿Eliminar elementos seleccionados?",
  copyFrom: "Copiar de",
  buttons: {
    accept: "Aceptar",
    cancel: "Cancelar",
    continue: "Continuar",
    clear: "Limpiar",
    close: "Cerrar",
    copy: "Copiar",
    copyFrom: "Copiar de la dirección {{addressType}}",
    create: "Crear",
    decline: "Rechazar",
    delete: "Eliminar",
    dismiss: "Cancelar solicitud",
    download: "Descargar",
    ok: "Ok",
    print: "Imprimir",
    refreshPageData: "Actualizar datos de la página",
    reset: "Restablecer",
    save: "Guardar",
    update: "Actualizar",
    search: "Buscar",
    undo: "Deshacer",
    moreDetails: "Más detalles",
    showAll: "Mostrar todo",
    showAllHiddenAfter: "resultados para {{search}} en {{entityType}}",
    showCountMore: "Mostrar {{count}} más",
    showPDF: "Mostrar PDF",
    preview: "Vista previa",
    previewHiddenAfter: "próxima factura",
    next: "Siguiente",
    back: "Atrás",
    finish: "Finalizar",
    generateGUID: "Generar GUID",
    clearGUID: "Borrar GUID",
    generatePINCode: "Generar código PIN",
    clearPINCode: "Borrar código PIN",
    toggleVisibility: "Alternar visibilidad de la contraseña",
    currentUserAccount: "Cuenta del usuario actual",
    accountMenu: "Menú de cuenta",
    applicationSwitcher: "Conmutador de aplicaciones",
    backTo: "Volver a {{entityType}} anterior",
    backToTooltip: "Volver a",
  },
  forms: {
    fieldIsMandatory: "El campo es obligatorio",
    mandatory: "{{label}} (obligatorio)",
    minLength: "El campo debe contener al menos {{count}} caracteres",
    maxLength: "El campo debe contener {{count}} caracteres o menos",
    lengthBetween:
      "El campo debe contener entre {{minLength}} y {{maxLength}} caracteres",
    lengthExactly: "El campo debe contener exactamente {{count}} caracteres",
    pattern: "El campo debe seguir el patrón {{pattern}}",
    formatShortSocialSecNo: "########-#",
    formatLongSocialSecNo: "########-#",
    date: {
      dateTimeError:
        "La fecha o la hora seleccionada no es válida (pista: {{hint}})",
      dateError: "La fecha seleccionada no es válida (pista: {{hint}})",
      timeError: "La hora seleccionada no es válida (pista: {{hint}})",
    },
    tableSelectionIsMandatory: "Se debe seleccionar una fila",
    jsonField: "Este campo debe contener una estructura JSON válida",
    translations: {
      hint: "Abrir traducciones",
      title: "Traducciones",
      contentText: "Introduzca las traducciones para {{label}}",
    },
    iconSelector: {
      searchIcons: "Busca un icono...",
      selectIcon: "Selecciona un icono",
      noIcon: "Sin icono",
      openIconSelector: "Abra el selector de iconos",
      icon: "Icono,",
    },
  },
  table: {
    rowActionMenu: {
      tooltip: "Acciones de fila para la fila actual",
      ariaLabel: "Menú de acciones",
    },
    multiRowActionMenu: {
      tooltip: "Acciones de fila para las filas seleccionadas",
      ariaLabel: "Menú de acciones de fila",
    },
    expandRow: "Expandir fila",
    defaultError: "Ocurrió un error al cargar la tabla",
  },
  cookieText:
    "Billiant Web requiere cookies estrictamente necesarias para permitir la funcionalidad central del sitio web, como el inicio de sesión de usuario y la gestión de cuentas. El sitio web no se puede usar correctamente sin cookies estrictamente necesarias.",
  snackbarMessageOccurredMultipleTimes: "(ocurrió {{times}} veces)",
  fetchingData: "Recuperando datos",
  keyboardShortcuts: "Atajos de teclado",
  settings: "Configuración",
  settingsMenu: {
    settingsMenu: "Menú de configuración",
    allowDnD: "Permitir arrastrar y soltar",
  },
  navigation: "Navegación",
  filter: {
    hideFilter: "Ocultar filtro",
    showFilter: "Mostrar filtro",
  },
  searchFilter: {
    currency: "Moneda",
    selectCurrency: "Seleccionar moneda",
    textFrom: "{{text}} (de)",
    textTo: "{{text}} (a)",
    fromValue: "De {{value}}",
    toValue: "A {{value}}",
    searchText: "Buscar texto",
    today: "Hoy",
  },
  ofTotal: "de {{count}}",
  zoomIn: "Acercar",
  zoomOut: "Alejar",
  approveOrders: "Aprobar orden(es)",
  adjustmentDate: "Fecha de ajuste",
  chargeType: "Tipo de cargo",
  generalLedgerInfo: "Información del libro mayor general",
  taxBundleInfo: "Información del paquete de impuestos",
  adjustmentDescription: "Descripción del ajuste",
  adjustmentDescriptionHelper:
    "Seleccione o ingrese una descripción que aparecerá en la factura.",
  decision: "Decisión",
  earliestInvoiceDate: "Fecha de la factura más temprana",
  enableImmediateInvoicing: "Habilitar para facturación inmediata",
  inputAmountInclVat: "Ingresar monto incluyendo {{vatRate}} IVA",
  amountExclVat: "Monto (excluyendo {{vatRate}} IVA)",
  amountVat: "Impuesto al valor agregado (IVA)",
  amountInclVat: "Monto (incluyendo {{vatRate}} IVA)",
  amountsInclTax: "Mostrar/ingresar montos incluyendo impuestos",
  effectiveDate: "Fecha de efectividad",
  overrideBinds: "Anular enlaces/período de aviso",
  reason: "Razón",
  supressFees: "Suprimir tarifas de incumplimiento",
  categoryWidget: {
    noCategoriesFound: "No se encontraron categorías",
    noCategoriesFoundHelper: "Este cliente no tiene ninguna categoría",
    missingScreenEntity: "Entidad de pantalla {{entityType}} faltante",
    missingScreenEntityHelper:
      "El widget no pudo encontrar ninguna entidad {{entityType}} dentro de las entidades de pantalla",
  },
  historyWidget: {
    message: "Mensaje",
    oldValue: "Valor anterior",
    newValue: "Nuevo valor",
    orderId: "ID de orden",
    dateLastPost: "Fecha del último cambio {{date}}",
  },
  dayOfMonth: "día del mes",
  days: "Días",
  amount: "Monto",
  amountWithCurrency: "Monto ({{currencyCode}})",
  current: "Actual",
  totalAmount: "Total {{amount}}",
  createdBy: "Creado por: {{createdBy}}",
  scoreWidget: {
    score: "Puntuación: {{score}}",
    noScore: "Sin puntuación",
  },
  reminderXSent: "Recordatorio {{number}} enviado",
  titleIsMissing: "Falta el título",
  noChildWidget:
    "No se encontró ningún widget coincidente. Tipo de entidad: {{EntityType}}, ID del tipo de entidad: {{number}}",
  descriptionIsMissing: "Falta la descripción",
  noResultsFound: "No se encontraron resultados",
  alerts_one: "{{count}} alerta",
  alerts_other: "{{count}} alertas",
  unknownEntity: "Entidad desconocida",
  breadcrumb: "Ruta de navegación",
  selectAll: "Seleccionar todo",
  items_one: "{{count}} elemento",
  items_other: "{{count}} elementos",
  appNameLogo: "logotipo de {{appName}}",
  footer:
    "Xplora es vendido y distribuido por: Xplora Technologies S.L. Ubicación: EDIFICIO UTOPICUS ORENSE, CALLE ORENSE, NUM. 62, PLANTA 1, PUERTA 15, 28020 Madrid, España\nOrg. n.º 814 499 022",
  yes: "Sí",
  no: "No",
  choose: "Elegir",
  identifier: "Identificador",
  score: "Puntuación",
  check: "Verificar",
  creditScore: "Puntuación de crédito",
  creditStatus: "Estado de crédito",
  creditRatingStatus: {
    notChecked: "No verificado",
    creditOk: "Ok",
    creditWarning: "Advertencia",
    creditNotOk: "No está bien",
  },
  noTaskEntity: "No hay ninguna entidad asociada a la tarea",
  productOfferingOptions: {
    availableRenewalOptions:
      "Hay {{optionsLength}} opciones de renovación disponibles.",
    noRenewalOptions:
      "No existen opciones de renovación. El producto no está activo, o tiene enlaces.",
    payWithCurrent: "Pagar con el {{current}} actual",
    payWithMethod: "Pagar con {{method}}",
    readAndApprove: "He leído y apruebo los",
    termsAndConditions: "términos y condiciones.",
    payAndRenew: "Renovar y pagar {{amountToPay}}",
  },
  description: "Descripción",
  applicablePrices: "Precios aplicables",
  orderList: {
    configError: {
      name: "Error de configuración web",
      description:
        "OrderListWidget requiere una TableConfig que contenga una sola TableOption para el tipo de entidad ORDEN",
    },
  },
  orderCard: {
    completionDate: "Completado: ",
    orderDate: "Ordenado: ",
  },
  subject: "Asunto",
  messageText: "Texto del mensaje",
  productLifeCycle: {
    periods: {
      bind: {
        label: "Período de compromiso",
        description:
          "El producto no puede cancelarse antes de la fecha de cancelación más temprana, a menos que se apliquen penalizaciones.",
        before: "ANTES del periodo de compromiso",
        within: "DURANTE el período de compromiso",
        after: "DESPUÉS del período de compromiso",
      },
      regret: {
        label: "Periodo de arrepentimiento",
        description:
          "El producto puede cancelarse con efecto inmediato sin penalización alguna.",
        within: "DURANTE el periodo de arrepentimiento",
      },
      notice: {
        label: "Plazo de preaviso",
        description: {
          beforeLatestNoticeDate:
            "El producto puede cancelarse al final del periodo de compromiso, siempre que la cancelación se solicite ANTES de la última fecha de preaviso.",
          withAdjustment:
            "El producto puede cancelarse en la fecha de cancelación más temprana, siempre que la cancelación se solicite ANTES de la última fecha de preaviso.",
          withoutAdjustment:
            "El producto puede cancelarse en la fecha de cancelación más temprana, siempre que la cancelación se solicite HOY.",
        },
        within:
          "DESPUÉS de la última fecha de preaviso del período de compromiso",
      },
    },
    marks: {
      bindStart: "Inicio del período de compromiso",
      bindEnd: "Fin del periodo de compromiso",
      latestRegret: "Última fecha de arrepentimiento",
      latestNotice: "Última fecha de preaviso",
      noticeDateWithinBind:
        "Última fecha de preaviso del período de compromiso",
      earliestTermination: "Fecha de cancelación más temprana",
    },
    currentDateIs: "La fecha actual es ",
    today: "HOY",
  },
  noItemSelected: "No se ha seleccionado ningún elemento",
  noActionMatchingSelection: "Ninguna acción coincide con la selección actual",
} as const;
