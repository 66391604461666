/**
 * Enum defining the Product Lifecycle Types used in the Product Lifecycle Events.
 *
 * Corresponds to the java class com.billiant.billiant.util.code.ProductLifecycleType
 * which is a BilliantCodeObject
 */
export enum ProductLifecycleType {
  COMMITMENT_START = "COMMITMENT_START",
  COMMITMENT_END = "COMMITMENT_END",
  REGRET = "REGRET",
  NOTICE = "NOTICE",
}
