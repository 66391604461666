import { FormCheckBox, FormSelect } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { TransferProductFlowData, TransferProductFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { FlowStepComponentProps } from "../models";
import {
  StyledStepContentWrapper,
  StyledStepIndentContentWrapper,
} from "../steps.styles";

export type TransferProductProps = FlowStepComponentProps<
  TransferProductFlowStep,
  TransferProductFlowData
>;

/** transfer product step component */
export function TransferProduct({
  control,
  data,
  flowStep,
}: TransferProductProps): ReactElement {
  const { t } = useTranslation(["action"]);
  const context = useFormContext();
  const {
    copyProduct,
    copyProductChangeAllowed,
    transferBinds,
    deactivateStatusReasonId,
    activateStatusReasonId,
    applicableActivateReasons,
    applicableDeactivateReasons,
  } = data;

  // Watch the copyProduct so it is possible to react on changes.
  const copyProductToggle: boolean = context?.watch(
    addFlowStepPrefix(flowStep, "copyProduct"),
    data.copyProduct
  );

  return (
    <StyledStepContentWrapper>
      <FormCheckBox
        control={control}
        fieldName={addFlowStepPrefix(flowStep, "copyProduct")}
        label={t("transferProductStep.copyProduct")}
        defaultValue={copyProduct}
        disabled={!copyProductChangeAllowed}
      />
      <StyledStepIndentContentWrapper>
        <FormSelect
          control={control}
          label={t("transferProductStep.deactivateStatusReasonId")}
          fieldName={addFlowStepPrefix(flowStep, "deactivateStatusReasonId")}
          required
          defaultValue={deactivateStatusReasonId}
          validValues={applicableDeactivateReasons}
          disabled={!copyProductToggle}
        />
        <FormSelect
          control={control}
          label={t("transferProductStep.activateStatusReasonId")}
          fieldName={addFlowStepPrefix(flowStep, "activateStatusReasonId")}
          required
          defaultValue={activateStatusReasonId}
          validValues={applicableActivateReasons}
          disabled={!copyProductToggle}
        />
        <FormCheckBox
          control={control}
          fieldName={addFlowStepPrefix(flowStep, "transferBinds")}
          label={t("transferProductStep.transferBinds")}
          defaultValue={transferBinds}
          disabled={!copyProductToggle}
        />
      </StyledStepIndentContentWrapper>
    </StyledStepContentWrapper>
  );
}
