import { getCurrentAppConfig, useAuthenticationContext } from "core/auth";
import {
  getCurrentCustomerGroupId,
  getLocalStoreAuthorization,
} from "core/auth/util";
import { Cache } from "core/idb";
import { Messages } from "core/message";
import { getI18n } from "i18n";
import { APIError, DownloadFileResponse, RequestResponse } from ".";
import { createApiUrl, shouldIgnoreError } from "./helpers";
import { IgnoreError, RequestInitWithParams } from "./interfaces";

export async function request(
  input: RequestInfo,
  init?: RequestInitWithParams | undefined,
  ignoreError?: IgnoreError
) {
  const response = await fetch(input, init);

  /** If error should be hidden for the user */
  const errorIgnored = shouldIgnoreError(response.status, ignoreError);

  if (response.ok) {
    return response;
  } else if (response.status !== 500) {
    const errorData: APIError =
      response.headers.get("content-type") === "application/json"
        ? await response.json()
        : {
            status: response.status,
            errorCode: String(response.status),
            userMessage: await response.text(),
            developerMessage: response.statusText,
          };

    if (!errorIgnored) {
      Messages().addMessage({
        text:
          errorData?.userMessage ||
          errorData?.developerMessage ||
          String(response.status),
        type: "error",
        noTimeout: true,
      });
    }
    throw errorData;
  } else {
    let body: string;
    let bodyJSON: {
      status: number;
      errorCode: string;
      userMessage: string;
      developerMessage: string;
    };

    try {
      body = await response.text();
      bodyJSON = JSON.parse(body);
    } catch (e) {
      bodyJSON = {
        status: response.status,
        errorCode: String(response.status),
        userMessage: "An Error Occurred",
        developerMessage: response.statusText,
      };
      body = "No Details Available";
    }

    if (!errorIgnored) {
      Messages().openErrorDialog({ details: body });
    }
    const errorData = {
      status: bodyJSON.status,
      errorCode: String(bodyJSON.status),
      userMessage: bodyJSON.userMessage,
      developerMessage: bodyJSON.developerMessage ?? response.statusText,
    };
    throw errorData;
  }
}

export function useAPIRequest(noAuthentication = false) {
  const {
    state: { isAuthenticated, currentCustomerGroup },
  } = noAuthentication
    ? { state: { isAuthenticated: false, currentCustomerGroup: undefined } }
    : useAuthenticationContext();

  const requestAPI = getAPIRequestFunction(
    isAuthenticated,
    currentCustomerGroup
  );

  const downloadAPIFile = getAPIDownloadFunction(
    isAuthenticated,
    currentCustomerGroup
  );

  return { requestAPI, downloadAPIFile };
}

export interface RequestOptions<
  TIncludeHeaders extends boolean | undefined = false | undefined
> {
  /** Don't inform the user about the error, but throw as normal */
  ignoreError?: IgnoreError;
  /** If cache in IndexedDB should be ignored */
  noCache?: boolean;
  /** Include headers in returned data */
  includeHeaders?: TIncludeHeaders;
}

export function getAPIRequestFunction(
  isAuthenticated: boolean,
  currentCustomerGroup?: number
) {
  return async function requestFn<
    TData = any,
    TIncludeHeaders extends boolean | undefined = false | undefined
  >(
    relativeURL: string,
    init?: RequestInitWithParams | undefined,
    options?: RequestOptions<TIncludeHeaders>
  ): Promise<RequestResponse<TData, TIncludeHeaders>> {
    const url = createApiUrl(relativeURL, init?.pathParams, init?.queryParams);
    const method = init?.method ?? "GET";

    if (!options?.noCache) {
      const cache =
        method === "GET" && (await Cache.getResponseFromCache(method, url));
      if (cache) {
        return cache
          ?.json()
          .then((value) =>
            init?.transformer ? init.transformer(value) : value
          );
      }
    }

    const headers: HeadersInit = getRequestHeaders(
      isAuthenticated,
      currentCustomerGroup
    );

    const response = await request(
      url,
      {
        ...init,
        headers: {
          ...headers,
          ...init?.headers,
        },
      },
      options?.ignoreError
    );

    let body;

    /**
     * In most cases we expect json data, which is why 'json'
     * is the default. If the response format doesn't match
     * the expected type an error should be thrown.
     */
    const expectedType = init?.expectedType ?? "json";

    switch (expectedType) {
      case "ArrayBuffer":
        body = await response.arrayBuffer();
        break;
      case "Text":
        body = await response.text();
        break;
      case "FormData":
        body = await response.formData();
        break;
      case "Blob":
        body = await response.blob();
        break;
      case "RequestOk":
        body = await response.ok;
        break;
      case "json":
      default:
        body = await response.json();
    }
    if (expectedType !== "Blob") {
      Cache.setResponseInCache(method, response, body);
    }
    body = init?.transformer ? init.transformer(body) : body;
    if (options?.includeHeaders) {
      return {
        headers: response.headers,
        body,
      } as RequestResponse<TData, TIncludeHeaders>;
    } else {
      return body;
    }
  };
}

function getAPIDownloadFunction(
  isAthenticated: boolean,
  currentCustomerGroup?: number
) {
  const requestFn = getAPIRequestFunction(isAthenticated, currentCustomerGroup);

  function getAttachmentFileName(headers: Headers) {
    const constentDisposition = headers.get("Content-Disposition");
    return constentDisposition?.includes("attachment")
      ? new RegExp(/filename=(\S+)/).exec(constentDisposition)?.[1]
      : undefined;
  }

  return async function downloadFile(
    relativeURL: string,
    init?: RequestInitWithParams | undefined,
    ignoreError?: IgnoreError
  ): Promise<DownloadFileResponse> {
    const response = await requestFn(
      relativeURL,
      { ...init, expectedType: "Blob" },
      { ignoreError, includeHeaders: true }
    );
    const attachmentFileName = getAttachmentFileName(response.headers);
    return { attachmentFileName, blob: response.body };
  };
}

function getRequestHeaders(
  isAuthenticated: boolean,
  currentCustomerGroup?: number
): HeadersInit {
  const clientsTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const headers: HeadersInit = {
    "Accept-Language": getI18n().language,
    "Content-Type": "application/json",
    "bss-customer-group": `${
      getCurrentCustomerGroupId() ?? process.env.REACT_APP_CUSTOMER_GROUP_ID
    }`,
    "bss-current-app-config": `${getCurrentAppConfig() || -1}`,
    "bss-clients-timezone": `${clientsTimeZone}`,
  };
  if (isAuthenticated) {
    headers.Authorization = `Bearer ${getLocalStoreAuthorization()}`;
    if (currentCustomerGroup) {
      headers["bss-customer-group"] = String(currentCustomerGroup);
    }
  }
  return headers;
}
