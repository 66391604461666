import {
  ContentCopy,
  OpenInNew,
  Settings,
  getFontIcon,
} from "common/assets/icons";
import {
  Box,
  Button,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "common/components";
import { OrderedActionOptions } from "core/actions";
import { ActionParams } from "core/actions/flows";
import { ActionConfig, EntityType, useFetchWebSetupActions } from "core/api";
import { StorageKey } from "core/appSettings";
import { OrderedActionButton } from "core/components";
import { Messages } from "core/message";
import { MouseEvent, ReactElement, useState } from "react";
import { getCurrentCustomerGroupId } from "../../auth";

export interface WebSetupActionsMenuProps {
  /** Type of entity */
  entityType: EntityType;
  /** Id of entity */
  entityId: number | undefined;
  /** Is the entity inside of a menu? Only applicable for entity type WEB_ACTION */
  inMenu?: boolean;
  /** Web setup application path for specified Entity */
  setUpPath?: string;
  /** Provides the ability to trigger an action */
  triggerAction: (
    config: ActionConfig<any>,
    params?: ActionParams | undefined
  ) => void;
}
/**
 * Generates and renders a dropdown menu button containing
 * applicable actions.
 *
 * Any future configMode actions applicable to entities should be
 * added to this component.
 *
 * @returns ReactElement | null
 */
export function WebSetupActionsMenu({
  entityId,
  entityType,
  inMenu = false,
  setUpPath = "",
  triggerAction,
}: WebSetupActionsMenuProps): ReactElement | null {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  /*
   * For some reason if we import and use the standard createOrderedAction function here
   *  we will get a type error from webpack when app is loaded  -> fatal crash.
   */
  function createOrderedActionButton(
    { icon, config, color, variant, size, ...actionProps }: ActionConfig,
    { disabled, hideDisabledActions, onClick }: OrderedActionOptions
  ): OrderedActionButton {
    return {
      ...actionProps,
      color: color ?? "primary",
      variant: variant ?? "outlined",
      size: size ?? "small",
      Icon: icon ? getFontIcon(icon) : undefined,
      href:
        config && Object.keys(config).includes("href")
          ? (config as { href: string }).href
          : undefined,
      disabled: actionProps.disabled || disabled,
      visible:
        (actionProps.disabled || disabled) && hideDisabledActions
          ? false
          : actionProps.visible,
      onClick,
    };
  }

  /** Determines menuButton appearance and behavior based on entity type */
  const menuButton =
    entityType == EntityType.WEB_ACTION ? (
      inMenu ? (
        <MenuItem
          id={"configMenuButton"}
          onClick={openMenu}
          data-cy={"configMenuButton"}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText>{entityId}</ListItemText>
        </MenuItem>
      ) : (
        <Button
          id={"configMenuButton"}
          onClick={openMenu}
          data-cy={"configMenuButton"}
          size="small"
          startIcon={<Settings />}
        >
          {entityId}
        </Button>
      )
    ) : (
      <IconButton
        id={"configMenuButton"}
        onClick={openMenu}
        data-cy={"configMenuButton"}
        size="small"
      >
        <Settings fontSize="inherit" sx={{ margin: "-2px" }} />
      </IconButton>
    );

  const { data: actionsList } = useFetchWebSetupActions(entityType);

  const customerGroupId = getCurrentCustomerGroupId();

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const inFlow = () => {
    const key = sessionStorage.getItem(StorageKey.ACTION_DIALOG);
    return key && key.indexOf('state":{') != -1 ? true : false;
  };

  /**
   * Add entity types here that should not support in place editing.
   * Reason for existing types are either that the entity appears where a flow can't be triggered (Application & App config)
   * or that the required function triggerAction can't be passed down to the WebSetupActionsMenu where the entity Appears. (Table and related)
   *
   * @returns Boolean
   */
  const isInPlaceEditEnabled = () => {
    switch (entityType) {
      case EntityType.WEB_APPLICATION:
      case EntityType.WEB_APP_CONFIG:
      case EntityType.WEB_TABLE:
      case EntityType.WEB_TABLE_OPTION:
      case EntityType.WEB_TABLE_FIELD_DEFINITION:
        return false;
      default:
        return true;
    }
  };

  const isInHomeScreen = () => {
    const key = sessionStorage.getItem(
      StorageKey.CURRENT_APPLICATION_CONFIG_ID
    );
    return key && key.indexOf('state":-1') != -1 ? true : false;
  };

  const onClick = (action: ActionConfig<any>) => {
    triggerAction(action, {
      entityType,
      entityId: String(entityId),
    });
  };

  const isActionsEnabled = () => {
    return isInPlaceEditEnabled() && !inFlow() && !isInHomeScreen();
  };

  const setupActions =
    isActionsEnabled() &&
    actionsList &&
    actionsList.map((action) => {
      return createOrderedActionButton(
        { ...action },
        {
          entity: [],
          onClick() {
            onClick(action);
          },
        }
      );
    });

  /** Determines onClick functionality of copyButton */
  const handleCopy = () => {
    navigator.clipboard.writeText("" + entityId);
    Messages().addMessage({
      text: "Id (" + entityId + ") copied to clipboard",
      type: "info",
    });
  };

  const defaultButtons = [
    <MenuItem
      key={1}
      data-cy={"copyButton"}
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        closeMenu();
        handleCopy();
      }}
    >
      <ListItemIcon>
        <ContentCopy />
      </ListItemIcon>
      {"Copy ID"}
    </MenuItem>,
    <MenuItem
      key={2}
      data-cy={"redirectButton"}
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        closeMenu();
        openInNewTab(
          `${window.location.origin}/${customerGroupId}${setUpPath}`
        );
      }}
    >
      <ListItemIcon>
        <OpenInNew />
      </ListItemIcon>
      {"Go to Details"}
    </MenuItem>,
  ];

  return (
    <>
      {menuButton}
      <Menu
        id="configMenu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={menuOpen}
        onClose={closeMenu}
      >
        {defaultButtons}

        {setupActions &&
          setupActions.map(({ label, Icon, href, onClick }, index) => (
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              key={label + "_" + index}
            >
              <MenuItem
                data-cy={"actionButton" + label.replace(/[\s\\/]/g, "")}
                LinkComponent={href ? Link : undefined}
                {...(href && { to: href })}
                onClick={() => {
                  if (onClick) {
                    onClick();
                  }
                  closeMenu();
                }}
              >
                {/* Only include padding if any action in menu has an icon */}
                {setupActions.find((action) => action.Icon) && (
                  <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
                )}
                <ListItemText primary={label} />
              </MenuItem>
            </Box>
          ))}
      </Menu>
    </>
  );
}
