import { FontIcon } from "common/assets/icons";
import { Alert, AlertTitle, Box, Typography } from "common/components";
import { StepMessages } from "core/actions/flows/ActionDialog/components";
import { StepMessage } from "core/api";

export interface StepDetailsProps {
  /** Details title */
  title: string;
  /** Details icon */
  icon: string;
  /** Details hint */
  hint: string;
  /** Details description */
  description: string;
  /** Optional messages from server */
  messages?: StepMessage[];
  /** API error message */
  apiError?: string;
  /** Show or hide the initial info box */
  showStepTitle: boolean | undefined;
}

/** Action dialog step details */
export function StepDetails({
  title,
  description,
  icon,
  messages = [],
  apiError,
  showStepTitle,
}: StepDetailsProps) {
  return (
    <Box display="grid" gap={0.5}>
      {(showStepTitle || showStepTitle === undefined) && (
        <Alert
          sx={{
            bgcolor: (theme) => theme.palette.primary.light,
            color: (theme) => theme.typography.caption,
          }}
          icon={
            <FontIcon
              icon={icon}
              sx={{ color: (theme) => theme.palette.primary.main }}
            />
          }
        >
          <AlertTitle data-cy="stepTitle">{title}</AlertTitle>
          <Typography variant="caption" whiteSpace={"pre-wrap"}>
            {description.replaceAll("\\n", String.fromCharCode(13, 10))}
          </Typography>
        </Alert>
      )}
      <StepMessages messages={messages} apiError={apiError} />
    </Box>
  );
}
