import { ValidValue } from "core/api";
import { useTranslation } from "i18n";
import { AvailableLanguage } from "i18n/models";

/**
 * @returns Available languages
 */
export function useAvailableLanguages(): ValidValue[] {
  const { t } = useTranslation("languages");

  return [
    {
      id: AvailableLanguage.de,
      name: t("de"),
      description: "",
    },
    {
      id: AvailableLanguage.en,
      name: t("en"),
      description: "",
    },
    {
      id: AvailableLanguage.es,
      name: t("es"),
      description: "",
    },
    {
      id: AvailableLanguage.gb,
      name: t("gb"),
      description: "",
    },
    {
      id: AvailableLanguage.sv,
      name: t("sv"),
      description: "",
    },
    {
      id: AvailableLanguage.fr,
      name: t("fr"),
      description: "",
    },
  ];
}
