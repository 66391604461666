import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  OptionsCard,
} from "common/components";
import { ProductOfferingOption } from "core/api";
import { NoListData } from "core/components";
import { useTranslation } from "i18n";
import { forwardRef, ReactElement } from "react";
import { theme } from "styles/theme";

interface OptionsCardFieldProps {
  /** List with product offering options */
  options: ProductOfferingOption[];
  /** The selected index. */
  selectedIndex: number;
  /** Function to set the selected index. */
  setSelectedIndex: (index: number) => void;
  /** If an error according to the validation. Will display error text if true. */
  error?: boolean;
}

/**
 * Field with FormControl that displays OptionsCards.
 *
 */
export const OptionsCardField = forwardRef<
  HTMLDivElement,
  OptionsCardFieldProps
>(
  (
    {
      options,
      selectedIndex,
      setSelectedIndex,
      ...props
    }: OptionsCardFieldProps,
    ref
  ): ReactElement => {
    const { t } = useTranslation(["product"]);

    // disables the next button if there are no options
    if (options.length === 0) {
      setSelectedIndex(-1);
    }

    return (
      <>
        <FormControl {...props} ref={ref}>
          {!Array.isArray(options) || options.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: theme.spacing(1),
              }}
            >
              <NoListData variant="data" />
            </Box>
          ) : (
            <Grid container spacing={1}>
              {options?.map((option, index) => {
                return (
                  <Grid item key={index}>
                    <OptionsCard
                      option={option}
                      index={index}
                      selected={selectedIndex === index}
                      setSelectedIndex={setSelectedIndex}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </FormControl>
        {props.error && options.length > 0 && (
          <FormHelperText error={props.error}>
            {t("product:productOfferingSelectionIsMandatory")}
          </FormHelperText>
        )}
      </>
    );
  }
);
