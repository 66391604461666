import { MoreVert } from "common/assets/icons";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "common/components";
import { Link } from "common/routing";
import { EntityType } from "core/api";
import { WebSetupToolbar } from "core/components";
import { MouseEvent, ReactElement, useState } from "react";
import { ActionButton } from ".";
export interface ActionsMenuProps {
  actions: ActionButton[];
  triggerAction?: any;
}

export function ActionsMenu({
  actions,
  triggerAction,
}: ActionsMenuProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        data-cy={"collapsedActionsMenu"}
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        data-cy="hiddenActionsMenu"
      >
        <Box display="flex" flexDirection="row" alignItems="left">
          <Box display="flex" flexDirection="column">
            {actions.map(({ label, Icon, href, onClick, disabled }, index) => (
              <MenuItem
                key={label + "_" + index}
                data-cy={"actionButton" + label.replace(/[\s\\/]/g, "")}
                LinkComponent={href ? Link : undefined}
                {...(href && { to: href })}
                onClick={() => {
                  if (onClick) {
                    onClick();
                  }
                  handleClose();
                }}
                disabled={disabled}
              >
                {/* Only include padding if any action in menu has an icon */}
                {actions.find((action) => action.Icon) && (
                  <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
                )}
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Box>
          <Box display="flex" flexDirection="column">
            {actions.map(({ actionId }, index) => (
              <WebSetupToolbar
                key={index}
                entityId={actionId}
                entityType={EntityType.WEB_ACTION}
                inMenu={true}
                triggerAction={triggerAction}
              />
            ))}
          </Box>
        </Box>
      </Menu>
    </>
  );
}
