import {
  Dialog,
  DialogProps,
  FormControllerProvider,
  FormControllerProviderProps,
} from "common/components";
import { FlowDialogSize } from "core/api";
import { LoggingErrorBoundary } from "error/components";

export interface FlowActionDialogProps
  extends Omit<DialogProps, "open" | "onClose"> {
  /** If dialog should be open */
  open?: boolean;
  /** Flow dialog size */
  size?: FlowDialogSize;
  onClose(): void;
  evaluateOnChange: FormControllerProviderProps["evaluateOnChange"];
}

const dialogHeightMap: Record<FlowDialogSize, number | string> = {
  FULLSCREEN: "100vh",
  NORMAL: 500,
  LARGE: 800,
};

const dialogWidthMap: Record<FlowDialogSize, DialogProps["maxWidth"]> = {
  FULLSCREEN: undefined,
  NORMAL: "md",
  LARGE: "lg",
};

/** Flow action dialog */
export function FlowActionDialog({
  size = FlowDialogSize.NORMAL,
  open = true,
  onClose,
  evaluateOnChange,
  ...props
}: FlowActionDialogProps) {
  const _onClose: DialogProps["onClose"] = (_, reason) => {
    if (reason === "escapeKeyDown") {
      onClose();
    }
  };
  return (
    <FormControllerProvider evaluateOnChange={evaluateOnChange}>
      <LoggingErrorBoundary onError={() => onClose()}>
        <Dialog
          {...props}
          open={open}
          onClose={_onClose}
          fullScreen={size === FlowDialogSize.FULLSCREEN}
          fullWidth={size !== FlowDialogSize.FULLSCREEN}
          maxWidth={dialogWidthMap[size]}
          PaperProps={{ sx: { height: dialogHeightMap[size] } }}
        />
      </LoggingErrorBoundary>
    </FormControllerProvider>
  );
}
