import { MoreVert } from "common/assets/icons";
import {
  Box,
  Checkbox,
  IconButton,
  SxProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from "common/components";
import { EntityType } from "core/api";
import {
  MenuState,
  TableAction,
  TableHeaderDefinition,
  TableSelectionVariant,
  WebSetupToolbar,
} from "core/components";
import { useTranslation } from "i18n";

/**
 * Properties for the DBTableHead component
 */
export interface TableHeaderProps<T> {
  /**
   * State variable for the amount of table items
   * that have been selected by the user
   */
  numSelected: number;
  /**
   * State variable for whether table items should be displayed
   * in ascending or descending order
   */
  order: "asc" | "desc";
  /**
   * State variable denoting which column that table items
   * should be ordered by
   */
  orderBy: string;
  /**
   * Function handling behavior for when the checkbox for
   * selecting all table items is clicked by the user
   */
  onSelectAllClick(checked: boolean): void;
  /**
   * Function to update order + orderBy on clicking header
   */
  onRequestSort(
    event: React.MouseEvent<HTMLButtonElement>,
    property: string
  ): void;
  /**
   * State variable storing total number of items in table
   */
  rowCount: number;
  /**
   * List of headers for table
   */
  columns: TableHeaderDefinition<T>[];
  /**
   * Function to update the state of the tables action menu
   */
  setMenuState: React.Dispatch<React.SetStateAction<MenuState<T>>>;
  /**
   * List of Table actions, used to determine whether the 'Open menu' button should be displayed
   * Filtered to only display the enabled actions if hideDisabledActions is true.
   */
  tableActions: TableAction<T>[];
  /** If table has actions, both enabled and disabled */
  hasActions: boolean;
  /** Boolean for hiding table row checkbox, default is true */
  enableSelection?: boolean;
  /** Table selection variant */
  selectionVariant?: TableSelectionVariant;
  /** If rows have collapsed content */
  collapsibleRows?: boolean;
  /** If disabled actions should be hidden */
  hideDisabledActions?: boolean;
}

export function TableHeader<T>({
  numSelected,
  order,
  orderBy,
  onSelectAllClick,
  onRequestSort,
  rowCount,
  columns,
  setMenuState,
  tableActions,
  hasActions,
  enableSelection = true,
  selectionVariant = "MULTI",
  collapsibleRows = false,
  hideDisabledActions,
}: TableHeaderProps<T>): JSX.Element {
  const { t } = useTranslation(["common"]);

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      onRequestSort(event, property);
    };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState({
      menuAnchor: event.currentTarget,
      selectedElement: undefined,
    });
  };

  const cellStyle: SxProps<Theme> = {
    borderColor: (theme) => theme.palette.grey[400],
  };

  return (
    <TableHead>
      <TableRow>
        {collapsibleRows && <TableCell padding="checkbox" sx={cellStyle} />}
        {enableSelection && (
          <TableCell padding="checkbox" sx={cellStyle}>
            {selectionVariant === "MULTI" && (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={(_, checked) => onSelectAllClick(checked)}
                inputProps={{
                  "aria-label": t("common:table.multiRowActionMenu.tooltip"),
                }}
                data-cy="selectItemAll"
              />
            )}
          </TableCell>
        )}
        {columns.map((headCell: TableHeaderDefinition<T>) => (
          <TableCell
            key={headCell.sortKey}
            align={headCell.alignment}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.sortKey ? order : false}
            variant="head"
            sx={{
              ...cellStyle,
              width: headCell.width,
              minWidth: headCell.minWidth,
              maxWidth: headCell.maxWidth,
            }}
          >
            {headCell.sortEnabled ? (
              <TableSortLabel
                active={orderBy === headCell.sortKey}
                direction={orderBy === headCell.sortKey ? order : "asc"}
                onClick={createSortHandler(headCell.sortKey)}
                data-cy={"sort_" + headCell.sortKey}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            <WebSetupToolbar
              entityType={EntityType.WEB_TABLE_FIELD_DEFINITION}
              entityId={headCell.webTableFieldDefinitionId}
            />
          </TableCell>
        ))}
        {tableActions.length > 0 || hideDisabledActions ? (
          <TableCell align="left" sx={cellStyle}>
            <Tooltip title={t("common:table.multiRowActionMenu.tooltip")}>
              <IconButton
                size="small"
                arial-label={t("common:table.multiRowActionMenu.ariaLabel")}
                onClick={openMenu}
                data-cy="headerRowActionsMenu"
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : (
          hasActions && (
            <TableCell align="left" sx={{ ...cellStyle, height: "47px" }}>
              <Box style={{ width: "34px" }}></Box>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}
