/**
 * Enum defining the Product Catalog Period Types used in the Contract Terms.
 *
 * Corresponds to the java class com.billiant.billiant.util.code.ProductCatalogPeriodType
 * which is a BilliantCodeObject
 */
export enum ProductCatalogPeriodType {
  CONTRACT_TERM_PERIOD = "CONTRACT_TERM_PERIOD",
  REGRET_PERIOD = "REGRET_PERIOD",
  PROMOTION_PERIOD = "PROMOTION_PERIOD",
  TERMINATION_NOTIFICATION_PERIOD = "TERMINATION_NOTIFICATION_PERIOD",
}
