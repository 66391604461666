import { UpgradeDowngradeFlowData, UpgradeDowngradeFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Upgrade downgrade data resolver
 *
 * Formats Upgrade downgrade data on form field values
 */
export const upgradeDowngradeResolver: StepDataResolver<
  UpgradeDowngradeFlowStep,
  UpgradeDowngradeFlowData
> = ({ data, fieldValues }) => {
  return {
    intSelectedIndex:
      fieldValues.selectedProductOffering ?? data.intSelectedIndex,
  };
};
