import { FormProductOfferingOptions } from "common/components";
import { ReactElement, useState } from "react";

import { UpgradeDowngradeFlowData, UpgradeDowngradeFlowStep } from "core/api";

import { addFlowStepPrefix } from "common/utils";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type UpgradeDowngradeProps = FlowStepComponentProps<
  UpgradeDowngradeFlowStep,
  UpgradeDowngradeFlowData
>;

/**
 * Upgrade Downgrade step component
 *
 * Displays a list of product offering card
 */
export function UpgradeDowngrade({
  control,
  data,
  flowStep,
}: UpgradeDowngradeProps): ReactElement {
  const { intSelectedIndex, productOfferingOptions } = data;
  const [indexValue, setIndexValue] = useState(intSelectedIndex);

  /**
   * Updates the index value.
   *
   * @param newIndex - The new index to set.
   */
  const handleIndexChange = (newIndex: number) => {
    setIndexValue(newIndex);
  };

  return (
    <StyledStepContentWrapper>
      <FormProductOfferingOptions
        control={control}
        fieldName={addFlowStepPrefix(flowStep, "selectedProductOffering")}
        options={productOfferingOptions}
        defaultValue={
          productOfferingOptions ? indexValue.toString() : undefined
        }
        selectedIndex={indexValue}
        setSelectedIndex={handleIndexChange}
        required
        withSearch
      />
    </StyledStepContentWrapper>
  );
}
