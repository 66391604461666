import { StatusIcon } from "common/assets";
import { Box, ListItem, ListItemIcon, ListItemText } from "common/components";
import { FlowData, FlowStep, IconVariant } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface EditCustomerNoteConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * EditCustomerCustomerNoteConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "EditCustomerCustomerNote".
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 * @param getValues A helper function from react-hook-form. The function is passed
 * into the parent Confirmation component and in turn passed here.
 *
 * @returns A JSX element with invoice details from the previous "EditCustomerCustomerNote" step in the flow.
 */
export function EditCustomerNoteConfirmation({
  step,
  flowData,
}: EditCustomerNoteConfirmationProps): JSX.Element {
  const { t } = useTranslation(["common"]);
  const fieldFlowData =
    flowData.uiComponent === "EditCustomerNote" ? flowData : undefined;

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("common:editCustomerNote.noteText")}
          secondary={fieldFlowData?.noteText}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          data-cy="friendlyStatus"
          primary={t("common:editCustomerNote:status")}
          secondary={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                {fieldFlowData && (
                  <StatusIcon
                    iconName={fieldFlowData.status.icon?.name || ""}
                    iconVariant={
                      fieldFlowData.status.icon?.variant || IconVariant.SUCCESS
                    }
                  />
                )}
              </ListItemIcon>
              {fieldFlowData?.status.name}
            </Box>
          }
        />
      </ListItem>
    </Fragment>
  );
}
