import { Box, Divider, FormSelect } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { StepMessages } from "core/actions/flows/ActionDialog/components";
import {
  FlowStepComponentProps,
  StyledStepContentWrapper,
  getStepComponent,
} from "core/actions/flows/steps";
import { EntityType, SelectStepFlowData, SelectStepFlowStep } from "core/api";
import { WebSetupToolbar } from "core/components";
import { useTranslation } from "i18n";
import { useEffect } from "react";
import { useController } from "react-hook-form";

export type SelectStepProps = FlowStepComponentProps<
  SelectStepFlowStep,
  SelectStepFlowData
>;

/** SelectStep step component */
export function SelectStep({
  control,
  flow,
  data,
  flowStep,
  setValue,
  setCurrentSubStepId,
  ...props
}: SelectStepProps) {
  const selectedStepIdKey = addFlowStepPrefix(flowStep, "selectedStepId");
  const { field } = useController({
    control,
    name: selectedStepIdKey,
    defaultValue: data.selectedStepId,
  });

  const { t } = useTranslation(["common"]);
  const {
    steps = [],
    addEmptySelection,
    sortInAlphabeticalOrder,
  } = flowStep.config;

  const selectedStep = steps.find((step) => field.value === step.id);

  const StepComponent = getStepComponent(selectedStep?.uiComponent);

  useEffect(() => {
    setCurrentSubStepId?.(field.value);
  }, [field.value, setCurrentSubStepId]);

  const dataList = addEmptySelection
    ? [
        { name: t("common:noValueSelected"), id: "-1", description: "" },
        ...steps.map(({ name, id }) => ({ name, id, description: "" })),
      ]
    : steps.map(({ name, id }) => ({ name, id, description: "" }));

  if (sortInAlphabeticalOrder) {
    dataList.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <StyledStepContentWrapper
      dialogSize={flow.config.dialogSize}
      sx={{ padding: 0 }}
    >
      <WebSetupToolbar
        entityType={EntityType.FLOW_STEP_DEFINITION}
        entityId={selectedStep?.stepId}
      />

      <FormSelect
        control={control}
        fieldName={selectedStepIdKey}
        validValues={dataList}
      />
      <Divider />
      {selectedStep && (
        <Box sx={{ overflow: "auto" }}>
          <Box>
            <StepMessages messages={flow.data[selectedStep.id]?.messages} />
          </Box>
          <Box sx={{ paddingTop: "1rem" }}>
            <StepComponent
              control={control}
              flow={flow}
              data={flow.data[selectedStep.id]}
              flowStep={selectedStep}
              setValue={setValue}
              {...props}
            />
          </Box>
        </Box>
      )}
    </StyledStepContentWrapper>
  );
}
