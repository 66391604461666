import {
  EntityData,
  EntityType,
  FieldConfig,
  FieldKey,
  FieldType,
  FieldVariant,
  Icon,
  Page,
  Product,
} from "core/api";
import { Bookmark } from "core/api/models/Bookmark";
import {
  fieldKeyFromString,
  getField,
  getFieldDisplayValue,
  getFieldKey,
} from "./FieldUtils";

/**
 * Get entity id name for an entity type.
 *
 * @remarks
 * Note, these "keywords" are the ones to use when replacing URL placeholders.
 * For instance when converting the screen entities into path parameters.
 *
 *
 * @param entityType An EntityType
 *
 * @returns Returns the entityIdName as text
 *
 * @see the EntityUtils.java, method getEntityIdKey()
 */
export function getEntityIdKey(entityType: EntityType): string {
  switch (entityType) {
    case EntityType.ACCOUNT:
      return "accountId";
    case EntityType.ADDRESS:
      return "addressId";
    case EntityType.ADJUSTMENT:
      return "adjustmentId";
    case EntityType.ARTICLE:
      return "articleId";
    case EntityType.CUSTOMER:
      return "customerNo";
    case EntityType.CUSTOMER_MANDATE:
      return "mandateId";
    case EntityType.DOCUMENT:
      return "documentId";
    case EntityType.INVENTORY:
      return "inventoryId";
    case EntityType.INVOICE:
      return "invoiceId";
    case EntityType.INVOICE_ACCOUNT:
      return "invoiceAccountId";
    case EntityType.MESSAGE:
      return "messageId";
    case EntityType.NETWORK_ELEMENT:
      return "elementId";
    case EntityType.NOTE:
      return "noteId";
    case EntityType.NP_CASE:
      return "caseId";
    case EntityType.ORDER:
      return "orderId";
    case EntityType.PAYMENT:
      return "paymentId";
    case EntityType.PAYMENT_PROVIDER_HISTORY:
      return "logId";
    case EntityType.PRODUCT:
      return "productId";
    case EntityType.REPAYMENT:
      return "repaymentId";
    case EntityType.RESELLER:
      return "resellerId";
    case EntityType.REPORT:
      return "reportId";
    case EntityType.SDR:
      return "sdrId";
    case EntityType.SUBSCRIPTION:
    case EntityType.BUNDLED_PRODUCT:
      return "subscriptionId";
    case EntityType.TASK:
      return "taskId";
    case EntityType.TASK_LIST:
      return "taskListId";
    case EntityType.USER:
      return "userId";
    case EntityType.NRDB_CLIENT:
      return "clientId";
    case EntityType.NRDB_MESSAGE:
      return "messageId";
    case EntityType.NRDB_NUMBER:
      return "nrdbNumberId";
    case EntityType.NRDB_CONFIG:
      return "nrdbConfigId";
    case EntityType.NRDB_PROXY:
      return "proxyId";
    case EntityType.WEB_ACTION:
      return "actionId";
    case EntityType.WEB_SCREEN:
      return "screenId";
    case EntityType.WEB_VIEW:
      return "viewId";
    case EntityType.WEB_WIDGET:
      return "widgetId";
    case EntityType.FLOW:
      return "flowId";
    case EntityType.FLOW_STEP_DEFINITION:
      return "stepId";
    case EntityType.WEB_TABLE:
      return "tableId";
    case EntityType.WEB_TABLE_OPTION:
      return "tableOptionId";
    case EntityType.WEB_TABLE_FIELD_DEFINITION:
      return "tableFieldDefinitionId";
    case EntityType.WEB_SEARCH_FILTER:
      return "searchFilterId";
    default:
      return "";
  }
}

export function getEntityIdFieldKey(entityType: EntityType): FieldKey {
  /* The key value must match the Column name of the DB object corresponding to the entity.
   * For example, "Adjustment_Id" matches com.billiant.billiant.dbapi.Adjustment.Columns.ADJUSTMENT_ID */
  switch (entityType) {
    case EntityType.ADDRESS:
      return {
        key: "Address_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.ADJUSTMENT:
      return {
        key: "Adjustment_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.ARTICLE:
      return {
        key: "Article_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.CREDIT_NOTE:
      return {
        key: "Invoice_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.INVOICE_ACCOUNT:
    case EntityType.CUSTOMER:
      return {
        key: "Customer_No",
        variant: FieldVariant.FIXED,
      };
    case EntityType.DOCUMENT:
      return {
        key: "Document_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.FLOW:
      return {
        key: "Flow_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.FLOW_STEP_DEFINITION:
      return {
        key: "Step_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.INVENTORY:
      return {
        key: "Inventory_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.INVOICE:
      return {
        key: "Invoice_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.MESSAGE:
      return {
        key: "Message_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.NETWORK_ELEMENT:
      return {
        key: "Element_id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.NOTE:
      return {
        key: "Note_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.NP_CASE:
      return {
        key: "Case_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.NRDB_CONFIG:
      return {
        key: "Nrdb_Config_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.NRDB_NUMBER:
      return {
        key: "Nrdb_Number_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.NRDB_CLIENT:
      return {
        key: "Client_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.NRDB_MESSAGE:
      return {
        key: "Message_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.NRDB_PROXY:
      return {
        key: "Proxy_Id",
        variant: FieldVariant.FIXED,
      };

    case EntityType.EVENT_ROUTE_INVOCATION_HISTORY:
      return {
        key: "Invocation_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.ORDER:
      return {
        key: "Order_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.PAYMENT:
      return {
        key: "Payment_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.PAYMENT_PROVIDER_HISTORY:
      return {
        key: "log_id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.RESELLER:
      return {
        key: "Reseller_id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.PRODUCT:
      return {
        key: "Product_Offering_Instance_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.PRODUCT_OFFERING:
      return {
        key: "Product_Offering_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.SDR:
      // key matches com.billiant.billiant.dbapi.SdrUsageValue.Columns.SDR_ID
      return { key: "SDR_Id", variant: FieldVariant.FIXED };

    case EntityType.SUBSCRIPTION:
      return {
        key: "Subscription_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.TASK:
      return {
        key: "Task_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.TASK_LIST:
      return {
        key: "Task_List_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.USER:
      return {
        key: "User_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.WEB_WIDGET:
      return {
        key: "Web_Widget_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.WEB_VIEW:
      return {
        key: "Web_View_Id",
        variant: FieldVariant.FIXED,
      };

    case EntityType.WEB_ACTION:
      return {
        key: "Web_Action_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.WEB_TABLE:
      return {
        key: "Web_Table_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.WEB_TABLE_OPTION:
      return {
        key: "Web_Table_Option_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.WEB_TABLE_FIELD_DEFINITION:
      return {
        key: "Web_Table_Field_Definition_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.WEB_SCREEN:
      return {
        key: "Web_Screen_Id",
        variant: FieldVariant.FIXED,
      };
    case EntityType.WEB_SEARCH_FILTER:
      return {
        key: "Web_Search_Filter_Id",
        variant: FieldVariant.FIXED,
      };
    default:
      return { key: "", variant: FieldVariant.FIXED };
  }
}

/**
 *
 * @param Bookmark A Bookmark
 * @returns Sorts list of Bookmark by primaryText
 */
export function sortByPrimary(a: Bookmark, b: Bookmark): number {
  if (a.primaryText === b.primaryText) {
    return 0;
  }
  return a.primaryText < b.primaryText ? -1 : 1;
}

/**
 *
 * @param EntityType An EntityType
 * @returns Sorts list of EntityType by entity type
 */
export function sortEntityType(a: EntityType, b: EntityType): number {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}

/**
 *
 * @param entity The current entity object
 * @returns True if the entity would be nested inside a collapsible row.
 */
export function isNestedRow(entity: EntityData): boolean {
  if ((entity as Product).topProductOfferingId) {
    return true;
  } else {
    return false;
  }
}

export function getTitleKeys(title: string): string[] {
  const paramRegex = /:[a-zA-Z|.|_]+/gm;
  const matches = title?.match(paramRegex);

  return (matches ? matches.map((key) => key.slice(1)) : []) as string[];
}

function formatTitleFromKeys(
  keys: string[],
  title: string,
  screenEntity: Partial<EntityData>,
  fieldConfigMap: Record<string, FieldConfig>
): string {
  return keys.reduce((formattedTitle, key) => {
    const fieldConfig = fieldConfigMap[key];
    return fieldConfig
      ? formattedTitle.replace(
          `:${key}`,
          getFieldDisplayValue(
            fieldKeyFromString(key),
            fieldConfig.uiComponent,
            screenEntity.fields ?? []
          )
        )
      : "";
  }, title ?? "");
}

export function getEntityTitle(
  screenEntity: Partial<EntityData> | undefined,
  title: string | undefined,
  fieldConfigMap: Record<string, FieldConfig>,
  entityFetchUrl: string | undefined
): string {
  if (!!screenEntity && !!title) {
    const keys = getTitleKeys(title);
    return keys
      ? formatTitleFromKeys(keys, title, screenEntity, fieldConfigMap)
      : title;
  }

  return entityFetchUrl ? "" : title ?? "";
}

/**
 * Get the icon that represents the status of an entity.
 *
 * @param entity An EntityData object that contains all the data defining
 *   an entity, including of its fields.
 *
 * @param fieldConfigs An array containing a field configuration for every field
 *   of that entity. It is used to identify which fields have the type
 *   ENTITY_STATUS_REASON_ID and ENTITY_STATUS_ID.
 *
 * @returns The Icon defined on the field of type ENTITY_STATUS_REASON_ID.
 *   If there is no icon defined on that field, the function returns the icon
 *   defined on the field of type ENTITY_STATUS_ID. If there is no icon defined
 *   on that field either, the function returns **undefined**.
 */
export function getEntityStatusIcon(
  entity: EntityData | undefined,
  fieldConfigs: FieldConfig[]
): Icon | undefined {
  if (!entity) {
    return undefined;
  }
  const fieldKeyStatusReasonId = getFieldKey(
    FieldType.ENTITY_STATUS_REASON_ID,
    fieldConfigs
  );
  if (fieldKeyStatusReasonId) {
    const fieldValue = getField(fieldKeyStatusReasonId, entity.fields)
      ?.values[0];
    if (fieldValue?.icon) {
      return {
        ...fieldValue?.icon,
        description: fieldValue?.description,
        displayValue: fieldValue?.displayValue,
      } as Icon;
    }
  }
  const fieldKeyStatusId = getFieldKey(
    FieldType.ENTITY_STATUS_ID,
    fieldConfigs
  );
  if (fieldKeyStatusId) {
    const fieldValue = getField(fieldKeyStatusId, entity.fields)?.values[0];
    if (fieldValue?.icon) {
      return {
        ...fieldValue?.icon,
        description: fieldValue?.description,
        displayValue: fieldValue?.displayValue,
      } as Icon;
    }
  }
  return undefined;
}

/**
 * This function is used to work around the issue that some API endpoints
 * return the data as EntityData[], and other API endpoints return the data as
 * Page<EntityData>.
 *
 * @param data the data returned by the REST API, either as EntityData[]
 *  or as Page<EntityData>
 *
 * @returns **data** if **data** is of type EntityData[],
 *   **data.items** if **data** is of type Page<EntityData>,
 *   or [] if **data** is undefined
 */
export function getEntityDataFromAPIQueryResult(
  data: EntityData[] | Page<EntityData> | undefined
): EntityData[] {
  let entityDataArray: EntityData[] = [];
  if (data) {
    if (Array.isArray(data)) {
      entityDataArray = data;
    } else if ("items" in data) {
      entityDataArray = data.items;
    } else {
      console.error(
        "'data' is not of type EntityData[] nor does it contain an 'items' property that is of type EntityData[]"
      );
    }
  }
  return entityDataArray;
}
