/**
 * Enum used to describe priority of an entity alert
 */
export enum EntityAlertPriority {
  NEUTRAL = "NEUTRAL",
  INFO = "INFO",
  ERROR = "ERROR",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
}
