import {
  AdjustmentAmount,
  adjustmentAmountDataResolver,
  AdjustmentProperties,
  adjustmentPropertiesDataResolver,
} from "./Adjustment";
import { AmountWithVAT, amountWithVATDataResolver } from "./AmountWithVAT";
import { Binds, bindsDataResolver } from "./Binds";
import {
  ChangeCustomerStatus,
  changeCustomerStatusDataResolver,
} from "./ChangeCustomerStatus";
import { ChangeDueDate } from "./ChangeDueDate";
import { changeDueDateDataResolver } from "./ChangeDueDate/changeDueDateDataResolver";
import {
  ChangeEntityStatus,
  changeEntityStatusDataResolver,
} from "./ChangeEntityStatus";
import {
  ChangeProductStatus,
  changeProductStatusDataResolver,
} from "./ChangeProductStatus";
import { Confirmation } from "./Confirmation";
import {
  EditCustomerNote,
  editCustomerNoteDataResolver,
} from "./CreateCustomerFriendlyNote";
import { CreatePayment } from "./CreatePayment";
import { createPaymentDataResolver } from "./CreatePayment/createPaymentDataResolver";
import { creditCheckDataResolver } from "./CreditCheck";
import { CreditCheck } from "./CreditCheck/CreditCheck";
import { CreditInvoice } from "./CreditInvoice";
import { Decision, decisionDataResolver } from "./Decision";
import {
  ConfigureInvoiceDunning,
  configureInvoiceDunningDataResolver,
} from "./Dunning";
import { EditInventory, editInventoryDataResolver } from "./EditInventory";
import { Empty } from "./Empty";
import { Fields, fieldsDataResolver } from "./Fields";
import { AddressFields } from "./Fields/AddressFields";
import { addressFieldsDataResolver } from "./Fields/addressFieldsDataResolver";
import { FindInventory, findInventoryDataResolver } from "./FindInventory";
import {
  FindNetworkElement,
  findNetworkElementDataResolver,
} from "./FindNetworkElement";
import { Logistics, logisticsDataResolver } from "./Logistics";
import { MissingConfig } from "./MissingConfig";
import {
  OrderAttributes,
  orderAttributesDataResolver,
} from "./OrderAttributes";
import { PayInvoice } from "./PayInvoice";
import { payInvoiceDataResolver } from "./PayInvoice/payInvoiceDataResolver";
import {
  ProductPartsConfiguration,
  productPartsConfigurationDataResolver,
} from "./ProductPartsConfiguration";
import { Repayment } from "./Repayment";
import { repaymentDataResolver } from "./Repayment/repaymentDataResolver";
import { SelectProduct, selectProductResolver } from "./SelectProduct";
import { SelectStep, selectStepDataResolver } from "./SelectStep";
import { SetReseller, setResellerDataResolver } from "./SetReseller";
import { createStepSettings as createStep } from "./stepUtils";
import { Table, tableDataResolver } from "./Table";
import {
  TransferProduct,
  transferProductDataResolver,
} from "./TransferProduct";
import { UpgradeDowngrade } from "./UpgradeDowngrade/UpgradeDowngrade";
import { upgradeDowngradeResolver } from "./UpgradeDowngrade/upgradeDowngradeResolver";

/**
 * List of all registered steps.
 * Resolvers are only necessary for steps that has form data.
 */
export const steps = [
  createStep("AmountWithVAT", AmountWithVAT, amountWithVATDataResolver),
  createStep(
    "AdjustmentAmount",
    AdjustmentAmount,
    adjustmentAmountDataResolver
  ),
  createStep("Decision", Decision, decisionDataResolver),
  createStep(
    "AdjustmentProperties",
    AdjustmentProperties,
    adjustmentPropertiesDataResolver
  ),
  createStep("Binds", Binds, bindsDataResolver),
  createStep(
    "ChangeCustomerStatus",
    ChangeCustomerStatus,
    changeCustomerStatusDataResolver
  ),
  createStep(
    "ChangeEntityStatus",
    ChangeEntityStatus,
    changeEntityStatusDataResolver
  ),
  createStep(
    "ChangeProductStatus",
    ChangeProductStatus,
    changeProductStatusDataResolver
  ),
  createStep("CreditCheck", CreditCheck, creditCheckDataResolver),
  createStep("CreditInvoice", CreditInvoice),
  createStep("CreditInvoiceConfirm", Confirmation),
  createStep("EditInventory", EditInventory, editInventoryDataResolver),
  createStep("Empty", Empty),
  createStep("Fields", Fields, fieldsDataResolver),
  createStep("OrderAttributes", OrderAttributes, orderAttributesDataResolver),
  createStep("AddressFields", AddressFields, addressFieldsDataResolver),
  createStep("Confirmation", Confirmation),
  createStep("MissingConfig", MissingConfig),
  createStep("Logistics", Logistics, logisticsDataResolver),
  createStep("PayInvoice", PayInvoice, payInvoiceDataResolver),
  createStep("CreatePayment", CreatePayment, createPaymentDataResolver),
  createStep(
    "EditCustomerNote",
    EditCustomerNote,
    editCustomerNoteDataResolver
  ),
  createStep("FindInventory", FindInventory, findInventoryDataResolver),
  createStep(
    "FindNetworkElement",
    FindNetworkElement,
    findNetworkElementDataResolver
  ),
  createStep(
    "ProductPartsConfiguration",
    ProductPartsConfiguration,
    productPartsConfigurationDataResolver
  ),
  createStep("ChangeDueDate", ChangeDueDate, changeDueDateDataResolver),
  createStep("Repayment", Repayment, repaymentDataResolver),
  createStep("SelectProduct", SelectProduct, selectProductResolver),
  createStep("SelectStep", SelectStep, selectStepDataResolver),
  createStep("SetReseller", SetReseller, setResellerDataResolver),
  createStep("Table", Table, tableDataResolver),
  createStep("TransferProduct", TransferProduct, transferProductDataResolver),
  createStep(
    "ConfigureInvoiceDunning",
    ConfigureInvoiceDunning,
    configureInvoiceDunningDataResolver
  ),
  createStep("UpgradeDowngrade", UpgradeDowngrade, upgradeDowngradeResolver),
];
