import {
  EntityType,
  IgnoreError,
  SearchQueryOptions,
  useSearchQuery,
} from "core/api";
import { useLoadPagedInfiniteQuery } from "core/components";
import { useMemo } from "react";

export interface InfinityQuerySearchOptions {
  /** API url */
  apiUrl: string | undefined;
  /** Entity type */
  entityType: EntityType | undefined;
  /** Search query */
  currentSearch: string;
  /** Setting this parameter to **true** forces the query request to be sent
   * to the server even if **currentSearch** is undefined or empty.
   * The default value is **false**, which will prevent sending the request
   * to the server if **currentSearch** is undefined or empty. */
  acceptEmptySearch?: boolean;
  /** Search filters */
  filters: string | string[] | undefined;
  /** If search is disabled and data should be fetched directly */
  searchDisabled: boolean;
  /** Unique search id */
  searchId?: string;
  orderBy: string | undefined;
  page: number | undefined;
  pageSize: number;
  order: "asc" | "desc";
  enabled?: boolean;
  /** Ignore all errors or based on request status codes */
  ignoreError: IgnoreError;
}

/** Infinity query search */
export function useInfinityQuerySearch({
  apiUrl,
  entityType,
  currentSearch,
  acceptEmptySearch = false,
  filters,
  searchDisabled,
  searchId,
  order,
  orderBy,
  page,
  pageSize,
  enabled,
  ignoreError,
}: InfinityQuerySearchOptions) {
  const filterObject: SearchQueryOptions = useMemo(
    () => ({
      apiUrl,
      entityType,
      enabled:
        !!enabled &&
        (currentSearch.length > 0 ||
          !!filters?.length ||
          searchDisabled ||
          acceptEmptySearch),
      search: currentSearch,
      acceptEmptySearch,
      filters,
      pageNo: 0,
      pageSize: pageSize,
      orderBy: orderBy,
      ascending: order === "asc",
      noCache: true,
      searchId,
      ignoreError,
    }),
    [
      apiUrl,
      entityType,
      enabled,
      currentSearch,
      acceptEmptySearch,
      filters,
      pageSize,
      orderBy,
      order,
      searchDisabled,
      searchId,
      ignoreError,
    ]
  );

  return useLoadPagedInfiniteQuery(
    useSearchQuery,
    filterObject,
    page ?? 0,
    pageSize,
    ignoreError
  );
}
