import { Checkbox, Chip, ListItemText, styled } from "common/components";

export interface StyledChipProps {
  maxWidth: number;
}

export interface StyledSelectContainerProps {
  fullWidth?: boolean;
}

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "maxWidth",
})<StyledChipProps>(({ theme, maxWidth }) => ({
  maxWidth: maxWidth + "px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  margin: "3px",
}));

export const StyledSelectContainer = styled("div")<StyledSelectContainerProps>(
  ({ theme, fullWidth }) => ({
    minWidth: fullWidth ? 0 : 250,
    maxWidth: fullWidth ? "100%" : 800,
    width: fullWidth ? "100%" : "auto",
  })
);

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  height: 24,
  width: 24,
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  "& > span": {
    width: "calc(100%)",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));
