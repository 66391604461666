import { useParams } from "common/routing";
import { isEqual, omit } from "lodash";
import { useEffect, useMemo } from "react";
import { v4 as uuidV4 } from "uuid";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type AppParams = Record<string, Record<string, string | undefined>>;

interface AppParamsStore {
  params: AppParams;
  addParams(key: string, params: AppParams[string]): void;
  removeParams(key: string): void;
}

const useAppParamsStore = create<AppParamsStore>()(
  devtools((set, get) => ({
    params: {},
    addParams: (key, params) => {
      if (!isEqual(get().params[key], params)) {
        set((state) => ({
          ...state,
          params: { ...state.params, [key]: params },
        }));
      }
    },
    removeParams: (key) => {
      if (key in get().params) {
        set((state) => ({ ...state, params: omit(state.params, [key]) }));
      }
    },
  }))
);

/**
 * Register current route parameters
 */
export function useRegisterParams() {
  const params = useParams();
  const key = useMemo(() => uuidV4(), []);
  const { addParams, removeParams } = useAppParamsStore();

  useEffect(() => {
    addParams(key, params);
  }, [key, params, addParams]);

  useEffect(() => {
    return () => removeParams(key);
  }, [key, removeParams]);
}

/**
 * Get current route parameters in store
 * @param additionalParams - Additional parameters to include
 * @returns Route parameters
 */
export function useRouteParams(
  additionalParams?: Record<string, any>
): Record<string, any> {
  const state = useAppParamsStore((state) => state.params);

  // Merge additionalParams with the route params
  const mergedParams = { ...additionalParams };
  Object.values(state).forEach((currentParams) => {
    Object.assign(mergedParams, currentParams);
  });

  return mergedParams;
}
