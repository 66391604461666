import { Box, List, ListSubheader, Typography } from "common/components";
import {
  ConfirmationFlowData,
  DefaultFlowStep,
  FindNetworkElementFlowData,
  Flow,
  FlowData,
  FlowStep,
  extractChangeEntityStatusFlowStep,
} from "core/api";
import { groupBy } from "../../flowUtils";
import { FlowStepComponentProps } from "../models";
import {
  AdjustmentAmountConfirmation,
  AdjustmentPropertiesConfirmation,
  ChangeDueDateConfirmation,
  ChangeEntityStatusConfirmation,
  CreatePaymentConfirmation,
  DecisionConfirmation,
  EditInventoryConfirmation,
  FieldsConfirmation,
  FindInventoryConfirmation,
  OrderAttributesConfirmation,
  PayInvoiceConfirmation,
  ProductOfferingCardsConfirmation,
  RepaymentConfirmation,
  TableConfirmation,
  TransferProductConfirmation,
} from "./subs";
import { CreditCheckConfirmation } from "./subs/CreditCheckConfirmation";
import { CreditInvoiceConfirmation } from "./subs/CreditInvoiceConfirmation";
import { EditCustomerNoteConfirmation } from "./subs/EditCustomerNoteConfirmation";
import { ProductPartsConfigurationConfirmation } from "./subs/ProductPartsConfigurationConfirmation";
import { SelectStepConfirmation } from "./subs/SelectStepConfirmation";
import { SetResellerConfirmation } from "./subs/SetResellerConfirmation";

export type ConfirmationProps = FlowStepComponentProps<
  DefaultFlowStep,
  ConfirmationFlowData
>;

/**The renderConfirmationComponent function returns a JSX element or null depending on the step.uiComponent type,
 determined by a switch statement, and returns the corresponding confirmation component.*/
export function renderConfirmationComponent(
  step: FlowStep,
  flowData: FlowData,
  flow: Flow,
  title?: string
): JSX.Element | null {
  switch (step.uiComponent) {
    case "CreditInvoice":
      return (
        <CreditInvoiceConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "CreditCheck":
      return (
        <CreditCheckConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "SelectStep":
      return (
        <SelectStepConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
          flow={flow}
        />
      );

    case "Fields":
    case "AddressFields":
      return (
        <FieldsConfirmation key={step.id} step={step} flowData={flowData} />
      );

    case "FindInventory":
      return (
        <FindInventoryConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
          title={title}
        />
      );

    case "FindNetworkElement":
      flowData = (flowData as FindNetworkElementFlowData).tableStep;
      flowData.uiComponent = "Table";
      return (
        <TableConfirmation
          key={step.id}
          step={step.config.tableStepConfig}
          flowData={flowData}
          title={"Selected Network Element"}
        />
      );

    case "OrderAttributes":
      return (
        <OrderAttributesConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "ChangeCustomerStatus":
      return (
        <ChangeEntityStatusConfirmation
          key={step.id}
          step={extractChangeEntityStatusFlowStep(step)}
          flowData={flowData}
        />
      );

    case "ChangeEntityStatus":
      return (
        <ChangeEntityStatusConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "ChangeProductStatus":
      return (
        <ChangeEntityStatusConfirmation
          key={step.id}
          step={extractChangeEntityStatusFlowStep(step)}
          flowData={flowData}
        />
      );

    case "CreatePayment":
      return (
        <CreatePaymentConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "EditCustomerNote":
      return (
        <EditCustomerNoteConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "PayInvoice":
      return (
        <PayInvoiceConfirmation key={step.id} step={step} flowData={flowData} />
      );

    case "EditInventory":
      return (
        <EditInventoryConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "AdjustmentAmount":
      return (
        <AdjustmentAmountConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "AdjustmentProperties":
      return (
        <AdjustmentPropertiesConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "Decision":
      return (
        <DecisionConfirmation key={step.id} step={step} flowData={flowData} />
      );

    case "Repayment":
      return (
        <RepaymentConfirmation key={step.id} step={step} flowData={flowData} />
      );

    case "ChangeDueDate":
      return (
        <ChangeDueDateConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "Table":
      return (
        <TableConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
          title={title}
        />
      );

    case "TransferProduct":
      return (
        <TransferProductConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "UpgradeDowngrade":
    case "SelectProduct":
      return (
        <ProductOfferingCardsConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "ProductPartsConfiguration":
      return (
        <ProductPartsConfigurationConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    case "SetReseller":
      return (
        <SetResellerConfirmation
          key={step.id}
          step={step}
          flowData={flowData}
        />
      );

    default:
      return null;
  }
}

/** Confirmation summary step component */
export function Confirmation({
  data,
  flow,
}: ConfirmationProps): JSX.Element | null {
  const grouped = groupBy(flow.steps, (s) => s.group || "");

  return (
    <Box>
      <Typography>{data.message}</Typography>
      <List>
        {Object.keys(grouped).map((key: string) => {
          return renderGroup(key, grouped[key], flow);
        })}
      </List>
    </Box>
  );
}

/**
 *
 * @param groupName Render a group of steps with a list sub header if there is a group Name
 * @param steps
 * @param flow
 * @returns
 */
function renderGroup(
  groupName: string,
  steps: FlowStep[],
  flow: Flow
): JSX.Element | null {
  return (
    <>
      {groupName && (
        <ListSubheader
          sx={{
            background: (theme) => theme.palette.primary.light,
            borderRadius: 2,
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
          }}
        >
          {groupName}
        </ListSubheader>
      )}
      {steps.map((step: FlowStep) => {
        const flowData = flow.data[step.id];
        return renderConfirmationComponent(step, flowData, flow);
      })}
    </>
  );
}
