import {
  Checkbox,
  LabeledSelect,
  LabeledSelectProps,
  ListItemText,
  MenuItem,
  SelectOption,
} from "common/components";
import { useTranslation } from "i18n";
import { ReactElement, useEffect } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

/** Form multiple select field */
export function FormMultipleSelect({
  renderOption,
  ...props
}: FormMultiSelectProps) {
  return (
    <FormMultiSelect
      {...props}
      multiple
      renderOption={
        renderOption ??
        (({ label, value }, selectedValue) => (
          <MenuItem value={value}>
            <Checkbox checked={(selectedValue as unknown[])?.includes(value)} />
            <ListItemText primary={label} />
          </MenuItem>
        ))
      }
      renderValue={(value) =>
        props.dataList
          ?.filter((option) =>
            (value as Array<string | number>)?.includes(option.value)
          )
          .map(({ label }) => label)
          .join(", ")
      }
    />
  );
}

export interface FormMultiSelectProps<
  TOption extends SelectOption = SelectOption
> extends FormControlledFieldProps,
    Omit<LabeledSelectProps<TOption>, "defaultValue" | "label"> {}

/**
 * Adds form handling for FormMultiSelect. Added in the same component to remove the option to import this in other components.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param dataList The list of selectable key:value pairs.
 * @param props Any remaining properties.
 * @param includeEmptyOption If set, add an empty value (-1, <None>) first in the select
 */
const FormMultiSelect = withFormController<FormMultiSelectProps>(
  ({ helperText, renderState, ...props }): ReactElement => {
    const { t } = useTranslation(["common"]);
    const {
      field,
      fieldState: { error },
      gridColumn,
      isDisabled,
      setExtendedRules,
    } = renderState;

    useEffect(() => {
      setExtendedRules({
        validate: {
          notEmptyRequiredValue: (value) => {
            if (
              props.required &&
              (value === "-1" || (Array.isArray(value) && value.length === 0))
            ) {
              return t("common:forms.fieldIsMandatory");
            }
            return true;
          },
        },
      });
    }, [setExtendedRules, t, props.required]);

    const filteredProps: any = { ...props };
    delete filteredProps.fieldName;

    return (
      <LabeledSelect
        fullWidth
        error={!!error}
        helperText={error?.message ?? helperText}
        {...(filteredProps as LabeledSelectProps)}
        {...field}
        disabled={isDisabled}
        FormControlProps={{
          ...props.FormControlProps,
          sx: [
            { gridColumn },
            ...((Array.isArray(props.FormControlProps?.sx)
              ? props.FormControlProps?.sx
              : [props.FormControlProps?.sx]) ?? []),
          ],
        }}
      />
    );
  }
);
