import { getFontIcon } from "common/assets/icons";
import { ActionConfig, EntityData, EntityType } from "core/api";
import { OrderedActionButton } from "core/components";

export interface OrderedActionOptions {
  /** If entity is undefined, the action configuration will prevail.
   * If entity is defined, the action will be enabled only if it is
   * applicable to this entity. */
  entity?: EntityData | EntityData[];
  /** Optional argument that can be used to force the action to be disabled. */
  disabled?: boolean;
  /** Optional argument that can be used to make disabled action invisible */
  hideDisabledActions?: boolean;
  /** Function that will be triggered when the action is clicked */
  onClick(): void;
}

/**
 * Creates an OrderedActionButton that will be used to render the action.
 * The action will be enabled or disabled, depending on the ActionConfig
 * argument (that represents's the action's configuration),
 * and the OrderedActionOptions argument (that represents options defined
 * by the context from which this function is called). See details of the
 * OrderedActionOptions above.
 */
export function createOrderedAction(
  { icon, config, color, variant, size, ...actionProps }: ActionConfig,
  { entity, disabled, hideDisabledActions, onClick }: OrderedActionOptions
): OrderedActionButton {
  const isNotApplicable = !isApplicableAction(
    actionProps.actionId,
    actionProps.entityType,
    entity
  );
  return {
    ...actionProps,
    color: color ?? "primary",
    variant: variant ?? "outlined",
    size: size ?? "small",
    icon,
    Icon: icon ? getFontIcon(icon) : undefined,
    href:
      config && Object.keys(config).includes("href")
        ? (config as { href: string }).href
        : undefined,
    disabled: actionProps.disabled || disabled || isNotApplicable,
    visible:
      (actionProps.disabled || disabled || isNotApplicable) &&
      hideDisabledActions
        ? false
        : actionProps.visible,
    onClick,
  };
}

export function isApplicableAction(
  actionId: number,
  requiredEntityType: EntityType,
  entity: EntityData | EntityData[] | undefined
): boolean {
  const isApplicableEntity = (targetEntity: EntityData | undefined) =>
    !(
      Array.isArray(targetEntity?.applicableActions) &&
      !targetEntity?.applicableActions.includes(actionId) &&
      requiredEntityType !== EntityType.NONE
    );

  if (Array.isArray(entity)) {
    return (
      entity.length > 0 &&
      entity.reduce(
        (isApplicable, _entity) => isApplicable && isApplicableEntity(_entity),
        true
      )
    );
  }
  return isApplicableEntity(entity);
}

/**
 * :
 * - action entityType must be EntityType ORDER
 * - action entityTypeId must be either -1 or match the entityTypeId
 *   of the current order
 */

/**
 * Filter that keeps only actions applicable to the given *entityType*
 * and *entityTypeId* (if provided).
 *
 * @param action The configuration of a Web Action
 * @param entityType An EntityType
 * @param entityTypeId (optional) An entityTypeId that defines a sub-type of entity.
 *   Defaults to -1.
 * @returns *true* if the entity type of the given action configuration
 *   matches the given entity type and the entityTypeId of the action
 *   configuration is either -1 or matches the given *entityTypeId*, else
 *   returns *false*.
 */
export function actionFilter(
  action: ActionConfig,
  entityType: EntityType,
  entityTypeId = -1
): boolean {
  return (
    action.entityType === entityType &&
    (action.entityTypeId === -1 || action.entityTypeId === entityTypeId)
  );
}
