import { EditCustomerNoteFlowData, EditCustomerNoteFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 *  edit customer note data resolver
 *
 */
export const editCustomerNoteDataResolver: StepDataResolver<
  EditCustomerNoteFlowStep,
  EditCustomerNoteFlowData
> = ({ data, fieldValues }) => {
  return {
    noteText: fieldValues.noteText ?? data.noteText,
    status:
      data.allStatuses.find((status) => status.id === fieldValues.status) ??
      data.status,
  };
};
