import { getUserLocale } from "core/api";
import currency from "currency.js";
import i18next from "i18next";

// As in Billiant core, see the CurrencyBox.MAXIMUM_FRACTION_DIGITS
// But this is Javascript and it seems if we specify anything above 4 we get 4... - revealed by simple tests //JSO
export const MAXIMUM_FRACTION_DIGITS = 10;

/**
 * Interface that defines properties to configure how numbers are formatted.
 */
export interface NumberFormatOptions extends Intl.NumberFormatOptions {
  /** Override user locale */
  locale?: string;
  /**
   * Optional. Number of decimals displayed after the decimal separator.
   */
  numberOfDecimals?: number;
  /**
   * Optional. Setting this property to true forces the field to display
   * exactly numberOfDecimals decimals after the separator.
   * Setting this property to false allows the fields to display fewer decimals
   * than numberOfDecimals, if the value does not require so many decimals.
   */
  fixedDecimals?: boolean;
}

/**
 * Displaying amount formatted for language settings
 * @param amount The amount that should be formatted
 * @param currencyCode The currency code
 * @returns A locale formatted amount
 */
export function formatCurrency(
  amount: number,
  currencyCode: string,
  options?: NumberFormatOptions
): string {
  if (!currencyCode) {
    return "";
  }
  if (Number.isNaN(amount)) {
    return "NaN";
  }
  return new Intl.NumberFormat(
    options?.locale ?? getUserLocale("amountLocale"),
    {
      style: "currency",
      currency: currencyCode,
      ...options,
    }
  ).format(amount);
}

/**
 * Gets currency symbol based on currency code
 * @param currencyCode Currency code
 * @param options NumberFormatOptions
 * @returns Currency symbol
 */
export function getCurrencySymbol(
  currencyCode: string,
  options?: NumberFormatOptions
): string {
  return formatCurrency(1, currencyCode, options).replace(/[\d., ]/g, "");
}

/**
 * Checks if currency symbol is a prefix symbol
 * @param currencyCode Currency code
 * @param options NumberFormatOptions
 * @returns If currency symbol is a prefix symbol
 */
export function isPrefixSymbol(
  currencyCode: string,
  options?: NumberFormatOptions
): boolean {
  const symbol = getCurrencySymbol(currencyCode, options);
  return formatCurrency(1, currencyCode, options).startsWith(symbol);
}

/**
 * Displaying number formatted for language settings, without grouping.
 *
 * @param number The number to format
 * @returns A locale formatted number string
 */
export function formatNumber(
  number: number,
  options?: NumberFormatOptions
): string {
  return new Intl.NumberFormat(
    options?.locale ?? getUserLocale("numberLocale"),
    {
      useGrouping: false,
      ...options,
    }
  ).format(number);
}

/**
 * Creates a unit label in short format to be used as a adornment to an input field.
 *
 * @param unitType The name of the Billiant UnitType enum.
 *
 * @returns unit label in long format to be used as a adornment to an input field.
 */
export function createUnitLabel(unitType: string): string {
  const unit = convertToJSUnit(unitType);

  if (unit && unit !== "ordinal") {
    const sampleText = new Intl.NumberFormat(getUserLocale("numberLocale"), {
      style: "unit",
      unit,
      unitDisplay: "long",
    }).format(99);

    return sampleText.replace("99", "").trim();
  } else if (unitType === "DAY_OF_MONTH") {
    const t = i18next.getFixedT(getUserLocale("numberLocale"));
    return t("common:dayOfMonth");
  }
  return "";
}
/**
 * Displaying num formatted for language settings with unit, without grouping.
 *
 * @param number The number to format
 * @param unit the JavaScript unit
 *
 * @returns A locale formatted number string, using short format
 */
export function formatNumberWithUnit(
  number: number,
  unit: string,
  options?: NumberFormatOptions
): string {
  if (unit === "ordinal") {
    return formatNumberWithOrdinal(
      number,
      options?.locale ?? getUserLocale("numberLocale")
    );
  } else if (unit === "second") {
    return toDurationWLocale(
      number,
      options?.locale ?? getUserLocale("numberLocale")
    );
  } else if (unit === "minute") {
    return toDurationWLocale(
      60 * number,
      options?.locale ?? getUserLocale("numberLocale")
    );
  } else if (unit === "hour") {
    return toDurationWLocale(
      3600 * number,
      options?.locale ?? getUserLocale("numberLocale")
    );
  } else {
    return new Intl.NumberFormat(
      options?.locale ?? getUserLocale("numberLocale"),
      {
        style: "unit",
        unit,
        unitDisplay: "short",
        useGrouping: false,
        ...options,
      }
    ).format(number);
  }
}

/**
 * Converts a duration in seconds to the hh:mm:ss format, works with any duration >= 0
 *
 * @param duration duration in seconds
 * @returns string with hh:mm:ss - note hh can be > 23.
 */
export function toDuration(duration: number): string {
  let totalSeconds = Math.round(duration);
  const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
  totalSeconds %= 3600;
  const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, "0");
  const seconds = String(totalSeconds % 60).padStart(2, "0");

  return hours + ":" + minutes + ":" + seconds;
}

/**
 * Converts a duration in seconds to the 0h 0m 0s format, works with any duration >= 0
 *
 * @param duration duration in seconds
 *
 * @returns string with 0h 0m 0s in appropriate locale.
 */
export function toDurationWLocale(duration: number, locale: string): string {
  let totalSeconds = Math.round(duration);
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const opts: NumberFormatOptions = {
    style: "unit",
    unitDisplay: "narrow",
    useGrouping: false,
  };

  const minStr = new Intl.NumberFormat(locale, {
    ...opts,
    unit: "minute",
  }).format(minutes);

  const secStr = new Intl.NumberFormat(locale, {
    ...opts,
    unit: "second",
  }).format(seconds);

  if (hours > 0) {
    const hStr = new Intl.NumberFormat(locale, {
      ...opts,
      unit: "hour",
    }).format(hours);

    return [hStr, minStr, secStr].join(" ");
  } else if (minutes > 0) {
    return [minStr, secStr].join(" ");
  } else {
    return secStr;
  }
}

/**
 * Displaying percent formatted for language settings, without grouping, assuming 0.0 - 1.0 as input to get 0.00% to 100.00%
 *
 * @param percentage The percentage to format
 * @returns A locale formatted percentage string
 */
export function formatPercentage(
  percentage: number,
  options?: NumberFormatOptions
): string {
  return new Intl.NumberFormat(
    options?.locale ?? getUserLocale("numberLocale"),
    {
      style: "percent",
      useGrouping: false,
      minimumFractionDigits: 2,
      ...options,
    }
  ).format(percentage);
}

/** Default PIN code length */
export const DEFAULT_PIN_CODE_LENGTH = 4;

/**
 * Generates a random PIN code
 * @param pinCodeLength PIN code length
 * @returns Generated PIN code
 */
export function generatePINCode(
  pinCodeLength = DEFAULT_PIN_CODE_LENGTH
): string {
  return (Math.floor(Math.random() * 10 ** pinCodeLength) + 10 ** pinCodeLength)
    .toString()
    .substring(1);
}
/**
 * Remove currency from currency language formatted amount
 * @param amount The amount that should be formatted
 * @param currencyCode The currency code
 * @returns currency formatted text without the currency code
 */
export function getCurrencyAmount(
  amount: number,
  currencyCode: string
): string {
  return formatCurrency(amount, currencyCode).replace(
    getCurrencySymbol(currencyCode),
    ""
  );
}

/**
 * Compares 2 strings that represent numbers.
 * This function can be used as sorting function in Array.sort().
 *
 * @param a A string that represents a number
 * @param b Another string that represents a number
 * @param ascending Set to **true** for ascending order, or **false** for descending order.
 *
 * @returns 0 if a represents exactly the same number as b,
 *   else if ascending is true:
 *     - returns a number < 0 if a is smaller than b
 *     - returns a number > 0 if b is smaller than a
 *   else if ascending is false:
 *     - returns a number < 0 if b is smaller a
 *     - returns a number > 0 if a is smaller b
 *
 */
export function compareAsNumber(
  a: string,
  b: string,
  ascending: boolean
): number {
  const aNumber = parseFloat(a);
  const bNumber = parseFloat(b);
  return ascending ? aNumber - bNumber : bNumber - aNumber;
}

/**
 * This function clamps a preferred value within a range of values between a
 * defined minimum bound and a maximum bound.
 *
 * @remarks
 * The function takes three parameters: a minimum value, a preferred value,
 * and a maximum allowed value. This clamp function is inspired by the CSS
 * function of the same name: {@link https://developer.mozilla.org/en-US/docs/Web/CSS/clamp}
 *
 * @param min The minimum value is the smallest (most negative) value.
 *   This is the lower bound in the range of allowed values.
 *   If the preferred value is less than this value, the minimum value will be used.
 *
 * @param preferred The preferred value is the expression whose value will be used
 *   as long as the result is between the minimum and maximum values.
 *
 * @param max The maximum value is the largest (most positive) expression value
 *   to which the value of the property will be assigned if the preferred value
 *   is greater than this upper bound.
 *
 * @returns The preferred value if it is bigger or equal to min and smaller or equal to max.
 *   Otherwise returns min if preferred is smaller than min, or max if preferred
 *   is bigger than max.
 *
 * @throws an error if the max is smaller than min.
 *
 */
export function clamp(min: number, preferred: number, max: number): number {
  if (max < min) {
    throw new Error(
      `max (${max}) is not allowed to be smaller than min (${min})`
    );
  }
  return Math.max(min, Math.min(preferred, max));
}

/**
 * Utility function that can be used to force an input value into an interval.
 *
 * @remarks
 * The function first converts the input value to the same sign as bound by
 * inverting its sign if necessary.
 * Then the converted input value is clamped into [-bound, bound] if bound is positive,
 * or [bound, -bound] if bound is negative.
 *
 * @param input The input value whose absolute value will be forced into the clamp.
 *   Can be positive, zero or negative.
 * @param bound The lower and upper bound of the clamp.
 *   Can be positive, zero or negative.
 *
 * @returns a value that fits into the interval [0, bound] if bound is positive,
 *   or [bound, 0] if bound is negative.
 *
 * @example
 *   absoluteClamp(4, 10) returns 4.
 *   absoluteClamp(15, 10) returns 10.
 *   absoluteClamp(-4, 10) returns 4.
 *   absoluteClamp(-15, 10) returns 10.
 *   absoluteClamp(4, -10) returns -4.
 *   absoluteClamp(15, -10) returns -10.
 *   absoluteClamp(-4, -10) returns -4.
 *   absoluteClamp(-15, -10) returns -10.
 *
 * @see clamp
 *
 */
export function absoluteClamp(input: number, bound: number): number {
  if (bound < 0) {
    const convertedInput = input > 0 ? -input : input;
    return clamp(bound, convertedInput, -bound);
  } else {
    const convertedInput = input < 0 ? -input : input;
    return clamp(-bound, convertedInput, bound);
  }
}

/*
 * This is the rounding function implementation in NumericUtils.java (core)
 * public static BigDecimal roundHalfUp(BigDecimal amount, int scale) {
 * return amount.setScale(scale, BigDecimal.ROUND_HALF_UP);
 * }
 * // To be reproduced in client side
 *
 */

/**
 * This is the rounding function that should be used to round the total
 * amount of invoices or credit notes.
 * It is equivalent to the roundHalfUp(BigDecimal amount, int scale)
 * in NumericUtils.java (within Billiant core).
 *
 * @param amount Amount to be rounded
 * @param precision Number of decimals that the amount should be rounded to.
 * @returns The given amount, rounded to the given number of decimals.
 *
 * @example
 * roundHalfUp(123.4, 0) returns 123
 * roundHalfUp(123.5, 0) returns 124
 * roundHalfUp(123.6, 0) returns 124
 * roundHalfUp(123.44, 0) returns 123.4
 * roundHalfUp(123.45, 0) returns 123.5
 * roundHalfUp(123.46, 0) returns 123.5
 */
export function roundHalfUp(amount: number, precision: number): number {
  const increment = 10 ** -precision;
  return Number(currency(amount, { increment, precision }).toString());
}

/**
 * Helper to convert from the Billiant UnitType enum to JavaScript unit names
 *
 * @remarks
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#unit_2
 *
 * A special unit "ordinal" (1st,2nd, ...) has been added that must be handled separately.
 *
 * @param unitType The unitType enum name.
 * @returns the JavaScript unit name, or undefined if it does not exist.
 *
 */
export function convertToJSUnit(unitType: string): string | undefined {
  const unitMap = {
    BPS: "bit-per-second",
    BYTE: "byte",
    CENTIMETER: "centimeter",
    DAY: "day",
    DAY_OF_MONTH: "ordinal",
    DECIMETER: undefined,
    DEGREE: undefined,
    DEGREE_CELCIUS: "celsius",
    FAHRENHEIT: "fahrenheit",
    GB: "gigabyte",
    GBPS: "gigabit-per-second",
    GIB: "gigabyte",
    GRAM: "gram",
    HERTZ: undefined,
    HOUR: "hour",
    KB: "kilobyte",
    KBPS: "kilobit-per-second",
    KELVIN: undefined,
    KIB: "kilobyte",
    KILOGRAM: "kilogram",
    KILOHERTZ: undefined,
    KILOMETER: undefined,
    KILOPASCAL: undefined,
    KILOWATT: undefined,
    KPH: undefined,
    KWH: undefined,
    LITER: "liter",
    MB: "megabyte",
    MBPS: "megabit-per-second",
    MEGAHERTZ: undefined,
    MEGAPASCAL: undefined,
    MEGAWATT: undefined,
    METER: "meter",
    MIB: "megabyte",
    MILLILITER: "milliliter",
    MILLIMETER: "millimeter",
    MILLISECOND: "millisecond",
    MINUTE: "minute",
    MONTH: "month",
    MPS: "meter-per-second",
    PASCAL: undefined,
    RADIAN: undefined,
    SECOND: "second",
    SQ_CENTIMETER: undefined,
    SQ_METER: undefined,
    SQ_MILLIMETER: undefined,
    STANDARD_ATMOSPHERE: undefined,
    TB: "terabyte",
    TBPS: "terabit-per-second",
    TIB: "terabyte",
    TON: undefined,
    UNCLASSIFIED: undefined,
    WATT: undefined,
    WEEK: "week",
    YEAR: "year",
    COUNT_VALUE: undefined,
  };

  return unitMap[unitType as keyof typeof unitMap];
}

/**
 * Return the number as an ordinal string, 1st, 2nd and so on.
 *
 * @remarks
 * should be coordinated with the languages in the languages.ts file.
 *
 * see https://www.beresfordresearch.com/ordinal-numbers-from-around-the-world/
 * @param num the number
 *
 * @returns the number as an ordinal string, 1st, 2nd and so on. Or just the number if we have no ordinals defined for the specified locale.
 */
function formatNumberWithOrdinal(num: number, locale: string): string {
  switch (locale) {
    case "fr":
      return num + (num <= 1 ? "er" : "e");
    case "es":
      return num + (num <= 3 ? "ro" : "˚");
    case "it":
      return num + "˚";
    case "nb":
    case "fi":
    case "da":
    case "lb":
    case "fo":
      return num + ".";
    case "nl-be":
      return num + "e";
    case "sv": {
      const ordinal = [":e", ":a", ":a", ":e"];
      const v = num % 100;
      return (
        num +
        (ordinal ? ordinal[(v - 20) % 10] || ordinal[v] || ordinal[0] : "")
      );
    }
    case "en":
    case "en-US":
    case "en-GB": {
      const ordinal = ["th", "st", "nd", "rd"];
      const v = num % 100;
      return (
        num +
        (ordinal ? ordinal[(v - 20) % 10] || ordinal[v] || ordinal[0] : "")
      );
    }
    case "ge":
    default:
      return num + "";
  }
}
