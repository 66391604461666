import { FormSelect } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { EditInventoryFlowData, EditInventoryFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type EditInventoryProps = FlowStepComponentProps<
  EditInventoryFlowStep,
  EditInventoryFlowData
>;

/** Edit Inventory step component */
export function EditInventory({
  control,
  data,
  flowStep,
}: EditInventoryProps): ReactElement {
  const { t } = useTranslation(["inventory"]);
  const { statusList, locationList, statusId, locationId } = data;

  return (
    <StyledStepContentWrapper>
      <FormSelect
        fieldName={addFlowStepPrefix(flowStep, "statusId")}
        control={control}
        label={t("inventory:statusName")}
        required
        defaultValue={statusId}
        validValues={statusList}
      />
      <FormSelect
        fieldName={addFlowStepPrefix(flowStep, "locationId")}
        control={control}
        label={t("inventory:locationName")}
        required
        defaultValue={locationId}
        validValues={locationList}
      />
    </StyledStepContentWrapper>
  );
}
