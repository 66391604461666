import { SelectProductFlowData, SelectProductFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Select product data resolver
 *
 * Formats Select Product data on form field values
 */
export const selectProductResolver: StepDataResolver<
  SelectProductFlowStep,
  SelectProductFlowData
> = ({ data, fieldValues }) => {
  return {
    intSelectedIndex:
      fieldValues.selectedProductOffering ?? data.intSelectedIndex,
    productOfferingOptions: data.productOfferingOptions,
  };
};
