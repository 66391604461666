import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import RelativeTime from "dayjs/plugin/relativeTime";
import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import { de, en, es, fr, gb, sv } from "./lang";
import { AvailableLanguage, AvailableLocale } from "./models";

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(minMax);

export const resources = {
  de,
  en,
  es,
  gb,
  fr,
  sv,
} as const;

/**
 * Default UX language
 */
export function defaultLanguage(): AvailableLanguage {
  return AvailableLanguage.en;
}

/**
 * Change language in UX
 * @param language The language
 */
export function changeLanguage(language: string): void {
  if (language !== undefined) {
    i18n.changeLanguage(language);
  }
}

export function requireLanguage(
  language: AvailableLanguage | AvailableLocale
): void {
  switch (language) {
    case AvailableLocale.sv:
      require("dayjs/locale/sv");
      break;
    case AvailableLocale.de:
      require("dayjs/locale/de");
      break;
    case AvailableLocale.da:
      require("dayjs/locale/da");
      break;
    case AvailableLocale.nb:
      require("dayjs/locale/nb");
      break;
    case AvailableLocale.fr:
      require("dayjs/locale/fr");
      break;
    case AvailableLocale.lb:
      require("dayjs/locale/lb");
      break;
    case AvailableLocale.fi:
      require("dayjs/locale/fi");
      break;
    case AvailableLocale.nlBe:
      require("dayjs/locale/nl-be");
      break;
    case AvailableLocale.enGB:
    case AvailableLanguage.gb:
      require("dayjs/locale/en-gb");
      break;
    case AvailableLocale.es:
      require("dayjs/locale/es");
      break;
    case AvailableLocale.fo:
      require("dayjs/locale/fo");
      break;
    case AvailableLocale.it:
      require("dayjs/locale/it");
      break;
    default:
      require("dayjs/locale/en");
      break;
  }
}

i18n.on("languageChanged", (language) => {
  const lng = language.split("-")[0];
  if (lng === AvailableLanguage.sv) {
    requireLanguage(AvailableLanguage.sv);
    dayjs.locale(AvailableLanguage.sv);
  } else if (lng === AvailableLanguage.de) {
    requireLanguage(AvailableLanguage.de);
    dayjs.locale(AvailableLanguage.de);
  } else if (lng === AvailableLanguage.es) {
    requireLanguage(AvailableLanguage.es);
    dayjs.locale(AvailableLanguage.es);
  } else if (lng === AvailableLanguage.fr) {
    requireLanguage(AvailableLanguage.fr);
    dayjs.locale(AvailableLanguage.fr);
  } else if (lng === AvailableLanguage.gb) {
    requireLanguage(AvailableLanguage.gb);
    dayjs.locale("en-gb");
  } else {
    dayjs.locale(AvailableLanguage.en);
  }
});

export function initI18n(
  resources: InitOptions["resources"],
  ns: InitOptions["ns"]
): void {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      fallbackLng: defaultLanguage(),
      load: "languageOnly",
      ns,
      supportedLngs: [
        AvailableLanguage.sv,
        AvailableLanguage.en,
        AvailableLanguage.es,
        AvailableLanguage.gb,
        AvailableLanguage.de,
        AvailableLanguage.fr,
      ],
      interpolation: {
        format: (value, format, lng = i18n.language) => {
          if (format === "number" && typeof value === "number") {
            return value.toLocaleString(lng);
          }
          if (dayjs(value).isValid()) {
            /* link with formatting parameters 
            https://day.js.org/docs/en/display/format#list-of-localized-formats
            */
            return dayjs(value).format(format);
          }
          return value;
        },
        escapeValue: false,
      },
    });
}
