import { Collapse, TableBody, TableCell, TableRow } from "common/components";
import {
  MenuState,
  TableAction,
  TableHeaderDefinition,
  TableSelectionType,
  TableSelectionVariant,
  TableSkeleton,
} from "core/components";
import {
  ComponentType,
  Dispatch,
  Fragment,
  ReactElement,
  SetStateAction,
} from "react";
import { BilliantTableRow } from "./BilliantTableRow";
import { NoTableData, NoTableDataVariant } from "./NoTableData";
import { useCollapseState } from "./useCollapseState";

/**
 * Properties for body of DBTable
 */
export interface BilliantTableBodyProps<TData> {
  tableItems: TData[];
  pageSize: number;
  headers: TableHeaderDefinition<TData>[];
  isSelected(item: TData): boolean;
  handleSelect(item: TData, type?: TableSelectionType): void;
  /**
   * List of Table actions,
   * Filtered to only display the enabled actions if hideDisabledActions is true.
   */
  tableActions: TableAction<TData>[];
  /** If table has actions, both enabled and disabled */
  hasActions: boolean;
  generateRowKey(item: TData, index: number): string;
  setMenuState: Dispatch<SetStateAction<MenuState<TData>>>;
  /** Boolean for enable/disable table row checkbox, default is true */
  enableSelection?: boolean;
  /** Table selection variant */
  selectionVariant?: TableSelectionVariant;
  /** String for adding your own data-cy name on table rows, default is "table_row"
   * useful when having many (widget) tables on the same page
   */
  tableRowDataCy?: string;
  /** If table data is loading */
  isLoading?: boolean;
  /** Table data variant for no data available message */
  tableDataVariant?: NoTableDataVariant;
  /** Error message to display in table */
  errorMessage?: string;
  /** If message for no data avilable should be visible */
  showNoDataMessage?: boolean;
  /** If rows have collapsed content */
  collapsibleRows?: boolean | ((item: TData) => boolean);
  /** Content inside Collapse */
  collapseContent?: ComponentType<TData>;
  /** Key name for property containing child items */
  childItemsKey?: string;
  /** If disabled actions should be hidden */
  hideDisabledActions?: boolean;
}

/** Billiant table body component */
export function BilliantTableBody<TData extends { [key: string]: any }>({
  tableItems,
  pageSize,
  headers,
  isSelected,
  handleSelect,
  tableActions,
  hasActions,
  setMenuState,
  generateRowKey,
  enableSelection = true,
  selectionVariant = "MULTI",
  tableRowDataCy,
  isLoading = false,
  tableDataVariant,
  errorMessage,
  showNoDataMessage = true,
  collapsibleRows,
  collapseContent: CollapseContent,
  childItemsKey,
  hideDisabledActions,
}: Readonly<BilliantTableBodyProps<TData>>): ReactElement {
  const { isOpen, toggleCollapse } = useCollapseState(tableItems);

  const SHOW_DATA = !isLoading && tableItems.length > 0;
  const NO_DATA = showNoDataMessage && !isLoading && tableItems.length === 0;

  return (
    <TableBody data-cy="tableBody">
      {SHOW_DATA &&
        tableItems.map((row: TData, index: number) => {
          const isItemSelected = isSelected(row);
          const rowHasCollapse =
            collapsibleRows === true ||
            (collapsibleRows instanceof Function && collapsibleRows(row));
          const childItems = !!childItemsKey
            ? (row[childItemsKey] as TData[])
            : undefined;
          return (
            <Fragment key={generateRowKey(row, index)}>
              <BilliantTableRow
                key={generateRowKey(row, index)}
                index={index}
                row={row}
                isSelected={isItemSelected}
                handleSelect={handleSelect}
                headers={headers}
                isOpen={isOpen}
                setMenuState={setMenuState}
                tableActions={tableActions}
                hasActions={hasActions}
                toggleCollapse={toggleCollapse}
                collapsibleRows={collapsibleRows}
                enableSelection={enableSelection}
                rowHasCollapse={rowHasCollapse}
                selectionVariant={selectionVariant}
                generateRowKey={generateRowKey}
                hideDisabledActions={hideDisabledActions}
                data-cy={tableRowDataCy}
              />
              {rowHasCollapse &&
                childItems &&
                childItems.length > 0 &&
                isOpen(index) && (
                  <>
                    {childItems.map((childRow, childIndex) => (
                      <BilliantTableRow
                        key={generateRowKey(childRow, index)}
                        indent
                        index={childIndex}
                        row={childRow}
                        isSelected={isItemSelected}
                        handleSelect={handleSelect}
                        headers={headers}
                        isOpen={isOpen}
                        setMenuState={setMenuState}
                        tableActions={tableActions}
                        hasActions={hasActions}
                        toggleCollapse={toggleCollapse}
                        collapsibleRows={collapsibleRows}
                        enableSelection={enableSelection}
                        selectionVariant={selectionVariant}
                        generateRowKey={generateRowKey}
                        hideDisabledActions={hideDisabledActions}
                        data-cy={tableRowDataCy}
                      />
                    ))}
                  </>
                )}
              {rowHasCollapse && CollapseContent && (
                <TableRow>
                  <TableCell
                    sx={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={Number.MAX_SAFE_INTEGER}
                  >
                    <Collapse in={isOpen(index)} timeout="auto" unmountOnExit>
                      <CollapseContent {...row} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          );
        })}
      {isLoading && (
        <TableRowSkeletons
          numberOfRows={pageSize}
          headers={headers}
          enableSelection={enableSelection}
          hasActions={tableActions.length > 0}
        />
      )}
      {errorMessage ? (
        <NoTableData variant={"error"} title={errorMessage} />
      ) : (
        NO_DATA && <NoTableData variant={tableDataVariant} />
      )}
    </TableBody>
  );
}

interface TableRowSkeletonsProps<TData extends { [key: string]: any }> {
  /** Number of displayed skeleton table rows */
  numberOfRows: number;
  /** If selection is enabled */
  enableSelection: boolean;
  /** Table column definitions */
  headers: TableHeaderDefinition<TData>[];
  /** If table has actions */
  hasActions: boolean;
}

/** Table row skeletons when loading data */
function TableRowSkeletons<TData extends { [key: string]: any }>({
  numberOfRows,
  enableSelection,
  headers,
  hasActions,
}: TableRowSkeletonsProps<TData>) {
  return (
    <>
      {Array(numberOfRows)
        .fill(null)
        .map((_, index) => (
          <TableRow key={index}>
            {enableSelection && (
              <TableCell padding="checkbox">
                <TableSkeleton />
              </TableCell>
            )}
            {headers.map((h) => (
              <TableCell key={`${h.sortKey}_${index}`}>
                <TableSkeleton fullColumn />
              </TableCell>
            ))}
            {hasActions && (
              <TableCell align="left">
                <TableSkeleton variant="circular" />
              </TableCell>
            )}
          </TableRow>
        ))}
    </>
  );
}
