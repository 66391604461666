import {
  Box,
  BoxProps,
  SxProps,
  Table,
  TableContainer,
  TablePagination,
  Theme,
} from "common/components";
import { DefaultPageSizes, StorageKey } from "core/appSettings";
import { useAccessLevels } from "core/auth";
import {
  BilliantTableBody,
  BilliantTableBodyProps,
  MenuState,
  TableAction,
  TableHeader,
  TableHeaderDefinition,
  getComparator,
  tableSort,
  usePaginationState,
  useSelectState,
  useSortState,
} from "core/components";
import { useSortingSettings } from "core/hooks";
import { useTranslation } from "i18n";
import {
  CSSProperties,
  ComponentType,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableActionMenu } from "../TableActionMenu";

export type TableSelectionVariant = "SINGLE" | "MULTI";

/**
 * Properties to define a DBTable
 */
export interface BilliantTableProps<TData> extends BoxProps {
  /** @deprecated */
  label?: string;
  columns: TableHeaderDefinition<TData>[];
  /** Current table items that match all filters */
  tableItems: TData[];
  /** Key used for storing settings */
  tableSettingsKey: string;
  /** Default order by value */
  defaultOrderBy?: string;
  /** If default sort order is by ascending or descending */
  defaultAscending?: boolean;
  /** Actions available for popup menu */
  tableActions?: TableAction<TData>[];
  /** If disabled actions should be hidden */
  hideDisabledActions?: boolean;
  /** Boolean for disable/enable table row checkbox, default is false (=show) */
  disableSelection?: boolean;
  /** Table selection variant */
  selectionVariant?: TableSelectionVariant;
  /** Boolean for disable/enable table pagination, default is false (=show) */
  disablePagination?: boolean;
  /** Total count of table items */
  totalItemCount?: number;
  /**
   * String for adding your own data-cy name, default is "table_main"
   * useful when having many (widget) tables on the same page
   */
  tableDataCy?: string;
  /**
   * String for adding your own data-cy name on table rows, default is "table_row"
   * useful when having many (widget) tables on the same page
   */
  tableRowDataCy?: string;
  /** Maximum table height */
  tableHeight?: CSSProperties["maxHeight"];
  /** If table data is loading */
  isLoading?: boolean;
  /** Table data variant for no data available message */
  tableDataVariant?: BilliantTableBodyProps<TData>["tableDataVariant"];
  /** Error message to display in table */
  errorMessage?: string;
  /** If message for no data avilable should be visible */
  showNoDataMessage?: boolean;
  /** If rows have collapsed content */
  collapsibleRows?: boolean | ((item: TData) => boolean);
  /** Content inside Collapse */
  collapseContent?: ComponentType<TData>;
  /** Add predefined-styling for widgets */
  addWidgetStyle?: boolean;
  /** Key name for property containing child items */
  childItemsKey?: string;
  /**
   * Flag determining how tableItem data should be treated;
   * If true, will assume that given table items represent all available
   * data and will slice it into pages. Otherwise, assumes that given data
   * only represents the current page and will not manipulate it.
   */
  pageTableItems?: boolean;
  /**
   * List of page size options
   */
  pageSizes?: number[];
  /** Selected table items. Overrides internal state */
  selectedItems?: TData[];
  /** the name of the unique key prop */
  uniqueKey?: string | number;
  /** Called on selection changes */
  selectionCallback?(selectedItems: TData[]): void;
  /** Called on page changes */
  onPageChange?(page: number): void;
  /** Called on page size changes */
  onPageSizeChange?(pageSize: number): void;
  /** Called on sorting changes */
  onSortChange?(orderBy: string, order: "asc" | "desc"): void;
}

/**
 * Component that defines a table to display
 * database objects of type T
 * Includes options to filter results, pagination
 * and optionally a menu to perform actions on
 * selected items
 *
 * @param TableProps Properties required to build the table
 * @returns
 */
export function BilliantTable<TData extends { [key: string]: any }>({
  columns,
  tableItems,
  tableSettingsKey,
  defaultOrderBy,
  defaultAscending,
  tableActions = [],
  hideDisabledActions,
  tableDataCy = "tableMain",
  tableRowDataCy = "tableRow",
  pageSizes = DefaultPageSizes,
  totalItemCount,
  disableSelection = false,
  selectionVariant = "MULTI",
  disablePagination = false,
  tableHeight,
  isLoading,
  tableDataVariant,
  showNoDataMessage,
  errorMessage,
  collapsibleRows,
  collapseContent,
  addWidgetStyle = false,
  childItemsKey,
  pageTableItems,
  selectedItems: _selectedItems,
  uniqueKey,
  selectionCallback,
  onPageChange,
  onPageSizeChange,
  onSortChange,
  ...props
}: Readonly<BilliantTableProps<TData>>): ReactElement {
  const { sortingSettings, updateSettings } = useSortingSettings({
    key: tableSettingsKey,
    storageKey: StorageKey.TABLE_OPTION_SETTINGS,
    defaultOrderBy,
    defaultAscending,
  });

  const [menuState, setMenuState] = useState<MenuState<TData>>({
    menuAnchor: null,
    menuId: undefined,
    menuButtonId: undefined,
    selectedElement: undefined,
  });

  const {
    page,
    setPage,
    handleChangePage,
    pageSize,
    setPageSize,
    handleChangeRowsPerPage,
  } = usePaginationState({
    pageSizes,
    initPage: 0,
    initPageSize: sortingSettings.pageSize,
    pageSizeListener: (newPageSize: number) => {
      if (newPageSize !== sortingSettings.pageSize) {
        sortingSettings.pageSize = newPageSize;
        updateSettings(sortingSettings);
      }
    },
    onPageChange,
    onPageSizeChange,
  });

  const requestSortListener = useCallback(
    (newOrderBy: string, ascending: boolean) => {
      sortingSettings.orderBy = newOrderBy;
      sortingSettings.ascending = ascending;
      updateSettings(sortingSettings);
    },
    [updateSettings, sortingSettings]
  );

  const { order, setOrder, orderBy, setOrderBy, handleRequestSort } =
    useSortState({
      initOrderBy: sortingSettings.orderBy
        ? sortingSettings.orderBy
        : columns[0]?.sortKey,
      setPage,
      initOrder: sortingSettings.ascending ? "asc" : "desc",
      requestSortListener,
      onSortChange,
    });

  //Update settings when hook is loaded
  useEffect(() => {
    setPageSize(sortingSettings.pageSize);
    setOrder(sortingSettings.ascending ? "asc" : "desc");
    setOrderBy(sortingSettings.orderBy ?? "");
  }, [
    setOrder,
    setOrderBy,
    setPageSize,
    sortingSettings.ascending,
    sortingSettings.orderBy,
    sortingSettings.pageSize,
  ]);

  const currentPageItems = useMemo(() => {
    return pageTableItems
      ? tableSort(tableItems, getComparator(order, orderBy)).slice(
          page * pageSize,
          page * pageSize + pageSize
        )
      : tableItems;
  }, [order, orderBy, page, pageSize, tableItems, pageTableItems]);

  const { selectedItems, handleSelect, handleSelectAllClick, isSelected } =
    useSelectState(
      tableItems,
      selectionCallback,
      _selectedItems,
      uniqueKey,
      isLoading
    );

  const tableItemCount = currentPageItems.length;

  const generateRowKey = useCallback(
    (item: TData, rowIndex: number) => {
      const _uniqueKey = uniqueKey ? item[uniqueKey] : rowIndex;
      if (_uniqueKey === undefined) {
        console.error(
          `BSS Web Configuration error: Table items are missing property '${uniqueKey}'`
        );
      }
      return _uniqueKey;
    },
    [uniqueKey]
  );

  //Filter out all actions that the user does not have access to.
  //Handling this here prevents any menus from being rendered if the user
  //does not have access to any actions.
  const { hasAccess } = useAccessLevels(undefined);
  tableActions = useMemo(
    () =>
      tableActions.filter((action) =>
        action.requiredAccess ? hasAccess(action.requiredAccess) : true
      ),
    [tableActions, hasAccess]
  );
  const { t } = useTranslation(["common"]);
  const enablePagination = !disablePagination; // for readability
  const widgetStyle: SxProps<Theme> = addWidgetStyle
    ? {
        display: "grid",
        height: "100%",
        gridTemplateRows: enablePagination
          ? "minmax(0, 1fr) min-content"
          : "minmax(0, 1fr)",
      }
    : {};

  const hasActions: boolean = tableActions.length > 0;
  // If hideDisabledActions is true it filters out the disabled actions.
  const filteredTableActions = hideDisabledActions
    ? tableActions.filter((action) => !action.disabled)
    : tableActions;

  return (
    <Box
      component="article"
      data-cy={tableDataCy}
      {...props}
      sx={{ ...widgetStyle, ...props.sx }}
    >
      <TableContainer sx={{ maxHeight: tableHeight }}>
        <Table stickyHeader>
          <TableHeader
            numSelected={selectedItems.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={tableItems ? tableItems.length : 0}
            columns={columns}
            setMenuState={setMenuState}
            tableActions={filteredTableActions}
            hasActions={hasActions}
            enableSelection={!disableSelection}
            selectionVariant={selectionVariant}
            collapsibleRows={!!collapsibleRows}
            hideDisabledActions={hideDisabledActions}
          />
          <BilliantTableBody
            tableItems={currentPageItems}
            tableRowDataCy={tableRowDataCy}
            pageSize={pageSize}
            isSelected={isSelected}
            handleSelect={handleSelect}
            headers={columns}
            generateRowKey={generateRowKey}
            tableActions={filteredTableActions}
            hasActions={hasActions}
            setMenuState={setMenuState}
            enableSelection={!disableSelection}
            selectionVariant={selectionVariant}
            isLoading={isLoading}
            tableDataVariant={tableDataVariant}
            errorMessage={errorMessage}
            showNoDataMessage={showNoDataMessage}
            collapsibleRows={collapsibleRows}
            collapseContent={collapseContent}
            childItemsKey={childItemsKey}
            hideDisabledActions={hideDisabledActions}
          />
        </Table>
      </TableContainer>
      {enablePagination && (
        <TablePagination
          data-cy="pagination"
          component="div"
          labelRowsPerPage={t("common:rowsperpage")}
          rowsPerPageOptions={pageSizes}
          count={totalItemCount ?? tableItemCount ?? tableItems.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <TableActionMenu
        actions={filteredTableActions}
        menuState={menuState}
        setMenuState={setMenuState}
        selectedItems={selectedItems}
      />
    </Box>
  );
}
