import { API, APIQueryResult, useAPIQuery } from "core/api";
import { getCurrentCustomerGroupId } from "core/auth";

/**
 * Fetch screen url based on a screen tag.
 *
 * @param enabled Directive for useQuery.
 * @param screenTag screenTag to fetch with
 *
 * @returns
 */
export function useFetchScreenUrl(
  screenTag: string,
  enabled = true
): APIQueryResult<{ url: string }> {
  const currentCustomerGroup = getCurrentCustomerGroupId();
  const appConfigId = sessionStorage.getItem("currentApplicationConfigId");

  return useAPIQuery<{ url: string }>(
    ["fetchScreenUrl", screenTag, currentCustomerGroup, appConfigId],
    API.config.screenUrl,
    {
      pathParams: {
        tag: screenTag,
      },
    },
    { enabled, excludeCustomerGroup: true }
  );
}
