export default {
  nextInvoice: "Nästa faktura",
  latestInvoice: "Senaste fakturan",
  invoice: "Faktura",
  invoice_other: "Fakturor",
  status: "Status",
  statementType: "Typ",
  invoiceId: "Faktura-Id",
  creditNoteId: "Kreditfaktura-Id",
  name: "Namn",
  amount: "Belopp",
  invoiceDate: "Fakturadatum",
  creditNoteDate: "Kreditfaktura Datum",
  dueDate: "Förfallodatum",
  creditDate: "Kreditdatum",
  creditedBy: "Krediterad Av",
  creditNoteType: "Typ av Kreditfaktura",
  creditedInvoiceId: "Krediterad Fakturans Id",
  creditReason: "Grund för Kredit",
  paymentMethod: "Betalmetod",
  referenceNumber: "Referensnummer",
  address: "Adress",
  netAmount: "Nettobelopp",
  rounding: "Avrundning",
  taxAmount: "Moms",
  totalAmount: "Totalbelopp",
  totalAmountTaxIncluded: "Totalbelopp inkl. moms",
  totalAmountTaxExcluded: "Totalbelopp exkl. moms",
  downloadSuccess: "Faktura {{invoiceId}} nedladdad",
  noLatestInvoice: "Ingen faktura",
  noInvoices: "Inga fakturor",
  period: "Period",
  costsForUpcomingPeriod:
    "Kostnader för kommande period på nummer {{subscription}}",
  noCostsForUpcomingPeriod: "Inga kostnader för kommande period",
  changeDueDate: {
    dueDate: "Förfallodatum",
  },
  configureInvoiceDunning: {
    blockReminders: "Blockera Påminnelse",
    unblockReminders: "Avblockera Påminnelse",
    blockDebtCollection: "Blockera inkasso",
    unblockDebtCollection: "Avblockera inkasso",
  },
  newDueDate: "Förfallodatum",
  remainingAmount: "Kvarvarande Belopp",
  payInvoice: {
    paidAmount: "Betalt belopp",
    paymentDate: "Betalningsdatum",
    paymentReference: "Betalningsreferens",
    paymentType: "Betalningstyp",
  },
  creditInvoice: {
    date: "Kreditfaktura Datum",
    invoice: "Krediterad Faktura",
    credit: "Kreditera",
    totalInvoicedAmount: "Totalt fakturerat belopp (inkl. MOMS)",
    creditedAmount: "Totalt krediterat belopp (inkl. MOMS)",
    creditApprove: "Godkänn Kreditfaktura",
    creditReason: "Skäl till Kredit",
    negativeOrZeroCreditedAmountWarning:
      "Krediterat belopp får inte vara negativt eller noll.",
    remainingMaxAmountToCreditWarning:
      "Krediterat belopp får inte överstiga Maxbelopp att kreditera.",
    maxAmountToCredit: "Maximalt kreditbelopp (inkl. MOMS)",
    remainingAmount: "Kvarvarande Belopp (inkl. MOMS)",
    revertCredit: "Återställ Kredit",
    creditInvoiceRow: "Kreditfaktura rad",
    remainingAmountToCredit: "Kvarvarande belopp att kreditera",
    amountInclTax: "Belopp inklusive MOMS",
    amountExclTax: "Belopp exklusive MOMS",
  },
} as const;
