import { TextFieldProps } from "common/components";
import { ProductOfferingOption } from "core/api";
import { useTranslation } from "i18n";
import { ReactElement, useEffect } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";
import { OptionsCardField } from "./OptionsCardField";
import { SearchableOptionsCardField } from "./SearchableOptionsCardField";

export type FormProductOfferingOptionsProps = Omit<TextFieldProps, "variant"> &
  FormControlledFieldProps & {
    renderValue?: (value: any) => any;
    /** List with product offering options */
    options: ProductOfferingOption[];
    /** The selected index. */
    selectedIndex: number;
    /** Function to set the selected index. */
    setSelectedIndex: (index: number) => void;
    /** If search and pagination should be enabled. */
    withSearch: boolean;
  };

/**
 * Adds form handling for Product Offering Options field.
 * Displays two different variants. One with search and pagination, one without.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param props Any remaining properties.
 *
 */
export const FormProductOfferingOptions =
  withFormController<FormProductOfferingOptionsProps>(
    ({
      fieldName,
      renderValue,
      renderState,
      setSelectedIndex,
      options,
      selectedIndex,
      withSearch,
      ...props
    }): ReactElement => {
      const { t } = useTranslation(["common"]);
      const {
        field: { value, onChange, ...field },
        fieldState: { error },
        setExtendedRules,
      } = renderState;

      /**
       * Triggers the fields onChange when the value changes
       * Needed for the validation to get the correct value
       */
      useEffect(() => {
        onChange(value);
      }, [value]);

      useEffect(() => {
        setExtendedRules({
          validate: {
            notEmptyRequiredValue: (value: string) => {
              if (props.required && value === "-1") {
                return false;
              }
              return true;
            },
          },
        });
      }, [setExtendedRules, t]);

      return (
        <>
          {withSearch ? (
            <SearchableOptionsCardField
              control={props.control}
              options={options}
              {...props}
              error={!!error}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              {...field}
            />
          ) : (
            <OptionsCardField
              options={options}
              {...props}
              error={!!error}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              {...field}
            />
          )}
        </>
      );
    }
  );
