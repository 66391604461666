import { formatFormDatePickerValue } from "common/components";
import { convertBooleanToText } from "common/utils";
import {
  EntityRef,
  FieldKey,
  FieldsFlowData,
  FieldsFlowStep,
  FieldValue,
  FieldValueConfig,
  FlowDataField,
  getFieldConfigQueryData,
  getValidValuesQueryData,
  TranslatedText,
} from "core/api";
import { getOptionalFieldName } from "core/forms";
import { getI18n } from "i18n";
import { List } from "lodash";
import { FieldValues } from "react-hook-form";
import { StepDataResolver } from "../models";

/**
 * Fields data resolver
 *
 * Formats fields based on form field values
 */
export const fieldsDataResolver: StepDataResolver<
  FieldsFlowStep,
  FieldsFlowData
> = ({ data, fieldValues }) => {
  return {
    fields: data.fields.map((field) => ({
      ...field,
      values: createFieldValues(data.entityRef, fieldValues, field),
    })),
  };
};

/**
 * Fetches data from the translationFields
 * @param formData Field values from form
 * @param field Field key
 * @param fieldKey Name of the field (Same as field for default)
 * @returns
 */
function fetchTranslationFields(
  formData: FieldValues,
  field: FlowDataField,
  fieldKey: FieldKey
): List<TranslatedText> {
  let result: List<TranslatedText> = [];
  if (field.values[0].translations) {
    const translations = field.values[0].translations;
    let key = "";
    for (let i = 0; i < translations.length; i++) {
      key = fieldKey.key + fieldKey.variant + "_" + translations[i].language;
      translations[i].text =
        formData[key] === undefined ? translations[i].text : formData[key];
    }
    result = translations;
  }
  return result;
}

/**
 * Creates field values from form data
 * @param entityRef Entity reference
 * @param formData Field values from form
 * @param field Field key
 * @param values Field values from flow data
 * @returns
 */
function createFieldValues(
  entityRef: EntityRef,
  formData: FieldValues,
  field: FlowDataField
): FieldValue[] {
  const { entityType, entityTypeId } = entityRef;
  const { key: fieldKey, values } = field;
  const fieldConfigs = getFieldConfigQueryData(entityType, entityTypeId);
  const config =
    field.config ??
    fieldConfigs?.find(
      ({ key }) => key.key === fieldKey.key && key.variant === fieldKey.variant
    );
  const formValue = formData[fieldKey.key + fieldKey.variant]; // This should find all relevant formdata

  if (!config || formValue === undefined) {
    return values;
  }

  switch (config.uiComponent) {
    case "Toggle":
      return values.map((value) => ({
        ...value,
        value: formValue ? "true" : "false",
        displayValue: convertBooleanToText(formValue),
      }));
    case "Select":
    case "NetworkElement": {
      const selectValues = getValidValues(config.values[0]);
      if (!selectValues) {
        return values;
      }
      return values.map((value) => ({
        ...value,
        displayValue:
          selectValues?.find(({ id }) => id === formValue)?.name ??
          getNoValueSelectedLabel(),
        value: formValue === null ? "-1" : formValue ?? value.value,
      }));
    }
    case "List": {
      const listValues = getValidValues(config.values[0]);
      if (!listValues) {
        return values;
      }
      return Array.isArray(formValue)
        ? formValue.map((value) => ({
            displayValue:
              listValues.find(({ id }) => id === value)?.name ??
              getNoValueSelectedLabel(),
            value,
            valueNo: 0,
          }))
        : [];
    }
    case "OptionalSelect":
    case "OptionalText":
    case "OptionalDate": {
      const includeOptionalValue = formData[
        getOptionalFieldName(fieldKey.key + fieldKey.variant)
      ]
        ? "true"
        : "false";
      const optionalSelectValues = getValidValues(
        config.values.find(({ valueNo }) => valueNo === 0)
      );
      const getOptionalFormValue = (value: string) => {
        if (config.uiComponent === "OptionalDate") {
          return formValue ? formatFormDatePickerValue(formValue) : value;
        }
        return formValue ?? value;
      };
      return values.map((value) => ({
        ...value,
        displayValue: Array.isArray(optionalSelectValues)
          ? optionalSelectValues.find(({ id }) => id === formValue)?.name ??
            getNoValueSelectedLabel()
          : getOptionalFormValue(value.displayValue),
        value:
          value.valueNo === 0
            ? getOptionalFormValue(value.value || "")
            : includeOptionalValue,
      }));
    }
    case "DateTime":
    case "Date":
    case "Time": {
      const timestamp = formValue
        ? formatFormDatePickerValue(formValue)
        : undefined;
      return values.map((value) => ({
        ...value,
        displayValue: timestamp ?? value.displayValue,
        value: timestamp ?? value.value,
      }));
    }

    case "TranslatableText":
    case "TranslatableTextArea":
      return values.map((value) => ({
        ...value,
        displayValue: formValue ?? value.displayValue,
        value: formValue ?? value.value,
        translations: fetchTranslationFields(formData, field, fieldKey),
      }));
    case "IconSelector":
      return values.map((value) => ({
        ...value,
        displayValue: formValue ?? value.displayValue,
        value: formValue ?? value.value,
      }));
    default:
      return values.map((value) => ({
        ...value,
        displayValue: formValue ?? value.displayValue,
        value: formValue ?? value.value,
      }));
  }
}

function getNoValueSelectedLabel() {
  return getI18n().t("common:noValueSelected");
}

function getValidValues(valueConfig: FieldValueConfig | undefined) {
  return (
    valueConfig?.discreteValues ??
    getValidValuesQueryData(valueConfig?.valuesURL as string)
  );
}
