import { FormProductOfferingOptions } from "common/components";
import { ReactElement, useEffect, useState } from "react";

import { addFlowStepPrefix } from "common/utils";
import {
  EntityType,
  SelectProductFlowData,
  SelectProductFlowStep,
  useFetchAllProductOfferingOptions,
} from "core/api";
import { LoadingView } from "../../../../components";
import { useScreenEntities } from "../../../../store";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type SelectProductProps = FlowStepComponentProps<
  SelectProductFlowStep,
  SelectProductFlowData
>;

/**
 * Select Product step component
 *
 * Displays a list of product offering card
 */
export function SelectProduct({
  control,
  data,
  flowStep,
}: SelectProductProps): ReactElement {
  const { intSelectedIndex } = data;

  const [indexValue, setIndexValue] = useState(intSelectedIndex);
  const screenEntities = useScreenEntities();
  const entityData =
    screenEntities[EntityType.INVOICE_ACCOUNT]?.entityData ??
    screenEntities[EntityType.CUSTOMER]?.entityData;

  const { data: productOfferingsOptionsContainer, isFetching } =
    useFetchAllProductOfferingOptions(entityData?.entityId ?? "-1");

  const allProductOfferingsOptions =
    (productOfferingsOptionsContainer as any)?.items ?? [];

  // sets data.productOfferingOptions = allProductOfferingsOptions. This is needed as we use the index in other places to get the selected product offering
  useEffect(() => {
    if (
      allProductOfferingsOptions.length > 0 &&
      data.productOfferingOptions !== allProductOfferingsOptions
    ) {
      data.productOfferingOptions = allProductOfferingsOptions;
    }
  }, [allProductOfferingsOptions, data]);

  /**
   * Updates the index value.
   *
   * @param newIndex - The new index to set.
   */
  const handleIndexChange = (newIndex: number) => {
    setIndexValue(newIndex);
  };

  return (
    <StyledStepContentWrapper>
      {isFetching ? (
        <LoadingView />
      ) : (
        <FormProductOfferingOptions
          control={control}
          fieldName={addFlowStepPrefix(flowStep, "selectedProductOffering")}
          options={allProductOfferingsOptions}
          defaultValue={
            allProductOfferingsOptions?.length > 0
              ? indexValue.toString()
              : undefined
          }
          selectedIndex={indexValue}
          setSelectedIndex={handleIndexChange}
          required
          withSearch
        />
      )}
    </StyledStepContentWrapper>
  );
}
