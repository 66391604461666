import { FormSelect, FormTextField } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { EditCustomerNoteFlowData, EditCustomerNoteFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type CustomerNoteProps = FlowStepComponentProps<
  EditCustomerNoteFlowStep,
  EditCustomerNoteFlowData
>;

/** edit customer note step component to add a customer note. */
export function EditCustomerNote({
  control,
  data,
  flowStep,
}: CustomerNoteProps): ReactElement {
  const { noteText, status, allStatuses } = data;
  const { t } = useTranslation(["common"]);

  return (
    <StyledStepContentWrapper sx={{ display: "flex", flexDirection: "column" }}>
      <FormTextField
        fieldName={addFlowStepPrefix(flowStep, "noteText")}
        defaultValue={noteText}
        control={control}
        label={t("common:editCustomerNote.noteText")}
        data-cy="noteText"
        multiline
        rows={5}
      />
      <FormSelect
        fieldName={addFlowStepPrefix(flowStep, "status")}
        control={control}
        label={t("common:editCustomerNote.status")}
        required
        defaultValue={status.id}
        validValues={allStatuses ?? []}
        data-cy="friendlyStatus"
      />
    </StyledStepContentWrapper>
  );
}
