import { ListItem, ListItemText } from "common/components";
import { formatCurrency } from "common/utils";
import { FlowData, FlowStep, MatchValue } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface ProductOfferingCardsConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * ProductOfferingCardsConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "UpgradeDowngrade" or "SelectProduct" .
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 *
 * @returns A JSX element with product offering details from the previous "UpgradeDowngrade" or "SelectProduct" step in the flow.
 */
export function ProductOfferingCardsConfirmation({
  step,
  flowData,
}: ProductOfferingCardsConfirmationProps): JSX.Element {
  const { t } = useTranslation(["common", "product"]);

  const fieldFlowData =
    flowData.uiComponent === "UpgradeDowngrade" ||
    flowData.uiComponent === "SelectProduct"
      ? flowData
      : undefined;

  const selectedIndex = fieldFlowData?.intSelectedIndex ?? -1;
  const productOfferingOption =
    selectedIndex >= 0
      ? fieldFlowData?.productOfferingOptions[selectedIndex]
      : undefined;

  const matchValuesText = productOfferingOption?.matchValues
    .map((item: MatchValue) => `${item.displayName} ${item.displayValue}.`)
    .join(" ");
  let decisionText = productOfferingOption?.contractTermPeriodText || "";

  if (matchValuesText) {
    decisionText += `. ${matchValuesText}`;
  }

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("product:productOffering")}
          secondary={productOfferingOption?.rule?.name}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("common:description")}
          secondary={productOfferingOption?.rule?.description}
        />
      </ListItem>
      <ListItem>
        <ListItemText primary={t("common:decision")} secondary={decisionText} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t("product:totalPriceInclTax")}
          secondary={formatCurrency(
            productOfferingOption?.totalPrice.amountInclTax ?? 0,
            productOfferingOption?.currencyCode ?? ""
          )}
        />
      </ListItem>
    </Fragment>
  );
}
