import { API, APIQueryResult, useAPIQuery } from "core/api";

/**
 * Find all Material icon names possible to use for screen/view/widget/action/flows config.
 *
 * @param enabled Directive for useQuery.
 *
 * @returns
 */
export function useFetchIcons(enabled = true): APIQueryResult<string[]> {
  return useAPIQuery(["fetchIcons"], API.config.icons, {}, { enabled });
}
