import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "common/components";
import { ConfirmationDialogMessage } from "core/message/models";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { StyledDialogTitle } from "./dialogs.styles";

export interface ConfirmationDialogProps {
  message: ConfirmationDialogMessage;
  open: boolean;
  onClose: () => void;
}

export function ConfirmationDialog({
  message,
  open,
  onClose,
}: ConfirmationDialogProps): ReactElement {
  const { t } = useTranslation("common");

  return (
    <Dialog open={open} maxWidth="lg" onClose={onClose}>
      <StyledDialogTitle>{message.title}</StyledDialogTitle>
      <DialogContent>{message.message}</DialogContent>
      <DialogActions>
        <Button
          data-cy="confirmDialogOk"
          onClick={() => {
            message.onOk();
            onClose();
          }}
        >
          {message.okButtonText ?? t("buttons.ok")}
        </Button>
        <Button onClick={onClose}>
          {message.cancelButtonText ?? t("buttons.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
