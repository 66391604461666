/**
 * The status of an order can have the values specified by this enum.
 *
 * This list should match the Code Object OrderStatus:
 * SELECT * FROM SYS_CODES WHERE Code_Class_Name LIKE '%OrderStatus%'
 */
export enum OrderStatus {
  /** Registered */
  REGISTERED = "REGISTERED",
  /** Approved */
  APPROVED = "APPROVED",
  /** Completed */
  COMPLETED = "COMPLETED",
  /** Failed */
  FAILED = "FAILED",
  /** Pending */
  PENDING = "PENDING",
  /** Provisioning Pending */
  PROVISIONING_PENDING = "PROVISIONING_PENDING",
  /** Provisioning */
  PROVISIONING = "PROVISIONING",
  /** Provisioning ready */
  PROVISIONING_READY = "PROVISIONING_READY",
  /** Status set on an order when provisioning has failed */
  PROVISIONING_FAILED = "PROVISIONING_FAILED",
  /** Order has been canceled */
  CANCELED = "CANCELED",
  /** Prerequisites Pending */
  PREREQUISITES_PENDING = "PREREQUISITES_PENDING",
  /** Wait for Prerequisite(s) */
  WAIT_FOR_PREREQUISITES = "WAIT_FOR_PREREQUISITES",
  /** Prerequisites Ready */
  PREREQUISITES_READY = "PREREQUISITES_READY",
  /** Prerequisites Failed */
  PREREQUISITES_FAILED = "PREREQUISITES_FAILED",
}
