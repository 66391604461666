import { StepperDirection } from "core/actions/flows";
import {
  API,
  CreateFlowParams,
  Flow,
  StepEvaluationReason,
  useAPIMutation,
  useAPIQuery,
} from "core/api";
import { setActionFlowQueryData } from "./useFetchActionFlow";

export interface EvaluateActionFlowSettings {
  actionId: number;
  currentStepId: string | undefined;
  currentSubStepId: string | undefined;
  direction: StepperDirection;
  evalOnEnter: boolean;
  flow: Flow | undefined;
  values: Record<string, any>;
  params: CreateFlowParams;
}

export function useEvaluateActionFlow({
  actionId,
  currentStepId,
  currentSubStepId,
  direction,
  evalOnEnter,
  flow,
  values,
  params,
}: EvaluateActionFlowSettings) {
  const queryResult = useAPIQuery<Flow>(
    ["evaluateFlow", actionId, currentStepId, currentSubStepId, direction],
    API.actions.flows.evaluate,
    {
      method: "PUT",
      pathParams: { actionId, reason: "ENTER" },
      body: JSON.stringify(flow),
    },
    {
      enabled:
        !!currentStepId &&
        evalOnEnter &&
        direction !== "back" &&
        flow !== undefined &&
        Object.values(values).length > 0,
      onSuccess: getOnSuccess(actionId, params),
      refetchOnMount: "always",
    }
  );
  const mutationResult = useAPIMutation<
    Flow,
    { reason: StepEvaluationReason; data: Flow }
  >(
    API.actions.flows.evaluate,
    ({ reason, data }) => ({
      method: "PUT",
      body: JSON.stringify(data),
      pathParams: { reason, actionId },
    }),
    {
      onSuccess: getOnSuccess(actionId, params),
    }
  );

  return {
    mutationResult,
    queryResult,
    mutate: mutationResult.mutate,
    isLoading: queryResult.isLoading || mutationResult.isLoading,
  };
}

function getOnSuccess(actionId: number, params: CreateFlowParams) {
  return (data: Flow) => {
    const { entityType, entityId } = data.context.parent;
    setActionFlowQueryData({ actionId, entityType, entityId, params }, data);
  };
}
