import { FontIcon, getFontIconColor } from "common/assets/icons";
import {
  Box,
  IconProps,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SxProps,
  Theme,
  Tooltip,
} from "common/components";
import { IconVariant } from "core/api";
import { ReactElement, Suspense } from "react";
interface StatusIconProps extends IconProps {
  /** Icon name */
  iconName: string;
  /** Icon variant */
  iconVariant: IconVariant;
  /** Optional Status description for tooltip */
  description?: string;
  /** Optional display value for tooltip */
  displayValue?: string;
  /** If showing a label */
  withLabel?: boolean;
  /** Icon size */
  size?: "small" | "medium" | "large";
  /** Style props */
  sx?: SxProps<Theme>;
  /** If show tooltip */
  tooltip?: boolean;
}

/**
 * Returns a status icon based on Icon name and variant.
 * @param iconName The icon name
 * @param iconVariant The icon variant
 * @param withLabel Display label
 * @param description The description
 * @param size The size, Defaults to small
 * @param sx Styling
 * @param tooltip Turn tooltip on/off. Default to on
 *
 * @returns The ReactElement
 */
export function StatusIcon({
  iconName,
  iconVariant,
  withLabel,
  description,
  displayValue,
  size = "small",
  sx,
  tooltip = true,
  ...props
}: StatusIconProps): ReactElement {
  const fontIcon = (
    <FontIcon
      icon={iconName}
      sx={{ color: getFontIconColor(iconVariant) }}
      fontSize={size}
      {...props}
    />
  );
  const skeletonSize = 18;

  return (
    <Suspense
      fallback={
        <Tooltip title={description ?? ""}>
          <Skeleton
            variant="circular"
            width={skeletonSize}
            height={skeletonSize}
          />
        </Tooltip>
      }
    >
      {displayValue || description ? (
        <Tooltip
          title={
            tooltip ? (
              <Box sx={{ whiteSpace: "pre-line" }}>
                {displayValue && `${displayValue}\n`}
                {description}
              </Box>
            ) : (
              ""
            )
          }
        >
          <Box
            display="grid"
            gridAutoFlow="column"
            alignContent="center"
            justifyContent="start"
            sx={sx}
          >
            <ListItemIcon
              sx={{
                display: "grid",
                placeContent: "center",
                width: "min-content",
              }}
            >
              {fontIcon}
            </ListItemIcon>
            {withLabel && (
              <ListItemText
                primary={description}
                disableTypography
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              />
            )}
          </Box>
        </Tooltip>
      ) : (
        <Box sx={sx}>{fontIcon}</Box>
      )}
    </Suspense>
  );
}
