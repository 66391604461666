import { Settings } from "common/assets/icons";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
} from "common/components";
import { ChangeEvent, MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface GridConfigMenuProps {
  isDraggable: boolean;
  setDraggable: (checked: boolean) => void;
}

export function GridConfigMenu({
  isDraggable,
  setDraggable,
}: GridConfigMenuProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation(["common"]);

  const open = Boolean(anchorEl);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const label = t("common:settings");
  const menuLabel = t("common:settingsMenu.settingsMenu");

  const onDraggableChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDraggable(event.target.checked);
  };

  return (
    <>
      <Tooltip title={label}>
        <div>
          <IconButton
            aria-controls={menuLabel}
            aria-haspopup="true"
            onClick={handleMenu}
            size="small"
            data-cy="settingsIconButton"
          >
            <Settings fontSize="small" data-active={true} aria-label={label} />
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        id="grid-config"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <ListSubheader>{label}</ListSubheader>
        <Divider />
        <MenuItem data-cy="gridConfigAllowDrag">
          <FormControlLabel
            control={
              <Checkbox checked={isDraggable} onChange={onDraggableChange} />
            }
            label={t("common:settingsMenu.allowDnD")}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
