/**
 * Enum defining the Notification Period Type used in the Product Catalog.
 *
 * Corresponds to the java class com.billiant.billiant.util.code.ProductCatalogNotificationPeriodType
 * which is a BilliantCodeObject
 */
export enum ProductCatalogNotificationPeriodType {
  APPLCABLE_DURING_CONTRACT_TERM_PERIOD = "APPLCABLE_DURING_CONTRACT_TERM_PERIOD",
  APPLCABLE_AFTER_CONTRACT_TERM_PERIOD = "APPLCABLE_AFTER_CONTRACT_TERM_PERIOD",
}
