import { ActionParams } from "core/actions/flows";
import {
  ActionButtonsConfig,
  ActionConfig,
  DashboardWidget,
  EntityData,
  EntityType,
  ViewProps,
  ViewType,
} from "core/api";
import { WebSetupToolbar } from "core/components";
import { ScreenEntities } from "core/store";
import {
  LazyExoticComponent,
  ReactElement,
  Suspense,
  createContext,
  useContext,
} from "react";
import { LayoutContextProvider } from "../LayoutContext";
import { LoadingView } from "./LoadingView";

export interface ViewContextState {
  /** View title */
  title: string | undefined;
  /** View id */
  viewId: number;
  /** Type details for view */
  viewType: ViewType;
  /** Actions config */
  actionsConfig: ActionButtonsConfig | undefined;
  /** Screen entity */
  screenEntity: EntityData | undefined;
  /** Screen entity type */
  screenEntityType: EntityType | undefined;
  /** screen entity id */
  screenEntityId: string | undefined;
  /** Screen entities */
  screenEntities: ScreenEntities;
  /** Widgets assigned to view */
  widgets: DashboardWidget[] | undefined;
}

export const ViewContext = createContext<ViewContextState | null>(null);

/** Use view context state */
export function useViewContext(ignoreError?: boolean) {
  const context = useContext(ViewContext);
  if (!context && !ignoreError) {
    console.error("ViewContext needs to be used within a Provider");
  }
  return (context as ViewContextState) ?? {};
}

export interface DynamicViewProps extends ViewContextState {
  /**
   * Configuration props for a view. Needs to be in a
   * JSON serializable format to be compatible with
   * API config data.
   */
  viewProps: ViewProps | null;
  importView(viewType: ViewType): LazyExoticComponent<any>;
  triggerAction?: (
    config: ActionConfig<any>,
    params?: ActionParams | undefined
  ) => void;
}

/**
 * Render lazily imported view component
 * @param DynamicViewProps
 * @returns View component
 */
export function DynamicView({
  viewId,
  title,
  viewType,
  viewProps,
  actionsConfig,
  screenEntity,
  screenEntityType,
  screenEntityId,
  screenEntities,
  widgets,
  importView,
  triggerAction,
}: DynamicViewProps): ReactElement {
  const View = importView(viewType);

  return (
    <Suspense fallback={<LoadingView />}>
      <ViewContext.Provider
        value={{
          title,
          viewId,
          viewType,
          actionsConfig,
          screenEntity,
          screenEntityType,
          screenEntityId,
          screenEntities,
          widgets,
        }}
      >
        <WebSetupToolbar
          entityType={EntityType.WEB_VIEW}
          entityId={viewId}
          triggerAction={triggerAction}
        />

        <LayoutContextProvider
          layoutId={`view_${viewId}`}
          collapsible={viewProps?.collapsible}
          hideDisabledActions={viewProps?.hideDisabledActions}
          showTitle={viewProps?.showTitle}
        >
          <View title={viewProps?.showTitle ? title : ""} {...viewProps} />
        </LayoutContextProvider>
      </ViewContext.Provider>
    </Suspense>
  );
}
