export default {
  nextInvoice: "Próxima factura",
  latestInvoice: "Última factura",
  invoice: "Factura",
  invoice_other: "Facturas",
  status: "Estado",
  statementType: "Tipo de declaración",
  invoiceId: "Id de factura",
  creditNoteId: "Id de nota de crédito",
  name: "Nombre",
  amount: "Monto",
  invoiceDate: "Fecha de factura",
  creditNoteDate: "Fecha de nota de crédito",
  dueDate: "Fecha de vencimiento",
  creditDate: "Fecha de crédito",
  creditedBy: "Acreditado por",
  creditNoteType: "Tipo de nota de crédito",
  creditedInvoiceId: "Id de factura acreditada",
  creditReason: "Razón para el crédito",
  paymentMethod: "Método de pago",
  referenceNumber: "Número de referencia",
  netAmount: "Monto neto",
  rounding: "Redondeo",
  taxAmount: "Impuesto",
  totalAmount: "Monto total",
  address: "Dirección",
  totalAmountTaxIncluded: "Monto total incl. IVA",
  totalAmountTaxExcluded: "Monto total excl. IVA",
  downloadSuccess: "Factura {{invoiceId}} descargada",
  noLatestInvoice: "Sin factura",
  noInvoices: "Sin facturas",
  period: "Período",
  costsForUpcomingPeriod:
    "Costos para el próximo período para el número {{subscription}}",
  noCostsForUpcomingPeriod: "Sin costos para el próximo período",
  changeDueDate: {
    dueDate: "Nueva fecha de vencimiento",
  },

  newDueDate: "Nueva fecha de vencimiento",
  configureInvoiceDunning: {
    blockReminders: "Bloquear recordatorios",
    unblockReminders: "Desbloquear recordatorios",
    blockDebtCollection: "Bloquear cobro de deudas",
    unblockDebtCollection: "Desbloquear cobro de deudas",
  },
  remainingAmount: "Monto restante",
  payInvoice: {
    paidAmount: "Monto pagado",
    paymentDate: "Fecha de pago",
    paymentReference: "Referencia de pago",
    paymentType: "Tipo de pago",
  },
  creditInvoice: {
    date: "Fecha de nota de crédito",
    invoice: "Factura acreditada",
    credit: "Crédito",
    totalInvoicedAmount: "Monto total facturado (incl. impuesto)",
    creditedAmount: "Monto total acreditado (incl. impuesto)",
    creditApprove: "Aprobar nota de crédito",
    creditReason: "Razón para el crédito",
    negativeOrZeroCreditedAmountWarning:
      "El monto acreditado no puede ser negativo o cero.",
    remainingMaxAmountToCreditWarning:
      "El monto acreditado no puede exceder el monto máximo a acreditar.",
    maxAmountToCredit: "Monto máximo a acreditar (incl. impuesto)",
    remainingAmount: "Monto restante (incl. impuesto)",
    revertCredit: "Revertir crédito",
    creditInvoiceRow: "Línea de factura de crédito",
    remainingAmountToCredit: "Monto restante a acreditar",
    amountInclTax: "Monto incluyendo impuesto",
    amountExclTax: "Monto excluyendo impuesto",
  },
} as const;
