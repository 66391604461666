import { FormCheckBox, FormDatePicker, FormSelect } from "common/components";
import { addFlowStepPrefix, formatDate } from "common/utils";
import {
  AdjustmentPropertiesFlowData,
  AdjustmentPropertiesFlowStep,
} from "core/api";
import { FormAutocomplete } from "core/forms";
import { useTranslation } from "i18n";
import { useFormContext } from "react-hook-form";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type AdjustmentPropertiesProps = FlowStepComponentProps<
  AdjustmentPropertiesFlowStep,
  AdjustmentPropertiesFlowData
>;

/**
 * This UI component allows the user to input the initial data that is required
 * to create an Adjustment. There are some non-trivial dependencies between
 * input fields that cannot be implemented using a generic Fields component.
 * The component allows the user to select a Price Type, select or input
 * a description, enable/disable immediate invoicing and select or pick
 * an invoice date.
 *
 * @remarks
 * The component expect the following properties to be present in the Flow data:
 *
 * - priceTypes (ValidValue[]): Values for the combo box that allows the user
 *   to select a price type
 *
 * - priceTypeDescriptionsMap (Record<number, string[]>): Map that contains
 *   predefined descriptions corresponding to every possible price type.
 *
 * - immediateInvoicingEnabled (boolean): Value for/of the 'enable immediate
 *   invoicing' check box
 *
 * - futureInvoiceDates (string[]): Values for the combo box that allows the
 *   user to select the earliest invoice date.
 *
 * - earliestInvoiceDate (string): Default value for the earliest invoice date
 *   (when immediateInvoicingEnabled = false).
 *
 * - immediateInvoiceDate (string): Default value for the earliest invoice date
 *   (when immediateInvoicingEnabled = true).
 *
 * All date are expected to be in a long ISO format (2023-06-01T00:00:00.000+02:00):
 * This is the format that the JSON serializer will use by default when
 * serializing a Calendar object.
 *
 * The other properties defined in AdjustmentPropertiesFlowData are used to
 * return values to the server, or to provide values to other steps in the flow.
 *
 * @see {@link AdjustmentPropertiesFlowData}
 */
export function AdjustmentProperties({
  control,
  data,
  flowStep,
}: AdjustmentPropertiesProps): JSX.Element {
  const { t } = useTranslation(["common"]);

  const priceTypeOptions = data.priceTypes.map((pt) => {
    return {
      id: pt.id,
      name: pt.name,
      description: "",
    };
  });

  const context = useFormContext();
  const selectedPriceType: string = context?.watch(
    addFlowStepPrefix(flowStep, "chargeType"),
    data.priceTypeId
  );
  const descriptionOptions = selectedPriceType
    ? data.priceTypeDescriptionsMap[parseInt(selectedPriceType)].map(
        (adjDesc) => {
          return {
            value: adjDesc,
            label: adjDesc,
          };
        }
      )
    : [];
  const immediateInvoiceEnabled: boolean = context?.watch(
    addFlowStepPrefix(flowStep, "enableImmediateInvoicing"),
    data.immediateInvoicingEnabled
  );
  const invoiceDateOptions = data.futureInvoiceDates.map((strDate) => {
    return {
      id: strDate, // Invoice date in ISO format
      name: formatDate(strDate), // Invoice date formatted according to user preferences
      description: "",
    };
  });
  return (
    <StyledStepContentWrapper>
      {/* Combo box to select the Charge Type */}
      <FormSelect
        validValues={priceTypeOptions}
        defaultValue={data.priceTypeId}
        includeEmptyOption={false}
        fieldName={addFlowStepPrefix(flowStep, "chargeType")}
        required
        control={control}
        label={t("common:chargeType")}
        // data-cy is set to the value of the label property, without spaces
      />
      {/* Autocomplete in Free Solo mode to select or input the Adjustment Description */}
      <FormAutocomplete
        freeSolo
        dataList={descriptionOptions}
        defaultValue={data.adjustmentDescription}
        fieldName={addFlowStepPrefix(flowStep, "adjustmentDescription")}
        control={control}
        label={t("common:adjustmentDescription")}
        fullWidth
        helperText={t("common:adjustmentDescriptionHelper")}
        required
        data-cy={"inputAdjustmentDescription"}
      />
      {/* Check box to enable/disable immediate invoicing */}
      <FormCheckBox
        control={control}
        fieldName={addFlowStepPrefix(flowStep, "enableImmediateInvoicing")}
        // data-cy is set to same value as the fieldName property
        label={t("common:enableImmediateInvoicing")}
        defaultValue={data.immediateInvoicingEnabled}
      />
      {/* Date Picker to select Earliest Invoice Date (used if enableImmediateInvoicing is true)
       * or Combo box to select Earliest Invoice Date (used if enableImmediateInvoicing is false) */}
      {immediateInvoiceEnabled ? (
        <FormDatePicker
          defaultValue={data.immediateInvoiceDate}
          fieldName={addFlowStepPrefix(flowStep, "immediateInvoiceDate")}
          required
          inputFormat="L"
          control={control}
          label={t("common:earliestInvoiceDate")}
          disabled={!immediateInvoiceEnabled}
          data-cy={"earliestInvoiceDate"}
        />
      ) : (
        <FormSelect
          validValues={invoiceDateOptions}
          defaultValue={data.earliestInvoiceDate}
          includeEmptyOption={false}
          fieldName={addFlowStepPrefix(flowStep, "earliestInvoiceDate")}
          required
          control={control}
          label={t("common:earliestInvoiceDate")}
          // data-cy is set to the value of the label property, without spaces
          disabled={immediateInvoiceEnabled}
        />
      )}
    </StyledStepContentWrapper>
  );
}
