export enum CodeObject {
  AddressStatus = "AddressStatus",
  AvailabilityStatus = "AvailabilityStatus",
  AddressTarget = "AddressTarget",
  CalendarUnit = "CalendarUnit",
  CreditRatingStatus = "CreditRatingStatus",
  CustomerStatus = "CustomerStatus",
  DebtCollectionStatus = "DebtCollectionStatus",
  DocumentStatus = "DocumentStatus",
  EntityType = "EntityType",
  HistoryEntryType = "HistoryEntryType",
  InvoicePaymentStatus = "InvoicePaymentStatus",
  InvoiceStatus = "InvoiceStatus",
  NoteStatus = "NoteStatus",
  NpNumberTypeCode = "NpNumberTypeCode",
  PaymentMatchOption = "PaymentMatchOption",
  PaymentMatchDetailType = "PaymentMatchDetailType",
  PaymentMethod = "PaymentMethod",
  PaymentStatus = "PaymentStatus",
  PrinterQueueStatus = "PrinterQueueStatus",
  ProductCatalogDateAlignmentType = "ProductCatalogDateAlignmentType",
  ProductCatalogNotificationPeriodType = "ProductCatalogNotificationPeriodType",
  ProductCatalogPeriodType = "ProductCatalogPeriodType",
  ProductLifecycleType = "ProductLifecycleType",
  StatementType = "StatementType",
  SubscriptionStatus = "SubscriptionStatus",
  TaskStatus = "TaskStatus",
  TaskListStatus = "TaskListStatus",
  UserRole = "UserRole",
  UserStatus = "UserStatus",
}
