import { convertRecentlyViewed } from "common/utils";
import {
  BookmarkEntryType,
  EntityType,
  useClearBookmarks,
  useFindBookmarks,
  useUpdateBookmarks,
} from "core/api";
import { Bookmark } from "core/api/models/Bookmark";
import { getCurrentCustomerGroupId, useAuthenticationContext } from "core/auth";
import { v4 as uuidV4 } from "uuid";

/**
 * Hook that serves up functions related to saved bookmark
 * items for current customer group
 * @returns {
 *  isBookmarked : Check if an item is saved as a bookmark
 *  addBookmark : Add a new bookmark item
 *  removeBookmark : Remove a bookmark
 *  bookmarks : A list of bookmark items.
 *              Can be filtered on app and entity type.
 * }
 */
export function useBookmarks(
  bookmarkType: EntityType | undefined,
  entryType: BookmarkEntryType
) {
  const {
    state: { currentCustomerGroup },
  } = useAuthenticationContext();
  const { data: bookmarks, isSuccess } = useFindBookmarks({
    enabled: currentCustomerGroup !== undefined,
    ignoreError: [404],
    retry(failureCount, error) {
      return error?.status === 404 ? false : failureCount < 3;
    },
    retryOnMount: false,
  });

  const { mutate: addOrRemove, status: updateStatus } = useUpdateBookmarks();
  const { mutate: clear } = useClearBookmarks();
  const bookmarkList = bookmarks instanceof Array ? bookmarks : [];
  const isFetched = isSuccess;

  /**
   * Check if an item is saved as bookmark
   * @param relativeURL URL to reach the item
   * @returns
   */
  function isBookmarked(relativeURL: string) {
    return (
      bookmarkList.findIndex(
        (bookmark) =>
          bookmark.url === relativeURL &&
          bookmark.bookmarkEntry.entryType === BookmarkEntryType.BOOKMARK
      ) !== -1
    );
  }

  /**
   * Add a new bookmark item
   * @param newBookmark
   */
  function addBookmark(
    newBookmark: Bookmark,
    invalidate?: boolean,
    recentlyViewedBookmark?: boolean
  ) {
    const bk = recentlyViewedBookmark
      ? convertRecentlyViewed(newBookmark)
      : newBookmark;
    bk.bookmarkEntry.id = encodeURIComponent(uuidV4());
    addOrRemove({ add: true, bookmark: bk, invalidate });
  }
  /**
   * Remove a bookmark item.
   * @param bookmark - The bookmark item to be removed.
   * @param invalidate - (Optional) If true, triggers invalidation of cached data. Default is false.
   * @param recentlyViewedBookmark - (Optional) If true, indicates that the bookmark is from recently viewed items. Default is false.
   * @param searchResults - (Optional) If true, indicates that the bookmark is from search results. Default is false.
   * @remarks
   * This function removes a bookmark item identified by the provided bookmark parameter.
   * It optionally allows invalidating cached data and supports handling bookmarks from recently viewed items or search results.
   * If `invalidate` is set to true, it triggers invalidation of cached data related to bookmarks.
   * If `recentlyViewedBookmark` is set to true, the function filters the bookmark from recently viewed items before removal.
   * If `searchResults` is set to true, the function filters the bookmark from search results before removal.
   */
  function removeBookmark(
    bookmark: Bookmark,
    invalidate?: boolean,
    recentlyViewedBookmark?: boolean,
    searchResults?: boolean
  ) {
    const bk =
      recentlyViewedBookmark || searchResults
        ? bookmarkList.filter(
            (b) =>
              b.bookmarkEntry.entryType === BookmarkEntryType.BOOKMARK &&
              b.bookmarkEntry.entityType ===
                bookmark.bookmarkEntry.entityType &&
              b.bookmarkEntry.entityId === bookmark.bookmarkEntry.entityId
          )[0]
        : bookmark;
    addOrRemove({ add: false, bookmark: bk, invalidate });
  }

  function clearBookmarks() {
    clear(getCurrentCustomerGroupId() ?? -1);
  }

  return {
    isFetched,
    bookmarks: bookmarkList.filter(
      (f) =>
        (!bookmarkType || f.bookmarkEntry.entityType === bookmarkType) &&
        f.bookmarkEntry.entryType === entryType
    ),
    updateStatus,
    isBookmarked,
    addBookmark,
    removeBookmark,
    clearBookmarks,
  };
}
