import {
  Description,
  ErrorOutlined,
  ManageSearch,
  SvgIconComponent,
} from "common/assets/icons";
import { TableCell, TableRow, Typography } from "common/components";
import { useTranslation } from "i18n";
import { StyledMessageContainer } from "./NoTableData.styles";

export type NoTableDataVariant = "search" | "data" | "error";

export interface NoTableDataProps {
  /** No table data variant */
  variant?: NoTableDataVariant;
  /** Message title */
  title?: string;
  /** Message description */
  description?: string;
}

/** Display in table body if no data is available */
export function NoTableData({
  variant = "data",
  title,
  description,
}: NoTableDataProps) {
  const { t } = useTranslation(["common", "search"]);

  const icons: Record<NoTableDataVariant, SvgIconComponent> = {
    search: ManageSearch,
    data: Description,
    error: ErrorOutlined,
  };
  const Icon = icons[variant] ?? Description;
  const defaultTitles: Record<NoTableDataVariant, string> = {
    search: t("search:noMatchesDefault"),
    data: t("common:noAvailableData"),
    error: t("common:table.defaultError"),
  };
  const displayTitle = title ?? defaultTitles[variant] ?? defaultTitles.data;

  return (
    <TableRow>
      <TableCell
        // Needed to fill full width of table
        colSpan={Number.MAX_SAFE_INTEGER}
        align="center"
        size="medium"
        sx={{ border: "none" }}
      >
        <Icon fontSize="x2large" color="disabled" aria-hidden />
        <StyledMessageContainer data-cy="noAvailableDataOrNoMatchesDefault">
          <Typography variant="h6">{displayTitle}</Typography>
          {description && (
            <Typography variant="body2">{description}</Typography>
          )}
        </StyledMessageContainer>
      </TableCell>
    </TableRow>
  );
}
