/**
 * Available languages code class.
 *
 * @remarks
 *  The values of these language codes must match the values of the
 *  Language_code_short column in the SYS_LANGUAGES table.
 *
 * de - German
 * en - English
 * gb - British English
 * sv - Swedish
 * fr - French
 *
 */
export enum AvailableLanguage {
  /**
   * German
   */
  de = "de",

  /**
   * English (International)
   */
  en = "en",

  /**
   * Spanish
   */
  es = "es",

  /**
   * British English
   */
  gb = "gb",

  /**
   * Swedish
   */
  sv = "sv",

  /**
   * French
   */
  fr = "fr",
}
