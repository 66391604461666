import {
  API,
  APIQueryResult,
  ActionConfig,
  EntityType,
  createApiUrl,
  invalidateAPIQuery,
  useAPIQuery,
} from "core/api";
import { Cache } from "core/idb";

/**
 * Fetch Web setup actions for provided entity type and optional entity type id
 *
 * @param entityType
 * @param entityId
 * @returns
 */
export function useFetchWebSetupActions(
  entityType: EntityType,
  entityTypeId = -1
): APIQueryResult<ActionConfig[]> {
  return useAPIQuery<ActionConfig[]>(
    ["fetchWebSetupActions", entityType, entityTypeId],
    API.webSetupService.actions,
    {
      pathParams: {
        entityType,
      },
      queryParams: {
        entityTypeId,
      },
    },
    { enabled: isAllowedEntityType(entityType) }
  );
}

/**
 *
 * @param entityType Returns true it the provided entity type can be used with the hook.
 *
 * @returns
 */
function isAllowedEntityType(entityType: EntityType): boolean {
  switch (entityType) {
    case EntityType.WEB_ACTION:
    case EntityType.WEB_APPLICATION:
    case EntityType.WEB_APP_CONFIG:
    case EntityType.WEB_SCREEN:
    case EntityType.WEB_VIEW:
    case EntityType.WEB_WIDGET:
    case EntityType.WEB_TABLE:
    case EntityType.WEB_TABLE_OPTION:
    case EntityType.WEB_TABLE_FIELD_DEFINITION:
    case EntityType.FLOW:
    case EntityType.FLOW_STEP_DEFINITION:
    case EntityType.WEB_NAVIGATION_GROUP:
      return true;
    default:
      return false;
  }
}

/**
 * Utility to clear the browser indexedDB and react Tanstack query cache.
 *
 * @param entityType
 * @param entityTypeId
 */
export function invalidateWebSetupActionsCache(
  entityType: EntityType,
  entityTypeId: number
): void {
  Cache.clearResponse(
    "GET",
    createApiUrl(API.webSetupService.actions, {
      entityType,
      entityTypeId,
    })
  );
  invalidateAPIQuery(["fetchWebSetupActions", entityType, entityTypeId]);
}
