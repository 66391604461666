import { getFontIcon } from "common/assets";
import { Alert, AlertColor, Box, Button, Typography } from "common/components";
import { useToggle } from "common/hooks";
import {
  EntityAlert,
  EntityAlertPriority,
  EntityType,
  useFetchEntityAlerts,
} from "core/api";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { StyledBox } from "./WidgetEntityAlerts.styles";

/** Number of entity alerts to show initially. The rest of the alerts
 * will be displayed with a show/hide button */
const MAX_ALERT_ITEMS = 3;

export interface WidgetEntityAlertsProps {
  /** EntityType to get Entity Alerts from */
  entityType: EntityType | undefined;
  /** EntityId to get Entity Alerts from */
  entityId: string | undefined;
}

/** Display given Entity Alerts (widget handles alert lookup so alert count is externally visible)*/
export function WidgetEntityAlerts({
  entityType,
  entityId,
}: WidgetEntityAlertsProps): ReactElement | null {
  const { t } = useTranslation(["common"]);
  const [showAll, toggleShowAll] = useToggle(false);
  const { data: entityAlerts = [] } = useFetchEntityAlerts(
    entityType || EntityType.NONE,
    entityId || "-1"
  );

  const alertCount = Object.keys(entityAlerts).length;
  const visibleCount = showAll ? alertCount : MAX_ALERT_ITEMS;

  if (alertCount === 0) {
    return null;
  }

  return (
    <StyledBox display="grid" gap={0.25} data-cy="entityAlertContainer">
      {getEntityAlerts(entityAlerts, visibleCount)}
      {alertCount > MAX_ALERT_ITEMS && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2">
            {t("common:alerts", { count: alertCount })}
          </Typography>
          <Button
            variant="text"
            size="small"
            sx={{ justifySelf: "end" }}
            onClick={toggleShowAll}
            data-cy="toggle-entity-alerts"
          >
            {showAll ? t("common:sideBar.hide") : t("common:buttons.showAll")}
          </Button>
        </Box>
      )}
    </StyledBox>
  );
}

function getSeverity(priority: EntityAlertPriority): AlertColor | undefined {
  switch (priority) {
    case EntityAlertPriority.ERROR:
      return "error";
    case EntityAlertPriority.WARNING:
      return "warning";
    case EntityAlertPriority.SUCCESS:
      return "success";
    case EntityAlertPriority.INFO:
      return "info";
    default:
      return undefined;
  }
}

/** Get a subset from EntityAlert records */
function getEntityAlerts(
  entityAlerts: EntityAlert[],
  count: number
): JSX.Element[] {
  return entityAlerts.slice(0, count).map((entityAlert, index) => {
    const AlertIcon = entityAlert.icon ? getFontIcon(entityAlert.icon) : null;
    const severity = getSeverity(entityAlert.priority);
    return (
      <Alert
        key={index}
        variant="filled"
        severity={severity}
        icon={AlertIcon ? <AlertIcon /> : null}
        data-cy="entity-alert-record"
        sx={{
          padding: (theme) => theme.spacing(0.5, 0.5),
          bgcolor: severity ? severity : "inherit",
          borderColor: (theme) => theme.palette.background.paper,
        }}
      >
        {entityAlert.text}
      </Alert>
    );
  });
}
