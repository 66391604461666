import {
  Description,
  ErrorOutlined,
  ManageSearch,
  SvgIconComponent,
} from "common/assets/icons";
import {
  Box,
  ListItem,
  Skeleton,
  SxProps,
  TablePagination,
  Theme,
  Typography,
} from "common/components";
import { DefaultPageSizes } from "core/appSettings";
import { useTranslation } from "i18n";
import { ReactElement, ReactNode, cloneElement } from "react";
import { NoTableDataVariant } from "../table/BilliantTableBody/NoTableData";
import { StyledList } from "./BilliantList.styles";

export interface BilliantListProps {
  children: ReactNode;
  /** Number of list items */
  totalItemCount: number;
  /** If list data is loading */
  isLoading?: boolean;
  /** Current page */
  page: number;
  /** Function that handles updating the page */
  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ): void;
  /** Current page size  */
  rowsPerPage: number;
  /** Customize the options of the rows per page select field */
  rowsPerPageOptions?: number[];
  /** Function that handles updating pageSize correctly */
  handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void;
  /** If message for no data avilable should be visible */
  showNoDataMessage?: boolean;
  /** Root container styling */
  sx?: SxProps<Theme> /**
  /** String for adding your own data-cy name, default is "list_main" */;
  "data-cy"?: string;
  /** Element to show when list is loading */
  skeletonListItem?: ReactElement;
  /** No list data variant */
  variant?: NoTableDataVariant;
  /** Message title */
  noDataTitle?: string;
  /** Message description */
  noDataDescription?: string;
}

/** A list container for a customized list with pagination */
export function BilliantList({
  children,
  totalItemCount,
  page,
  handleChangePage,
  rowsPerPage,
  rowsPerPageOptions = DefaultPageSizes,
  handleChangeRowsPerPage,
  isLoading = false,
  showNoDataMessage,
  sx,

  "data-cy": dataCy = "listMain",
  skeletonListItem = (
    <ListItem>
      <Skeleton width="100%" height={SKELETON_HEIGHT} />
    </ListItem>
  ),
  variant,
  noDataTitle,
  noDataDescription,
}: Readonly<BilliantListProps>) {
  const SHOW_DATA = !isLoading && (totalItemCount > 0 || !showNoDataMessage);
  const NO_DATA = showNoDataMessage && !isLoading && totalItemCount === 0;

  return (
    <Box
      data-cy={dataCy}
      sx={{ display: "grid", gridTemplateRows: "auto min-content", ...sx }}
    >
      {isLoading && (
        <ListRowSkeletons
          numberOfListItems={rowsPerPage}
          skeletonListItem={skeletonListItem}
        />
      )}
      {SHOW_DATA && <StyledList>{children}</StyledList>}
      {NO_DATA && (
        <NoListData
          variant={variant}
          title={noDataTitle}
          description={noDataDescription}
        />
      )}
      <TablePagination
        component="div"
        count={totalItemCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onRowsPerPageChange={handleChangeRowsPerPage}
        data-cy="pagination"
      />
    </Box>
  );
}

export interface NoListDataProps {
  /** No list data variant */
  variant?: NoTableDataVariant;
  /** Message title */
  title?: string;
  /** Message description */
  description?: string;
}

/** Display if no data is available */
export function NoListData({
  variant = "search",
  title,
  description,
}: Readonly<NoListDataProps>) {
  const { t } = useTranslation(["common", "search"]);

  const icons: Record<NoTableDataVariant, SvgIconComponent> = {
    search: ManageSearch,
    data: Description,
    error: ErrorOutlined,
  };
  const Icon = icons[variant] ?? Description;
  const defaultTitles: Record<NoTableDataVariant, string> = {
    search: t("search:noMatchesDefault"),
    data: t("common:noAvailableData"),
    error: t("common:table.defaultError"),
  };
  const displayTitle = title ?? defaultTitles[variant] ?? defaultTitles.data;

  return (
    <Box sx={{ display: "grid", placeContent: "center", placeItems: "center" }}>
      <Icon fontSize="x2large" color="disabled" aria-hidden />
      <div data-cy="noAvailableDataOrNoMatchesDefault">
        <Typography variant="h6">{displayTitle}</Typography>
        {description && <Typography variant="body2">{description}</Typography>}
      </div>
    </Box>
  );
}

/** Height of placeholder skeleton */
const SKELETON_HEIGHT = 90;

interface ListRowSkeletonsProps {
  /** Number of displayed skeleton list items */
  numberOfListItems: number;
  skeletonListItem: ReactElement;
}

/** List row skeletons when loading data */
function ListRowSkeletons({
  numberOfListItems,
  skeletonListItem,
}: Readonly<ListRowSkeletonsProps>) {
  return (
    <StyledList>
      {Array(numberOfListItems)
        .fill(null)
        .map((_, index) =>
          cloneElement(skeletonListItem, {
            key: index,
          })
        )}
    </StyledList>
  );
}
