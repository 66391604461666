import { STATIC_TABLE_ITEMS_KEY } from "core/components";
import { Cache } from "core/idb";
import {
  API,
  CURRENT_USER_QUERY_KEY,
  EntityCacheParams,
  EntityType,
  FETCH_ENTITY_QUERY_KEY,
  SCREEN_QUERY_KEY,
  SEARCH_QUERY_KEY,
  ScreenCacheParams,
  invalidateFindBookmarks,
  invalidateFindEntityNotesCache,
  queryClient,
} from ".";

/**
 * Invalidator functions for deleting cached responses from IndexedDB and
 * invalidating queries.
 *
 * ### Deleting cached responses
 * Deletion of cached responses is needed to allow a query to request
 * fresh data from the server.
 *
 * @example
 *
 * ```
 * // Deletes all cached requests for the screen with target 'screenId'.
 * // Since transactions in IndexedDB are asynchronous they need to be
 * // awaited to ensure the cache is deleted before query invalidation.
 * await Cache.deleteAllMatching(
 *   API.config.screen.replace(":screenId", String(screenId))
 * );
 *
 * // The matching is done loosely, which means the code below will
 * // delete all cache where the url contains the string "task".
 * await Cache.deleteAllMatching("task");
 * ```
 *
 * ### Invalidating queries
 * Invalidation of queries is needed to ensure the displayed data
 * is up to date after an action is performed.
 *
 * @example
 *
 * ```
 * // Invalidates and refetches all queries loosely matching the given
 * // query key.
 * queryClient.invalidateQueries({
 *   queryKey: ["fetchEntity", entityRef.entityType],
 *   // Setting refetch type to "all" results in refetching all matching
 *   // queries instead of only active ones.
 *   refetchType: "all",
 * });
 * ```
 */
export const cacheInvalidators = {
  /** Deletes cached responses and invalidates queries for a given screen */
  SCREEN: async ({ screenId }: ScreenCacheParams) => {
    await Cache.deleteAllMatching(
      API.config.screen.replace(":screenId", String(screenId))
    );
    queryClient.invalidateQueries({
      queryKey: [SCREEN_QUERY_KEY, screenId],
    });
  },
  /** Deletes cached responses and invalidates queries for a given entity */
  ENTITY: async ({ entityRef, changeType }: EntityCacheParams) => {
    switch (entityRef.entityType) {
      case EntityType.ADJUSTMENT:
        /* Ideally only the cache containing the adjustments for the current customer
         * should be invalidated. But the cache invalidator only receives the product ID
         * and the newly created adjustment ID. => invalidate all queries that contain
         * the 'adjustements' in their path. */
        await Cache.deleteAllMatching("adjustments");
        /* Adjustments are retrieved using generic searchQuery with entityType=ADJUSTMENT
          => no need to invalidate specific tanstack/queries */
        // Delete queries matching the adjustmentId
        await Cache.deleteAllMatching(
          API.customerservice.customerAdjustment.replace(
            ":adjustmentId",
            String(entityRef.entityId)
          )
        );
        break;
      case EntityType.CUSTOMER:
        await Cache.deleteAllMatching(
          API.customerservice.customer.replace(
            ":customerNo",
            String(entityRef.entityId)
          )
        );
        queryClient.invalidateQueries({
          queryKey: ["fetchCreditRating"],
          exact: false,
          refetchType: "all",
        });
        queryClient.invalidateQueries({
          queryKey: ["fetchEntityCounters", Number(entityRef.entityId)],
          exact: false,
          refetchType: "all",
        });
        break;
      case EntityType.INVOICE_ACCOUNT:
        await Cache.deleteAllMatching(
          API.invoiceaccountservice.invoiceaccount.replace(
            ":invoiceAccountId",
            String(entityRef.entityId)
          )
        );
        queryClient.invalidateQueries({
          queryKey: ["fetchCreditRating"],
          exact: false,
          refetchType: "all",
        });
        queryClient.invalidateQueries({
          queryKey: ["fetchEntityCounters", Number(entityRef.entityId)],
          exact: false,
          refetchType: "all",
        });
        break;
      case EntityType.CUSTOMER_BUCKET:
        // Nothing to do: Buckets are retrieved using generic searchQuery
        // with entityType=CUSTOMER_BUCKET
        // => no need to invalidate specific tanstack/queries
        break;
      case EntityType.ADDRESS:
        await Cache.deleteAllMatching("address");
        break;
      case EntityType.INVOICE:
      case EntityType.CREDIT_NOTE:
        await Cache.deleteAllMatching(
          API.invoiceservice.invoice.replace(
            ":invoiceId",
            String(entityRef.entityId)
          )
        );
        if (entityRef.entityType === EntityType.CREDIT_NOTE) {
          // The Credit Notes are fetched using entity type INVOICE
          // => invalidating all queries with keys "fetchEntity" and "INVOICE"
          queryClient.invalidateQueries([FETCH_ENTITY_QUERY_KEY, "INVOICE"], {
            refetchType: "all",
          });
        }
        break;
      case EntityType.NP_CASE:
        await Cache.deleteAllMatching("np-cases");
        await Cache.deleteAllMatching(
          API.numberportservice.case.replace(
            ":caseId",
            String(entityRef.entityId)
          )
        );
        break;
      case EntityType.CUSTOMER_MANDATE:
        await Cache.deleteAllMatching("mandates");
        break;

      case EntityType.PAYMENT_JOURNAL:
        await Cache.deleteAllMatching("payments");
        break;

      case EntityType.PAYMENT_PROVIDER:
        await Cache.deleteAllMatching("payments");
        break;

      case EntityType.PRODUCT:
        await Cache.deleteAllMatching(
          API.productservice.product.replace(
            ":productId",
            String(entityRef.entityId)
          )
        );
        await Cache.deleteAllMatching(API.userservice.userProducts);

        queryClient.invalidateQueries({
          queryKey: ["findCurrentUserProducts"],
          exact: false,
          refetchType: "all",
        });

        queryClient.invalidateQueries({
          queryKey: ["fetchEntityCounter"],
          exact: false,
          refetchType: "all",
        });

        break;
      case EntityType.INVENTORY:
        await Cache.deleteAllMatching(
          API.inventoryservice.inventory.replace(
            ":inventoryId",
            String(entityRef.entityId)
          )
        );
        break;
      case EntityType.TASK:
        await Cache.deleteAllMatching("task");
        await Cache.deleteAllMatching("notifications");

        queryClient.invalidateQueries({
          queryKey: ["findTasks"],
          refetchType: "all",
        });

        queryClient.invalidateQueries({
          queryKey: ["findEntityTasks"],
          refetchType: "all",
        });

        queryClient.invalidateQueries({
          queryKey: ["notifications"],
          refetchType: "all",
        });

        queryClient.invalidateQueries({
          queryKey: [
            SEARCH_QUERY_KEY,
            EntityType.DOCUMENT,
            API.taskservice.attachments.replace(
              ":taskId",
              String(entityRef.entityId)
            ),
          ],
          exact: false,
          refetchType: "all",
        });

        queryClient.invalidateQueries({
          queryKey: [
            STATIC_TABLE_ITEMS_KEY,
            EntityType.DOCUMENT,
            API.taskservice.attachments.replace(
              ":taskId",
              String(entityRef.entityId)
            ),
          ],
          exact: false,
          refetchType: "all",
        });

        await Cache.deleteAllMatching(
          API.taskservice.attachments.replace(
            ":taskId",
            String(entityRef.entityId)
          )
        );

        break;
      case EntityType.TASK_NOTIFICATION:
        await Cache.deleteAllMatching("notifications");
        break;
      case EntityType.NOTE:
        invalidateFindEntityNotesCache();
        break;
      case EntityType.PAYMENT:
        await Cache.deleteAllMatching(
          API.paymentservice.payment.replace(
            ":paymentId",
            String(entityRef.entityId)
          )
        );
        break;
      case EntityType.USER:
        await Cache.deleteAllMatching("users");
        await Cache.deleteAllMatching(API.userservice.currentUser);

        await Cache.deleteAllMatching(
          API.userservice.user.replace(":userId", String(entityRef.entityId))
        );

        queryClient.invalidateQueries({
          queryKey: [CURRENT_USER_QUERY_KEY],
          refetchType: "all",
        });

        break;

      case EntityType.REPAYMENT:
        await Cache.deleteAllMatching("repayment");
        await Cache.deleteAllMatching("payment");
        break;

      case EntityType.PAYMENT_PROVIDER_HISTORY:
        await Cache.deleteAllMatching("provider");
        break;

      case EntityType.EVENT_ROUTE_INVOCATION_HISTORY:
        await Cache.deleteAllMatching("orders");
        await Cache.deleteAllMatching("eventroutes");

        queryClient.invalidateQueries([SEARCH_QUERY_KEY, EntityType.ORDER], {
          refetchType: "active",
        });
        break;
      case EntityType.ORDER:
        await Cache.deleteAllMatching("orders");
        await Cache.deleteAllMatching("eventroutes");

        queryClient.invalidateQueries(
          [SEARCH_QUERY_KEY, EntityType.EVENT_ROUTE_INVOCATION_HISTORY],
          {
            refetchType: "active",
          }
        );
        break;
      case EntityType.WEB_ACTION:
      case EntityType.WEB_WIDGET:
      case EntityType.WEB_SCREEN:
      case EntityType.WEB_VIEW:
      case EntityType.FLOW:
      case EntityType.FLOW_STEP_DEFINITION:
      case EntityType.WEB_APPLICATION:
      case EntityType.WEB_APP_CONFIG:
      case EntityType.WEB_NAVIGATION_GROUP:
      case EntityType.WEB_TABLE:
      case EntityType.WEB_TABLE_FIELD_DEFINITION:
      case EntityType.WEB_TABLE_OPTION:
      case EntityType.WEB_SEARCH_FILTER:
        await Cache.deleteCacheStore("config");
        await Cache.deleteCacheStore("data");
        await queryClient.resetQueries([]);
        break;

      case EntityType.MESSAGE:
        await Cache.deleteAllMatching("message");
        break;

      case EntityType.NONE:
        break;

      default:
        console.error(
          "No matching cache invalidation for entity type " +
            entityRef.entityType
        );
        break;
    }
    if (changeType === "REMOVE" || changeType === "ADD") {
      queryClient.removeQueries([FETCH_ENTITY_QUERY_KEY, entityRef.entityType]);
    } else if (changeType === "UNCHANGED") {
      queryClient.invalidateQueries(
        [FETCH_ENTITY_QUERY_KEY, entityRef.entityType],
        {
          refetchType: "active",
        }
      );
    } else {
      queryClient.invalidateQueries(
        [FETCH_ENTITY_QUERY_KEY, entityRef.entityType],
        {
          refetchType: "all",
        }
      );
    }

    queryClient.invalidateQueries([SEARCH_QUERY_KEY, entityRef.entityType], {
      refetchType: "active",
    });

    queryClient.invalidateQueries(
      [STATIC_TABLE_ITEMS_KEY, entityRef.entityType],
      {
        refetchType: "active",
      }
    );

    await Cache.deleteAllMatching(
      `alerts/${entityRef.entityType}/${entityRef.entityId}`
    );

    queryClient.invalidateQueries({
      queryKey: ["alerts", entityRef.entityId, entityRef.entityType],
      refetchType: "active",
    });

    invalidateFindBookmarks();
  },
} as const;
