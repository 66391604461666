export default {
  cancelDialog: {
    title: "¿Descartar la acción '{{action}}'?",
    body: "Los datos ingresados se perderán.",
    cancelButton: "Descartar cambios",
    closeButton: "Cancelar",
  },

  transferProductStep: {
    copyProduct: "Copiar producto",
    transferBinds: "Transferir enlaces",
    deactivateStatusReasonId: "Razón de desactivación",
    activateStatusReasonId: "Razón de activación",
  },

  findInventoryStep: {
    searchText: "Buscar texto",
    status: "Estado",
    location: "Ubicación",
    article: "Artículo",
    maxInvItems: "Limitar #elementos a",
  },

  findNetworkElementStep: {
    searchText: "Buscar texto",
    status: "Estado",
    networkElementType: "Tipo de elemento de red",
    maxTableItems: "Limitar #elementos a",
  },
} as const;
